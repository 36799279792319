import * as React from "react";
import {
  IconButton,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
  Pivot,
  PivotItem,
  SearchBox,
  Stack,
} from "@fluentui/react";
import { CodeInfo } from "../models";
import queryString from "query-string";
import Api from "../api";
import { RouteComponentProps } from "react-router-dom";
import { ListItems } from "./ListItems";
import { Header } from "./Header";
import { Content } from "./Content";
import { IconTableRow, ParagraphLabel, StackHorizontal, StackVert, TableRow2ColsText } from "./Controls";
import { ListFreezerItems } from "./ListFreezerItems";
import { Language } from "./Strings";
import { ListHtmlItems } from "./ListHtmltems";
import { ListLostFoundItems } from "./ListLostFoundItems";
import { ListBoxes } from "./ListBoxes";
import { useTheme } from "../hooks/useTheme";
import { useEffect, useState } from "react";
import { TagTypeOverview } from "./TagTypeOverview";
import { useObserveUser } from "../hooks";
import { ListStoreItems } from "./ListStoreItems";
import { ShowInfoOnly } from "./ShowInfoOnly";
import { TabSettingsPanel } from "./TabSettingsPanel";

const strings = Language.strings;

export const Home = (props: RouteComponentProps<{}>) => {
  // Take qr code from query string (format: ?s=xxxxxx) => for backward compability
  const { list, s } = queryString.parse(window.location.search);
  const _qrCode = s ? (s as string) : undefined;
  if (_qrCode) window.location.href = "/" + _qrCode;

  const { showFreezerCodes, showHtmlCodes, showLostAndFoundCodes, showStaticCodes, showStorageCodes } = useTheme(
    strings.Shureguard ? "shuregard" : "qrit"
  );

  const { tabChoices, stores, onTabChoiceChange } = useObserveUser();

  const [userId] = useState(localStorage.getItem("userId"));
  const [items, setItems] = useState<CodeInfo[]>([]);
  const [qrCode, setQrCode] = useState(_qrCode);
  const [busy, setBusy] = useState(false);
  const [view, setView] = useState<string>("0");
  const [error, setError] = useState<string>();
  const [search, setSearch] = useState("");
  const [staticItems, setStaticItems] = useState<CodeInfo[]>([]);
  const [freezeItems, setFreezeItems] = useState<CodeInfo[]>([]);
  const [lostFoundItems, setLostFoundItems] = useState<CodeInfo[]>([]);
  const [htmlItems, setHtmlItems] = useState<CodeInfo[]>([]);
  const [boxesItems, setBoxesItems] = useState<CodeInfo[]>([]);
  const [viewDetailedList, setViewDetailedList] = useState(localStorage.getItem("viewMode") === "list");
  // const [_codeInfo, setCodeInfo] = useState<CodeInfo>();
  const [pivotItems, setPivotItems] = useState<boolean[]>([]);
  const [showTabSettingsPanel, setShowTabSettingsPanel] = useState(false);

  useEffect(() => {
    if (list && stores && tabChoices && stores.length > 0) {
      const store = stores.find((s) => s.id === list);
      if (store) {
        const hasListInTabChoices = tabChoices.some((id, i) => store.id === id);
        if (!hasListInTabChoices) {
          const newTabChoices = [...tabChoices, list as string];
          onTabChoiceChange(newTabChoices).then(() => {
            const index = newTabChoices.indexOf(store.id);
            setView((index + 5).toString());
            if (window.history !== undefined && window.history.pushState !== undefined) {
              window.history.pushState({}, document.title, window.location.pathname);
            }
          });
        } else {
          const index = tabChoices.indexOf(store.id);
          setView((index + 5).toString());
          if (window.history !== undefined && window.history.pushState !== undefined) {
            window.history.pushState({}, document.title, window.location.pathname);
          }
        }
      }
    }
  }, [list, stores, tabChoices, onTabChoiceChange]);

  const classNamesIconTableRow = mergeStyleSets({
    table: {
      fontFamily: "arial, sans-serif",
      borderCollapse: "collapse",
      width: "100%",
    },
    td_th: {
      border: "1px solid #dddddd",
      textAlign: "left",
      padding: "8px",
    },
  });

  useEffect(() => {
    const _getCodeDetails = async () => {
      if (qrCode) {
        try {
          // const codeInfo =
          await Api.getCode(qrCode!);
          // console.log('code info = ', codeInfo);
          // setCodeInfo(codeInfo);
        } catch {
          try {
            const ok = await Api.verifyCode(qrCode!);
            if (ok) {
              props.history.push("/registerItemManually?s=" + qrCode);
            } else {
              setError(strings.home_codeDetailsFailedGetInfo);
            }
          } catch {
            setError(strings.home_codeDetailsFailedGetInfo);
          }
        }
      }
    };

    const _updateCodes = async () => {
      setBusy(true);
      setError(undefined);
      try {
        const codes = await Api.getCodesForUser();
        setBusy(false);
        setItems(codes);
      } catch (err) {
        setBusy(false);
        setError("Internal error: code retrieval failed");
      }
    };

    if (userId) {
      _updateCodes();
      _getCodeDetails();
    } else {
      const { s } = queryString.parse(window.location.search);
      if (s) {
        setQrCode(s as string);
        _getCodeDetails();
      }
    }

    const view = localStorage.getItem("view");
    if (view) {
      setView(view);
    }
  }, [props.history, qrCode, userId]);

  const openItem = (code: string) => props.history.push("/" + code);

  // const staticItems = items.filter(i => Helpers.isStaticCodeType(i.properties));
  // TODO: Update so that all codes hvae a type
  // const staticItems = items.filter(
  //   (i) =>
  //     i.type === 'static'
  // );
  useEffect(() => localStorage.setItem("viewMode", viewDetailedList ? "list" : "grid"), [viewDetailedList]);
  useEffect(() => {
    const match = (i: CodeInfo, s: string) =>
      `${i.name} 
      ${i.description} 
      ${i.id} 
      ${i.shortID} 
      ${i.properties.content}
      ${i.properties.contentType}
      ${i.properties.boxState}
      ${i.properties.foodType}
      ${i.properties.location}
      ${i.properties.room}
      ${i.properties.type}
      ${i.properties.itemType}
      ${i.properties.content}`
        .toLocaleUpperCase()
        .includes(s.toLocaleUpperCase());
    const matches = (i: CodeInfo) => search.split(" ").every((s) => match(i, s));
    const filter = (type: string) =>
      items
        .filter((i) => i.type === type)
        .filter((i) => {
          return search.trim().length === 0 || match(i, search) || matches(i);
        });
    setStaticItems(filter("static"));
    setFreezeItems(filter("freezer"));
    setLostFoundItems(filter("lostFound"));
    setHtmlItems(filter("html"));
    setBoxesItems(filter("boxes"));
  }, [search, items]);

  const selectList = (itemKey: string) => {
    localStorage.setItem("view", itemKey);
    setView(itemKey);
    // console.log("setView", itemKey);
  };

  const onPivotItemClick = (pi?: PivotItem) => {
    if (pi && pi.props.itemKey) {
      if (pi.props.itemKey === "+") {
        setShowTabSettingsPanel(true);
      } else {
        selectList(pi.props.itemKey);
      }
    }
  };

  useEffect(() => {
    setPivotItems([
      showStaticCodes && (!tabChoices || tabChoices.includes("static")),
      showStorageCodes && (!tabChoices || tabChoices.includes("boxes")),
      showLostAndFoundCodes && (!tabChoices || tabChoices.includes("lostFound")),
      showFreezerCodes && !!tabChoices && tabChoices.includes("freezer"),
      showHtmlCodes && !!tabChoices && tabChoices.includes("html"),
      ...(tabChoices || []).map((t) => t.length > 20),
    ]);
  }, [tabChoices, showStaticCodes, showStorageCodes, showLostAndFoundCodes, showFreezerCodes, showHtmlCodes]);

  useEffect(() => {
    let start: number | undefined;
    const touchStart = (event: TouchEvent) => {
      if (event.touches.length === 1) {
        //just one finger touched
        start = event.touches[0].clientX;
        // console.log("TOUCH START", start);
      } else {
        //a second finger hit the screen, abort the touch
        start = undefined;
      }
    };

    const touchEnd = (event: TouchEvent) => {
      var offset = 100; //at least 100px are a swipe
      if (start && event.changedTouches.length === 1) {
        //the only finger that hit the screen left it
        var end = event.changedTouches[0].clientX;

        let index = view !== undefined ? +view : 0;
        if (end > start + offset) {
          //a left -> right swipe
          do {
            index = (index - 1 + pivotItems.length) % pivotItems.length;
          } while (!pivotItems[index]);
          selectList(index.toString());
        }
        if (end < start - offset) {
          //a right -> left swipe
          do {
            index = (index + 1) % pivotItems.length;
          } while (!pivotItems[index]);
          selectList(index.toString());
        }
      }
    };

    window.addEventListener("touchstart", touchStart);
    window.addEventListener("touchend", touchEnd);

    return () => {
      window.removeEventListener("touchstart", touchStart);
      window.removeEventListener("touchend", touchEnd);
    };
  }, [pivotItems, view]);

  return (
    <StackVert>
      <Header />
      <Content>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(undefined)}>
            {error}
          </MessageBar>
        )}

        {!userId && (
          <>
            <TagTypeOverview />
            {/* <WelcomePage code={qrCode} /> */}
          </>
        )}

        {userId && (
          <StackHorizontal>
            <Stack.Item grow>
              <SearchBox
                placeholder={strings.home_Search}
                underlined
                onSearch={setSearch}
                onChange={(_, s) => setSearch(s || "")}
              />
            </Stack.Item>
            <Stack.Item style={{ marginLeft: 0 }}>
              <IconButton
                iconProps={{ iconName: viewDetailedList ? "ThumbnailViewMirrored" : "PhotoCollection" }}
                onClick={() => setViewDetailedList(!viewDetailedList)}
                title={viewDetailedList ? strings.homePage_list : strings.homePage_grid}
              />
            </Stack.Item>
            <Stack.Item style={{ marginLeft: 0 }}>
              <ShowInfoOnly infoTitle={strings.homePage_infoHeader}>
                <StackVert>
                  <Stack.Item>
                    <ParagraphLabel>{strings.homePage_infoGeneralHelp}</ParagraphLabel>
                  </Stack.Item>
                  <Stack.Item>
                    <ul>
                      <li>{strings.homePage_info0}</li>
                      <li>{strings.homePage_info1}</li>
                      <li>{strings.homePage_info2}</li>
                      <li>{strings.homePage_info3}</li>
                      <li>{strings.homePage_info4}</li>
                      <li>{strings.homePage_info5}</li>
                      <li>{strings.homePage_info6}</li>
                      <li>{strings.homePage_info7}</li>
                      <li>{strings.homePage_info8}</li>
                    </ul>
                  </Stack.Item>
                  {(view === "0" || view === "1") && (
                    <Stack.Item>
                      <ParagraphLabel>{strings.home_iconTableWhatDoTheIconsMean}</ParagraphLabel>
                    </Stack.Item>
                  )}
                  <Stack.Item>
                    {(view === "0" || view === "1") && (
                      <table className={classNamesIconTableRow.table}>
                        <thead>
                          <tr>
                            <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderIcon}</th>
                            <th className={classNamesIconTableRow.td_th}>{strings.home_iconTableHeaderDescription}</th>
                          </tr>
                        </thead>
                        {view === "0" && (
                          <tbody>
                            <IconTableRow iconName="ICON_Home_32.png" description={strings.home_iconTableStaticHome} />
                            <IconTableRow
                              iconName="ICON_HomeAtOther_64.png"
                              description={strings.home_iconTableStaticHomeAtOther}
                            />
                            <IconTableRow
                              iconName="ICON_MineBorrowedByOther_32.png"
                              description={strings.home_iconTableStaticMyBorrowedByOther}
                            />
                            <IconTableRow
                              iconName="ICON_OthersBorrowedByMe_64.png"
                              description={strings.home_iconTableStaticOtherBorrowedByMe}
                            />
                            <IconTableRow
                              iconName="ICON_OtherBorrowedByOther_64.png"
                              description={strings.home_iconTableStaticOtherBorrowedByOther}
                            />
                          </tbody>
                        )}
                        {view === "1" && (
                          <tbody>
                            <IconTableRow
                              iconName="BoxOnly_White_32x32.png"
                              description={strings.home_iconTableBoxOpen}
                            />
                            <IconTableRow
                              iconName="BoxArrowIn_WhiteGreenArrow_32x32.png"
                              description={strings.home_iconTableBoxPacking}
                            />
                            <IconTableRow
                              iconName="BoxOnlyClosed_White_32x32.png"
                              description={strings.home_iconTableBoxClosed}
                            />
                            <IconTableRow
                              iconName="BoxOnlyClosedStored_Green_32x32.png"
                              description={strings.home_iconTableBoxStorageHome}
                            />
                            <IconTableRow
                              iconName="BoxOnlyClosedStored_Red_32x32.png"
                              description={strings.home_iconTableBoxStorageAway}
                            />
                            <IconTableRow
                              iconName="BoxArrowOut_WhiteBlueArrow_32x32.png"
                              description={strings.home_iconTableBoxUnpacking}
                            />
                            {/* <IconTableRow iconName='BoxOnly_White_32x32.png' description='STR: This box is unpacked (and could be reused or unregistered)' /> */}
                          </tbody>
                        )}
                      </table>
                    )}
                  </Stack.Item>
                  <Stack.Item>
                    <ParagraphLabel>{strings.home_tagTypeTableWhatAreTheTagTypes}</ParagraphLabel>
                  </Stack.Item>
                  <Stack.Item>
                    <li>
                      <table className={classNamesIconTableRow.table}>
                        <thead>
                          <tr>
                            <th className={classNamesIconTableRow.td_th}>{strings.home_tagTypeTableCodeTypeName}</th>
                            <th className={classNamesIconTableRow.td_th}>{strings.home_tagTypeTableDescription}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <TableRow2ColsText name={strings.home_items} description={strings.home_tagTypeTableStatic} />
                          <TableRow2ColsText name={strings.home_Boxes} description={strings.home_tagTypeTableBoxes} />
                          <TableRow2ColsText
                            name={strings.home_LostFound}
                            description={strings.home_tagTypeTableLostFound}
                          />
                          <TableRow2ColsText
                            name={strings.home_FreezerReuse}
                            description={strings.home_tagTypeTableFreezer}
                          />
                          <TableRow2ColsText
                            name={strings.home_Freetext}
                            description={strings.home_tagTypeTableMessage}
                          />
                          <TableRow2ColsText
                            name={strings.home_tagTypeTableOtherTextOnly}
                            description={strings.home_tagTypeTableOther}
                          />
                        </tbody>
                      </table>
                    </li>
                  </Stack.Item>
                </StackVert>
              </ShowInfoOnly>
            </Stack.Item>
          </StackHorizontal>
        )}
        {userId && (
          <Pivot selectedKey={view} onLinkClick={onPivotItemClick} overflowBehavior="menu">
            {pivotItems[0] && (
              <PivotItem
                itemKey="0"
                headerText={strings.home_items}
                // headerButtonProps={{
                //     'data-order': 1,
                //     'data-title': 'STR: items',
                // }}
              >
                <ListItems viewDetailedList={viewDetailedList} busy={busy} items={staticItems} onSelected={openItem} />
              </PivotItem>
            )}
            {pivotItems[1] && (
              <PivotItem itemKey="1" headerText={strings.home_Boxes}>
                <ListBoxes viewDetailedList={viewDetailedList} busy={busy} items={boxesItems} onSelected={openItem} />
              </PivotItem>
            )}
            {pivotItems[2] && (
              <PivotItem itemKey="2" headerText={strings.home_LostFound}>
                <ListLostFoundItems
                  viewDetailedList={viewDetailedList}
                  busy={busy}
                  items={lostFoundItems}
                  onSelected={openItem}
                />
              </PivotItem>
            )}
            {pivotItems[3] && (
              <PivotItem
                itemKey="3"
                headerText={strings.home_FreezerReuse}
                // headerButtonProps={{
                //     'data-order': 2,
                //     'data-title': 'STR: Freeze reuse',
                // }}
              >
                <ListFreezerItems
                  viewDetailedList={viewDetailedList}
                  busy={busy}
                  items={freezeItems}
                  onSelected={openItem}
                />
              </PivotItem>
            )}
            {pivotItems[4] && (
              <PivotItem itemKey="4" headerText={strings.home_Freetext}>
                <ListHtmlItems
                  viewDetailedList={viewDetailedList}
                  busy={busy}
                  items={htmlItems}
                  onSelected={openItem}
                />
              </PivotItem>
            )}
            {tabChoices &&
              tabChoices.map((id, i) => {
                if (id.length <= 20) return null;
                const store = stores.find((s) => s.id === id);
                if (!store) return null;
                return (
                  <PivotItem key={id} itemKey={(i + 5).toString()} headerText={store.name}>
                    <ListStoreItems
                      search={search}
                      busy={busy}
                      storeId={id}
                      onSelected={openItem}
                      viewDetailedList={viewDetailedList}
                    />
                  </PivotItem>
                );
              })}
            <PivotItem itemKey="+" itemIcon="Edit"></PivotItem>
          </Pivot>
        )}
      </Content>
      <TabSettingsPanel
        isOpen={showTabSettingsPanel}
        onDismiss={() => setShowTabSettingsPanel(false)}
        tabChoices={tabChoices}
        onTabChoiceChange={onTabChoiceChange}
      />
    </StackVert>
  );
};
