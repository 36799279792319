import { Stack, MessageBarType, MessageBar } from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useObserveCode } from "../hooks/useObserveCode";
import { ParagraphTextPre, StackVert } from "./Controls";
import { ItemFooterEdit } from "./ItemFooterEdit";
import { ImagesPanel } from "./ImagesPanel";

interface HandleOthersHtmlProps extends RouteComponentProps {
  code: string;
}

export const HandleOthersHtml = withRouter(({ history, code }: HandleOthersHtmlProps) => {
  const { description, hasCodeEditAccess, images, defaultImageId, setDefaultImageId } = useObserveCode({
    code,
    onSaved: () => history.goBack(),
  });

  const [error, setError] = useState(undefined);
  const [isHtml, setIsHtml] = useState(false);

  useEffect(() => {
    const hasHtmlTags = description.includes("<") && description.includes(">") && description.includes("/");
    setIsHtml(hasHtmlTags);
  }, [description]);

  // TODO: if the html is only an url then navigate to that url

  return (
    <StackVert>
      {/* <TitleWithInfo title={strings.handleHtmlItem_PageHeader} iconFileName="">
                    <ParagraphLabel>{'STR: Instructions'}</ParagraphLabel>
                    <ul>
                        <li>{'STR: 1 '}</li>
                        <li>{'STR: 2'}</li>
                        <li>{'STR: 3'}</li>
                        <li>{'STR: 4'}</li>
                        <li>{'STR: '}</li>
                    </ul>
                </TitleWithInfo> */}
      <Stack.Item>
        {error && (
          <MessageBar messageBarType={MessageBarType.error} onDismiss={() => setError(error)}>
            {error}
          </MessageBar>
        )}
      </Stack.Item>
      <Stack.Item>
        {isHtml && <div dangerouslySetInnerHTML={{ __html: description }}></div>}
        {!isHtml && <ParagraphTextPre>{description}</ParagraphTextPre>}
      </Stack.Item>
      {images.length > 0 && (
        <Stack.Item>
          <ImagesPanel
            viewMode
            defaultImageId={defaultImageId}
            setDefaultImageId={setDefaultImageId}
            code={code}
            images={images}
          />
        </Stack.Item>
      )}
      {hasCodeEditAccess && <ItemFooterEdit code={code} />}
    </StackVert>
  );
});
