import React, { useEffect } from "react";
import { Content, Header, StackVert } from ".";
import { Html5QrcodeScanner } from "html5-qrcode";
import { Stack } from "@fluentui/react";

export const Scan = () => {
  //   const [result, setResult] = React.useState(null);
  //   const [data, setData] = React.useState(null);

  const onScanSuccess = (result: any) => {
    // alert(JSON.stringify(result, null, 2));
    window.location.href = result;
  };

  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: { width: 250, height: 250 },
      },
      false
    );
    html5QrcodeScanner.render(onScanSuccess, (e) => {
      // console.log(e);
    });

    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  return (
    <>
      <Header />
      <Content>
        <StackVert>
          <Stack.Item>
            <div id="reader"></div>
          </Stack.Item>
        </StackVert>
      </Content>
    </>
  );
};
