import React from "react";
import { PrimaryButton, Stack } from "@fluentui/react";
import { useHistory, useParams } from "react-router-dom";
import { useObserveStore } from "../hooks/useObserveStore";
import { Content, Header, ParagraphText, StackHorizontal, StackVert } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { strings } from "./Strings";

export const JoinStore = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { busy, name, description, hasListAccess, join, canEditCodes } = useObserveStore({
    storeId: id,
    onSaved: () => {},
    onRemoved: () => {},
  });

  // useEffect(() => {
  //   if (hasListAccess && id) {
  //     history.push(`/viewstore/${id}`);
  //   }
  // }, [hasListAccess, id, history]);

  const onSelected = (code: string) => {
    history.push(`/${code}`);
  };

  // TODO: move to helpers? Or to new observable (that uses useHistory)
  const close = () => {
    console.log("close", history.length);
    if (history.length > 1) {
      history.goBack();
    } else {
      history.replace("/");
    }
  };

  const joinList = async () => {
    const ok = await join();
    if (!ok) {
      // TODO: set error? Or, display dialog alert?
      alert(strings.viewStore_CannotReqestJoinList);
    }
    // TODO: display info bar that request has been sent (copy Error control to InfoBar control)?
  };

  return (
    <>
      <Header />
      <Content>
        <StackVert>
          <Stack.Item>
            <TitleWithInfo title={name} infoTitle={name} onClose={close}>
              <ParagraphText>{description}</ParagraphText>
            </TitleWithInfo>
          </Stack.Item>
          <StackVert>
            <Stack.Item>
              <StackHorizontal>
                <Stack.Item grow>
                  <PrimaryButton onClick={joinList}>{strings.joinStore_acceptJoinList}</PrimaryButton>
                </Stack.Item>
                <Stack.Item align="end">
                  <PrimaryButton onClick={close}>{strings.joinStore_rejectJoinList}</PrimaryButton>
                </Stack.Item>
              </StackHorizontal>
            </Stack.Item>
          </StackVert>
        </StackVert>
      </Content>
    </>
  );
};
