import React, { useState } from "react";
import { Text, Stack, Label, Link, Dialog, DialogContent, PivotItem, mergeStyleSets, Icon } from "@fluentui/react";
import { CodeInfo, StoreInfo } from "../models";
import QRCode from "qrcode.react";
import { strings } from "./Strings";
import { QRcodeImage } from "./QRcodeImage";
import { useObserveStore } from "../hooks/useObserveStore";

export const PageTitle = (props: any) => {
  return (
    <div>
      <Text variant={"xxLarge"}>{props.children}</Text>
    </div>
  );
};

export const ParagraphTitle = (props: any) => {
  return (
    <div>
      <Text
        nowrap={props.nowrap}
        style={{ display: "block", textOverflow: "ellipsis", overflow: "hidden" }}
        variant={"xLarge"}
      >
        {props.children}
      </Text>
    </div>
  );
};

export const ParagraphText = (props: any) => {
  return (
    <div>
      <Text>{props.children}</Text>
    </div>
  );
};

export const ParagraphTextPre = (props: any) => {
  return (
    <pre>
      <Text>{props.children}</Text>
    </pre>
  );
};

export const ItemInfoStacked = ({ header, content }: { header: string; content: string }) => {
  if (!content || content === "-") {
    return null;
  }

  return (
    <Stack horizontal tokens={{ childrenGap: 6 }}>
      <Stack.Item>
        <ParagraphLabel>{header}</ParagraphLabel>
      </Stack.Item>
      <Stack.Item align="center">
        <ParagraphText>{content}</ParagraphText>
      </Stack.Item>
    </Stack>
  );
};

export const CodeShowAndLinkToQRImage = ({ code }: { code: string }) => {
  const [show, setShow] = useState(false);

  return (
    <Stack horizontal tokens={{ childrenGap: 6 }}>
      <Stack.Item>
        <ParagraphLabel>{strings.generic_QRCode}</ParagraphLabel>
      </Stack.Item>
      <Stack.Item align="center">
        <Link onClick={() => setShow(true)} underline>
          {code}
        </Link>
      </Stack.Item>
      <Dialog
        hidden={!show}
        title={strings.generic_QRCode}
        onDismiss={() => setShow(false)}
        modalProps={{ isBlocking: false }}
      >
        <DialogContent>
          <QRcodeImage code={code} />
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export const ViewListStores = ({ id }: { id: string }) => {
  const { name, storeCodes } = useObserveStore({ storeId: id, onSaved: () => {}, onRemoved: () => {} });
  return (
    <PivotItem itemKey={id} headerText={name}>
      <ul>
        {storeCodes.map((c) => (
          <li key={c.id}>
            <Link href={`/${c.id}`}>{c.name}</Link>
          </li>
        ))}
      </ul>
    </PivotItem>
  );
};

export const ShowList = ({ header, codesLists }: { header: string; codesLists: StoreInfo[] }) => (
  <>
    {codesLists.length > 0 && (
      <StackVert>
        <StackVert></StackVert>
        <Stack.Item>
          <ParagraphLabel>{header}</ParagraphLabel>
        </Stack.Item>
        <Stack.Item>
          {codesLists.length > 0 && (
            <ul>
              {codesLists.map((store) => (
                <li key={store.id}>
                  <a href={`/store/${store.id}`}>{store.name}</a>
                </li>
              ))}
            </ul>
          )}
          {codesLists.length === 0 && <ParagraphText>{strings.generic_noLists}</ParagraphText>}
        </Stack.Item>
      </StackVert>
    )}
  </>
);

export const ParagraphLabel = (props: any) => {
  return <Label>{props.children}</Label>;
};

export const TableRow = ({ title, content }: { title: string; content: string }) => (
  <tr>
    <td>
      <Label>{title}</Label>
    </td>
    <td>{content || ""}</td>
  </tr>
);

const classNamesIconTableRow = mergeStyleSets({
  table: {
    fontFamily: "arial, sans-serif",
    borderCollapse: "collapse",
    width: "100%",
  },
  td_th: {
    border: "1px solid #dddddd",
    textAlign: "left",
    padding: "8px",
  },
});

export const IconTableRow = ({ iconName, description }: { iconName: string; description: string }) => (
  <tr>
    <td className={classNamesIconTableRow.td_th}>
      {<img width={"35rem"} style={{ paddingRight: 4 }} src={iconName} alt="icon" />}
    </td>
    <td className={classNamesIconTableRow.td_th}>{description}</td>
  </tr>
);

export const TableRow2ColsText = ({ name, description }: { name: string; description: string }) => (
  <tr>
    <td className={classNamesIconTableRow.td_th}>{name}</td>
    <td className={classNamesIconTableRow.td_th}>{description}</td>
  </tr>
);

export const StackVert = (props: any) => {
  return (
    // <Stack tokens={{ childrenGap: 2 }} styles={{ root: { width: "95%" } }}>
    <Stack tokens={{ childrenGap: 2 }}>
      {props.children}
      {/* TODO: Should we add the following ? style={{ marginTop: 10 }} */}
    </Stack>
  );
};

export const StackHorizontal = (props: any) => {
  return (
    <Stack horizontal tokens={{ childrenGap: 5 }} wrap>
      {props.children}
    </Stack>
  );
};

export const QRCodeInfoShow = (props: { code: CodeInfo }) => {
  // TODO: Not used
  return (
    <div>
      {" "}
      {/* TODO - 210729: why is the code here not reachable? because */}
      <StackHorizontal>
        <ParagraphText>{strings.handleOwnItem_QRnumber + props.code.shortID}</ParagraphText>
      </StackHorizontal>
      <StackHorizontal>
        <ParagraphText>{strings.handleOwnItem_QRCode + props.code.id}</ParagraphText>
      </StackHorizontal>
      <StackHorizontal>
        {/* TODO - 210729: this should be made into a button with a pop-up "Show QR code image" */}
        <QRCode value={`https://qrit.app/${props.code.id}`} />;
      </StackHorizontal>
    </div>
  );
};

export const OnRenderOptionDropdown = (option: any): JSX.Element => {
  const iconStyles = { marginRight: "8px" };
  const textStyles = { width: "100%" };

  return (
    <div style={textStyles} onClick={option.data ? option.data.onClick : undefined}>
      {option.data && option.data.icon && (
        <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />
      )}
      <span onClick={option.data ? option.data.onClick : undefined}>{option.text}</span>
    </div>
  );
};
