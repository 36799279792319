interface IStrings {
  QRitDefault: boolean;
  Shureguard: boolean;

  // Generic
  generic_yes: string;
  generic_no: string;
  generic_description: string;
  generic_edit: string;
  generic_copy: string;
  generic_name: string;
  generic_cancel: string;
  generic_save: string;
  generic_share: string;
  generic_properties: string;
  generic_back: string;
  generic_close: string;
  generic_PageInfo: string;
  generic_noCodes: string;
  generic_ListsShow: string;
  generic_QRCode: string;
  generic_noLists: string;
  generic_noTypeSet: string;
  generic_phone: string;
  generic_add: string;
  generic_noMessages: string;
  generic_user: string;
  generic_manageImages: string;
  generic_quickAddImage: string;

  // Chat messages
  messages_footerMessage: string;
  messages_messageTitle: string;
  messages_send: string;
  messages_reply: string;
  messages_replyTitle: string;
  messages_replyText: string;
  messages_delete: string;
  messages_deleteTitle: string;
  messages_deleteText: string;

  // header.txs
  header_whatIsQRit: string;
  header_buyQRcodes: string;
  header_manualQRentry: string;
  header_scan: string;
  header_termsAndConditions: string;
  header_feedback: string;
  header_itemFound: string;
  header_account: string;
  header_logout: string;
  header_login: string;
  header_stores: string;
  header_settings: string;

  // Home.ts - TODO: THIS IS NOT COMPLETE
  welcome: string;
  environmentFuture: string;
  found: string;
  home_loginRegister: string;
  home_lostFound: string;
  home_WarnMsgYouHaveNewCode: string;
  home_WarnMsgYouHaveExistingCode: string;
  home_ButtonSignIn: string;
  home_ButtonItemFound: string;
  home_codeDetailsFailedGetInfo: string;
  home_items: string;
  home_FreezerReuse: string;
  home_LostFound: string;
  home_Freetext: string;
  home_Boxes: string;
  home_Search: string;

  home_iconTableWhatDoTheIconsMean: string;
  home_iconTableHeaderIcon: string;
  home_iconTableHeaderDescription: string;
  home_iconTableStaticHome: string;
  home_iconTableStaticHomeAtOther: string;
  home_iconTableStaticMyBorrowedByOther: string;
  home_iconTableStaticOtherBorrowedByMe: string;
  home_iconTableStaticOtherBorrowedByOther: string;
  home_iconTableBoxOpen: string;
  home_iconTableBoxPacking: string;
  home_iconTableBoxClosed: string;
  home_iconTableBoxStorageHome: string;
  home_iconTableBoxStorageAway: string;
  home_iconTableBoxUnpacking: string;
  home_iconTableBoxWhatAreTagTypes: string;
  home_tagTypeTableWhatAreTheTagTypes: string;
  home_tagTypeTableOtherTextOnly: string;
  home_tagTypeTableCodeTypeName: string;
  home_tagTypeTableDescription: string;
  home_tagTypeTableStatic: string;
  home_tagTypeTableBoxes: string;
  home_tagTypeTableLostFound: string;
  home_tagTypeTableFreezer: string;
  home_tagTypeTableMessage: string;
  home_tagTypeTableOther: string;

  // Item.tsx
  item_shareItemText: string;
  item_status: string;
  item_whoBorrowed: string;
  handleBorrowingItem_whenGiveBack1: string;
  handleBorrowingItem_whenGiveBack2: string;
  item_photo: string;
  item_atHome: string;
  item_isPublicViewable: string;

  // ItemView
  itemView_CodeDoesNotExist: string;

  // ListItems
  listItems_state: string;
  listItems_loading: string;
  listItems_home: string;
  listItems_giveBack1: string;
  listItems_giveBack2: string;
  listItems_awayFor1: string;
  listItems_awayFor2: string;
  listItems_photo: string;

  // listHtmlItems
  listHtmlItems_created: string;
  listHtmlItems_description: string;

  // itemImage
  itemImage_NoImage: string;

  //ListPuchases
  listPurchases_errorMsgUpdatePurchases: string;
  listPurchases_purchaseCompletedColText: string;
  listPurchases_tagSentColText: string;
  listPurchases_stateOrActionColText: string;
  listPurchases_digitalColText: string;
  listPurchases_createdColText: string;

  // HandleBorrowedItem.ts
  handleBorrowedItem_reportReturned: string;

  // HandleBorrowingItem
  handleBorrowingItem_infoHeader: string;
  handleBorrowingItem_Info1: string;
  handleBorrowingItem_Info2: string;
  handleBorrowingItem_Info3: string;
  handleBorrowingItem_Info4: string;
  handleBorrowingItem_Info5: string;
  handleBorrowingItem_wantToBorrowFrom: string;
  handleBorrowingItem_oneDay: string;
  handleBorrowingItem_threDays: string;
  handleBorrowingItem_oneWeek: string;
  handleBorrowingItem_onemonth: string;
  handleBorrowingItem_threeMonths: string;
  handleBorrowingItem_oneYear: string;
  handleBorrowingItem_noEndDate: string;
  handleBorrowingItem_borrow: string;
  handleBorrowingItem_OwnerName: string;
  handleBorrowingItem_TypeOfItem: string;
  handleBorrowingItem_ItemName: string;
  handleBorrowingItem_InfoAboutRent: string;
  handleBorrowingItem_SendMessToOwner: string;
  handleBorrowingItem_whenReturnItem: string;
  handleBorrowingItem_Category: string;

  // HandleItemBorrowedByMe.ts
  handleItemBorrowedBy_reportItemBack: string;
  handleItemBorrowedByMe_YouBorrowedThis: string;
  handleItemBorrowedByMe_Info1: string;
  handleItemBorrowedByMe_Info2: string;
  handleItemBorrowedByMe_Info3: string;
  handleItemBorrowedByMe_ShouldBeReturnedBy: string;
  handleItemBorrowedByMe_ReturnIn: string;
  handleItemBorrowedByMe_Days: string;
  handleItemBorrowedByMe_RentCost: string;
  handleItemBorrowedByMe_infoHeader: string;
  handleItemBorrowedByMe_oneDay: string;
  handleItemBorrowedByMe_threDays: string;
  handleItemBorrowedByMe_oneWeek: string;
  handleItemBorrowedByMe_onemonth: string;
  handleItemBorrowedByMe_threeMonth: string;
  handleItemBorrowedByMe_oneYear: string;
  handleItemBorrowedByMe_noEndDate: string;
  handleItemBorrowedByMe_extend: string;
  handleItemBorrowedByMe_extendDate: string;
  handleItemBorrowedByMe_addNumDays: string;

  //HandleItemBorrowedByOther.ts
  handleItemBorrowedByOther_ReportReturned: string;
  handleItemBorrowedByOther_BorrowedItem: string;
  handleItemBorrowedByOther_BorrowedBy: string;
  handleItemBorrowedByOther_ShouldBeReturnedBy: string;
  handleItemBorrowedByOther_ReturnIn: string;
  handleItemBorrowedByOther_Days: string;
  handleItemBorrowedByOther_info1: string;
  handleItemBorrowedByOther_info2: string;
  handleItemBorrowedByOther_info3: string;
  handleItemBorrowedByOther_rentCost: string;

  // HandleItemOtherBorrowedByOther.tsx
  handleItemOtherBorrowedByOther_Title: string;
  handleItemOtherBorrowedByOther_infoHeader: string;
  handleItemOtherBorrowedByOther_info1: string;
  handleItemOtherBorrowedByOther_info2: string;

  // HandleOwnItem.ts
  handleOwnItem_itemHeader: string;
  handleOwnItem_unregisterItem: string;
  handleOwnItem_unregister: string;
  handleOwnItem_TypeOfItem: string;
  handleOwnItem_close: string;
  handleOwnItem_realyUnregister: string;
  handleOwnItem_resetCode: string;
  handleOwnItem_QRnumber: string;
  handleOwnItem_QRCode: string;
  handleOwnItem_PageHeader: string;

  // handleOwnHtmlItem.ts
  handleHtmlItem_PageHeader: string;
  handleHtmlItem_reset: string;
  handleHtmlItem_QRCode: string;
  handleHtmlItem_TagId: string;
  handleOwnHtml_infoHeader: string;
  handleOwnHtml_TextOrHTML: string;
  handleOwnHtml_info1: string;
  handleOwnHtml_info2: string;
  handleOwnHtml_info3: string;
  handleOwnHtml_info4: string;
  handleOwnHtml_info5: string;
  handleOwnHtml_info6: string;

  // handleOwnLostFound
  handleOwnLostFound_LostFound: string;
  handleOwnLostFound_ShowFindersFee: string;
  handleOwnLostFound_FindersFee: string;
  handleOwnLostFound_ShowYourPhoneNum: string;
  handleOwnLostFound_MessageSendingEnabled: string;
  handleOwnLostFound_ResetCode: string;
  handleOwnLostFound_FinderCanSendGPS: string;
  handleOwnLostFound_infoOnLostFound: string;
  handleOwnLostFound_thisIsLostFoundCode: string;
  handleOwnLostFound_whenYouMarkSometingWithTag: string;
  handleOwnLostFound_whenYouEditThisTag: string;

  handleOtherLostFound_HowToReturnItem: string;
  handleOtherLostFound_YouHaveScannedCode: string;
  handleOtherLostFound_FindersFee: string;
  handleOtherLostFound_ThereIsAFindersFee: string;
  handleOtherLostFound_OwnersPhoneNum: string;
  handleOtherLostFound_PhoneNum: string;
  handleOtherLostFound_PleaseCallOrSend: string;
  handleOtherLostFound_SendMessToOwner: string;
  handleOtherLostFound_YouCanChooseSendMess: string;
  handleOtherLostFound_SendMess: string;
  handleOtherLostFound_infoHeader: string;
  handleOtherLostFound_info1: string;
  handleOtherLostFound_info2: string;
  handleOtherLostFound_info3: string;
  handleOtherLostFound_info4: string;
  handleOtherLostFound_info5: string;
  handleOtherLostFound_info6: string;
  handleOtherLostFound_SendGPSPosToOwnerHeader: string;
  handleOtherLostFound_SendGPSPositionToOwnerInfo: string;
  handleOtherLostFound_SendGPSPosToOwnerButton: string;
  handleOtherLostFound_SendingGPSPosToOwner: string;
  handleOtherLostFound_GeolocationIsNotSupported: string;
  handleOtherLostFound_HasSentGPSPosToOwner: string;
  handleOtherLostFound_GpsLinkMessage: string;

  // HandleOthersFreezer
  handleOthersFreezer_infoHeader: string;
  handleOthersFreezer_info1: string;
  handleOthersFreezer_info2: string;
  handleOthersFreezer_info3: string;
  handleOthersFreezer_PageHeader: string;
  handleOthersFreezer_Owner: string;
  handleOthersFreezer_ContentType: string;
  handleOthersFreezer_FoodType: string;
  handleOthersFreezer_FreezDate: string;
  handleOthersFreezer_ExparationDate: string;
  handleOthersFreezer_ForHowMany: string;
  handleOthersFreezer_HowMuch: string;
  handleOthersFreezer_Location: string;

  //handleOthersBoxes
  handleOthersBoxes_Info1: string;
  handleOthersBoxes_Info2: string;
  handleOthersBoxes_Info3: string;
  handleOthersBoxes: string;
  handleOthersBoxes_PageHeader: string;
  handleOwnBoxes_PageHeader: string;
  handleOwnBoxes_infoHeader: string;
  handleOwnBoxes_PageHeaderEdit: string;

  // HandleBoxes
  handleBoxes_Owner: string;
  handleBoxes_ShortID: string;
  handleBoxes_ContentType: string;
  handleBoxes_Content: string;
  handleBoxes_Description: string;
  handleBoxes_Location: string;
  handleBoxes_Room: string;
  handleBoxes_Category: string;
  handleBoxes_Fragility: string;
  handleBoxes_Priority: string;
  handleBoxes_QRcode: string;
  handleBoxes_BoxColor: string;
  handleBoxes_BoxState: string;
  handleBoxes_StorageDate: string;
  handleBoxes_ShortId: string;
  handleBoxes_0: string;
  handleBoxes_1: string;
  handleBoxes_2: String;
  handleBoxes_3: string;
  handleBoxes_4: string;
  handleBoxes_5: string;
  handleBoxes_6: string;
  handleBoxes_7: string;
  handleBoxes_8: string;
  handleBoxes_9: string;
  handleBoxes_10: string;

  // EditBoxes.ts
  EditBoxes_ContentTypeHeader: string;
  EditBoxes_ContentHeader: string;
  EditBoxes_BoxName: string;
  EditBoxes_Location: string;
  EditBoxes_Room: string;
  EditBoxes_QRID: string;
  EditBoxes_BoxID: string;
  EditBoxes_QRcode: string;
  EditBoxes_LocationHeader: string;
  EditBoxes_RoomHeader: string;
  EditBoxes_CategoryHeader: string;
  EditBoxes_BoxStateHeader: string;
  EditBoxes_BoxPriorityHeader: string;
  EditBoxes_BoxColorHeader: string;
  EditBoxes_InfoHeader: string;

  // EditBoxInfo
  EditBoxInfo_BoxInfoHeader: string;
  EditBoxInfo_PlaceQR: string;
  EditBoxInfo_ScanQR: string;
  EditBoxInfo_Packing: string;
  EditBoxInfo_AddingCatDetails: string;
  EditBoxInfo_BackLater: string;
  EditBoxInfo_FinishedPacking: string;
  EditBoxInfo_AddingPrioFrag: string;
  EditBoxInfo_PutInStorage: string;

  // HandleFreezer.ts
  handleFreezer_freezing_date: string;
  handleFreezer_persons: string;
  handleFreezer_noDateSet: string;

  // HomePageText.ts
  homePageText_howWorks: string;
  homePageText_prices: string;
  homePageText_toThinkAbout: string;
  homePageText_tagsFromUs: string;
  homePageText_tagOntoTool: string;
  homePageText_scanWithCell: string;
  homePageText_addDescript: string;
  homePageText_ifSomoneBorrow: string;
  homePageText_weKeepTrack: string;
  homePageText_qritIsServiceSubscr: string;
  homePageText_subscript8kr: string;
  homePageText_subscript8krEnd: string;
  homePageText_12TagsCost: string;
  homePageText_12TagsCostEnd: string;
  homePageText_24TagsCost: string;
  homePageText_24TagsCostEnd: string;
  homePageText_buyPhysicalTags: string;
  homePageText_alsoJusgtPrintAndTejp: string;
  homePageText_attTankaPa: string;
  homePageText_ifNotPrintWeSend: string;
  homePageText_ifNotPrintWeSendEnd: string;
  homePageText_tagsMustPlanar: string;
  homePageText_size2x4: string;
  homePageText_partOfTagsWhite: string;
  homePageText_ifPDF: string;
  homePageText_priceTablePrice: string;
  homePageText_priceTableCurrency: string;

  // HomePage
  homePage_infoHeader: string;
  homePage_infoGeneralHelp: string;
  homePage_info0: string;
  homePage_info1: string;
  homePage_info2: string;
  homePage_info3: string;
  homePage_info4: string;
  homePage_info5: string;
  homePage_info6: string;
  homePage_info7: string;
  homePage_info8: string;
  homePage_grid: string;
  homePage_list: string;

  // tagTypeOverview
  tagTypeOverview_pageHeader: string;
  tagTypeOverview_intro: string;
  tagTypeOverview_RegisterLoginInfo: string;
  tagTypeOverview_RegisterLogin: string;
  tagTypeOverview_lblHeaderQRitStorage: string;
  tagTypeOverview_QRitStorage1: string;
  tagTypeOverview_QRitStorage2: string;
  tagTypeOverview_QRitStorage3: string;
  tagTypeOverview_lblHeaderQRitKeys: string;
  tagTypeOverview_QRitKeys1: string;
  tagTypeOverview_QRitKeys2: string;
  tagTypeOverview_lblHeaderQRitStatic: string;
  tagTypeOverview_QRitStatic1: string;
  tagTypeOverview_QRitStatic2: string;
  tagTypeOverview_QRitStatic3: string;
  tagTypeOverview_lblHeaderQRitLostFound: string;
  tagTypeOverview_QRitLostFound1: string;
  tagTypeOverview_QRitLostFound2: string;
  tagTypeOverview_lblHeaderQRitFreezer: string;
  tagTypeOverview_QRitFreezer1: string;
  tagTypeOverview_QRitFreezer2: string;
  tagTypeOverview_QRitFreezer3: string;
  tagTypeOverview_lblHeaderQRitHtml: string;
  tagTypeOverview_QRitHtml1: string;
  tagTypeOverview_QRitHtml2: string;
  tagTypeOverview_business: string;
  tagTypeOverview_private: string;

  tagTypeOverview_pageHeader_BB: string;
  tagTypeOverview_intro_BB: string;
  tagTypeOverview_RegisterLoginInfo_BB: string;
  tagTypeOverview_lblHeaderQRitStorage_BB: string;
  tagTypeOverview_lblHeaderQRitKeys_BB: string;
  tagTypeOverview_lblHeaderQRitLostFound_BB: string;
  tagTypeOverview_lblHeaderQRitFreezer_BB: string;

  tagTypeOverview_lblHeaderQRitRestaurant_BB: string;
  tagTypeOverview_QRitStorage1_BB: string;
  tagTypeOverview_QRitStorage2_BB: string;
  tagTypeOverview_QRitOrderCodesHere_BB: string;
  tagTypeOverview_QRitKeys1_BB: string;
  tagTypeOverview_QRitKeys2_BB: string;
  tagTypeOverview_lblHeaderQRitStatic_BB: string;
  tagTypeOverview_QRitStatic1_BB: string;
  tagTypeOverview_QRitStatic2_BB: string;
  tagTypeOverview_QRitStatic_BB: string;
  tagTypeOverview_QRitLostFound1_BB: string;
  tagTypeOverview_QRitLostFound2_BB: string;
  tagTypeOverview_lblHeaderQRitHtml_BB: string;
  tagTypeOverview_QRitHtml1_BB: string;
  tagTypeOverview_QRitRestaurant1_BB: string;
  tagTypeOverview_QRitRestaurant2_BB: string;

  // Privacy.ts
  privacy_deliveryTerms: string;
  privacy_deliveryTermsHeader: string;
  privacy_deliveryTermsMissDelivery: string;
  privacy_personalInfoHeader: string;
  privacy_personalInfoUseInfo: string;
  privacy_cookies: string;
  privacy_cookiesInfo: string;
  privacy_infoStorageHeader: string;
  privacy_infoStorageInfoSSL: string;
  privacy_infoStorageInfoEncrypt: string;
  privacy_personalInfoPurchaseHeading: string;
  privacy_personalInfoPurchaseInfo: string;
  privacy_pricesHeader: string;
  privacy_pricesInfo: string;
  privacy_giveBackHeader: string;
  privacy_giveInfo: string;
  privacy_returningHeader: string;
  privacy_returningInfo: string;
  privacy_exchangeHeader: string;
  privacy_exchangeInfo: string;
  privacy_payBackHeader: string;
  privacy_payBackInfo: string;
  privacy_payBackInfoDelivery: string;
  privacy_goodToHaveLinksHeader: string;
  privacy_goodToHaveLinksConsumerService: string;
  privacy_goodToHaveLinksConsumerARN: string;
  privacy_goodToHaveLinksArbitration: string;
  privacy_complaintsHeader: string;
  privacy_complaintsInfo: string;

  // Purchasing.ts
  purchasing_payment: string;
  purchasing_ifPurchaseDigital: string;
  purchasing_ifPurchasePhysical: string;
  purchasing_order: string;
  puchasing_youLoggedInLoginNow: string;
  purchasing_payPaypal: string;
  purchasing_paySwish: string;
  purchasing_payKlarna: string;
  purchasing_paySwishInstructions: string;
  purchasing_fullName: string;
  purchasing_firstName: string;
  purchasing_lastName: string;
  purchasing_address: string;
  purchasing_zipCode: string;
  purchasing_city: string;
  purchasing_country: string;
  purchasing_emailPlaceholder: string;
  purchasing_email: string;
  purchasing_phone: string;
  purchasing_username: string;

  // RegisterItem.ts
  registerItem_itemRegistration: string;
  registerItem_freezerItemRegistration: string;
  registerItem_boxItemRegistration: string;
  registerItem_lostFoundItemRegistration: string;
  registerItem_messageTagItemRegistration: string;
  registerItem_messageHtml: string;
  registerItem_messageName: string;
  registerItem_alwaysConfirmReturn: string;
  registerItem_registrerMustHaveBankID: string;
  registerItem_putItemForRent: string;
  registerItem_rentInfo: string;
  registerItem_lostFound: string;
  registerItem_lostFoundInfo: string;
  registerItem_configRent: string;
  registerItem_configLostFound: string;
  registerItem_itemRegister: string;
  registerItem_failedRegisterCode: string;

  // EditHtml
  editHtml_PageHeaderEdit: string;
  editItem_PageHeaderEdit: string;

  editHtml_Instructions: string;
  editHtml_infoHeader: string;
  editHtml_Info1: string;
  editHtml_Info2: string;
  editHtml_Info3: string;
  editHtml_Info4: string;
  editHtml_Info5: string;

  // titleWithInfo
  titleWithInfo: string;
  titleWithInfo_reportMisuse: string;
  titleWithInfo_reportMisuseDescript: string;
  titleWithInfo_reportMisuseSent: string;
  titleWithInfo_reportMisuseError: string;

  // EditItem.ts
  editItem_typeStatic: string;
  editItem_typeReuse: string;
  editItem_typeReuseLostFound: string;
  editItem_typeReuseFreetext: string;
  editItem_typeStorage: string;
  editItem_typeLabel: string;
  editItem_failedSaveCode: string;

  // EditStatic
  editStatic_infoHeader: string;
  editStatic_PageHeaderEdit: string;
  editStatic_1: string;
  editStatic_2: string;
  editStatic_3: string;
  editStatic_3_1: string;
  editStatic_3_2: string;
  editStatic_4: string;
  editStatic_5: string;
  editStatic_6: string;
  editStatic_7: string;
  editStatic_photo: string;
  editStatic_photoChoice: string;
  editStatic_ISBNScanningInfo: string;
  editStatic_alwaysConfirmReturn: string;
  editStatic_registrerMustHaveBankID: string;
  editStatic_putItemForRent: string;
  editStatic_configLostFound: string;
  editStatic_configRent: string;
  editStatic_lostFound: string;
  editStatic_lostFoundInfo: string;
  EditStatic_ItemTypesListHeader: string;
  EditStatic_ItemTypesHeader: string;
  editStatic_ListsHeader: string;
  editStatic_codeCannotBelongToMoreThanOne: string;
  editStatic_codeWillChangeOwner: string;
  editStatic_ISBNScanningError: string;

  // UploadDialog
  uploadDialogImageChoice: string;
  uploadDialogChooseImage: string;
  uploadDialogUpload: string;
  uploadDialogRotate: string;

  // ScanDialog
  scanDialog_manualEnter: string;

  // useObserveCode
  useObserveCode_ErroTryRefresh: string;

  // RegisterUser.ts
  registerUser_registerUser: string;
  registerUser_InfoAboutFree: string;
  registerUser_registerUserName: string;
  registerUser_registerPhone: string;
  registerUser_registerEmail: string;
  registerUser_registerphoneNumMustStartWith: string;
  registerUser_registerUsernameNeeded: string;
  registerUser_sendCode: string;
  registerUser_code: string;
  registerUser_privacyAgreement: string;
  registerUser_privacyAgreementHere: string;
  registerUser_iAgreeTerms: string;
  registerUser_iAgreeTermsEnd: string;
  registerUser_registerUserButton: string;
  registerUser_networkErrorTryAgain: string;
  registerUser_checkCodeTryAgain: string;
  registerUser_networkErrorTryAgain2: string;
  registerUser_goToPayment: string;
  registerUser_smsTextCode: string;
  registerUser_organization: string;
  registerUser_meAsUser: string;

  //buyDigitalTags
  buyDigialTags_buyTags: string;
  buyDigialTags_fillInEmail: string;
  buyDigialTags_email: string;
  buyDigialTags_howHandlePersonaInfoHere: string;
  buyDigialTags_howHandlePersonaInfo: string;

  // BuyPhysicalTags
  buyPhysicalTags_buyPhysicalTags: string;
  buyPhysicalTags_country: string;
  buyPhysicalTags_fillInAddressInfo: string;
  buyPhysicalTags_firstName: string;
  buyPhysicalTags_lastName: string;
  buyPhysicalTags_address: string;
  buyPhysicalTags_zipCode: string;
  buyPhysicalTags_city: string;

  // listMessages
  listMessages_Messages: string;

  // BuyTags
  buyTags_headerBuyQRcodes: string;
  buyTags_toUseMustHaveInfo: string;
  buyTags_chooseHereToBuyDigitalPhysical: string;
  //buyTags_ifYouBuyDigial: string
  buyTags_ifYouBuyDigial1: string;
  buyTags_ifYouBuyDigialPdfExampleURL: string;
  buyTags_ifYouBuyDigialPdfExampleHere: string;
  buyTags_ifYouBuyDigial2: string;
  buyTags_ifYouBuyDigialAveryExampleURL: string;
  buyTags_ifYouBuyDigialAveryExampleURLHere: string;
  buyTags_ifYouBuyDigial3: string;
  buyTags_ifYouBuyPhysical: string;
  buyTags_ifYouBuyPhysical2: string;
  buyTags_downloadPdf: string;
  buyTags_downloadPhysical: string;
  buyTags_qrCodeToX1: string;
  buyTags_qrCodeToX2: string;
  buyTags_chooseNumCodes: string;

  // BuyTags
  buyTags_ChoosePhysDigit: string;
  buyTags_ChooseTagLayout: string;
  buyTags_Price: string;
  buyTags_emptyTag: string;
  buyTags: string;
  buyTags_lostAndFound: string;
  buyTags_numbered: string;
  buyTags_numberedForeezer: string;

  buyTagsPhysicalOnly_toUseMustHaveInfo: string;
  buyTagsPhysicalOnly_chooseNumCodes: string;
  buyTagsPhysicalOnly_selectedFormatSimple: string;
  buyTagsPhysicalOnly_unSelectedFormatSimple: string;
  buyTagsPhysicalOnly_selectedFormatRugged: string;
  buyTagsPhysicalOnly_unSelectedFormatRugged: string;
  buyTagsPhysicalOnly_simpleTagFormat: string;
  buyTagsPhysicalOnly_ruggedTagFormatInfo: string;
  buyTagsPhysicalOnly_freeText: string;
  buyTagsPhysicalOnly_normalOrThickSticker: string;
  buyTagsPhysicalOnly_typeOfTagYouWant: string;
  buyTagsPhysicalOnly_theNumberOfTagsYouWant: string;

  buyTags_selectedTagEmpty: string;
  buyTags_selectedTagLostFound: string;
  buyTags_selectedTagNumber: string;
  buyTags_unSelectedTagEmpty: string;
  buyTags_unSelectedTagLostFound: string;
  buyTags_unSelectedTagNumber: string;

  // RegisterItemManually
  registerItemManually_infoHeader: string;
  registerItemManually_manualRegisteringItem: string;
  registerItemManually_manualRegisteringItemInfo1: string;
  registerItemManually_manualRegisteringItemInfo2: string;
  registerItemManually_writeQRcode8Char: string;
  registerItemManually_code: string;
  registerItemManually_register: string;
  registerItemManually_codeDoesNotExist: string;

  // Itemfound
  itemFound_youFoundQR: string;
  itemFound_someoneMarkedItem: string;
  itemFound_ownersPhoneNumber: string;
  itemFound_whatMessageDoYouWantToSend: string;
  itemFound_ownersOffersThisAmount: string;
  itemFound_insteadSendMessageToOwner: string;
  itemFound_sendMessage: string;
  itemFound_sendGPS: string;
  itemFound_sendGPSInfo: string;

  // ConfigRent
  configRent_choiceRentHeading: string;
  configRent_howMuchCostLbl: string;
  configRent_enableChatLbl: string;
  configRent_allowTimeExtent: string;
  configRent_enableRentQueue: string;

  // EditLostFound
  // TODO: Clean up naming
  editLostFound_settingsLost: string;
  editLostFound_settingsLostText: string;
  editLostFound_IwantFinderToSeePhoneNumber: string;
  editLostFound_IwantFinderAbleSendMess: string;
  configLost_withThisSettingFinderShowPos: string;
  editLostFound_IwantOfferLostPay: string;
  configLost_IwantOfferLostPayInfo: string;
  editLostFound_IwantFinderSeeHowMuchPay: string;
  configLost_IwantFinderSeeHowMuchPayInfo: string;
  editLostFound_HowMuchLostPay: string;
  editLostFound_HowMuchLostPayInfo: string;
  configLost_IwantEnablePosition: string;
  configLost_IwantEnablePositionInfo: string;
  editLostFound_infoHeader: string;
  editLostFound_info0: string;
  editLostFound_info1: string;
  editLostFound_info2: string;
  editLostFound_info3: string;
  editLostFound_info4: string;
  editLostFound_info5: string;
  editLostFound_info6: string;
  editLostFound_info7: string;
  editLostFound_info8: string;
  // editLostFound_info9: string
  editLostFound_info10: string;
  editLostFound_info11: string;

  // ItemFooter
  itemFooter_unregisterItem: string;
  itemFooter_unregister: string;
  itemFooter_close: string;
  itemFooter_realyUnregister: string;
  itemFooter_ShowAdvSettings: string;
  itemFooter_WarningDataLostRegisterNew: string;
  itemFooter_ChangeCodeType: string;
  itemFooter_areYouReadyToLeavePage: string;

  // ListTimeHelp
  // TODO: Andvänd dess på en hjälpsida för listItem
  listItemHelp_WhereIconHeader: string;
  listItemHelp_Header: string;
  listItemHelp_WhereIconDescr: string;
  listItemHelp_WhereIcon_Home: string;
  listItemHelp_WhereIcon_In: string;
  listItemHelp_WhereIcon_Out: string;
  listItemHelp_WhereIcon_Freezer: string;
  listItemHelp_OtherHeader: string;
  listItemHelp_Descr: string;
  listItemHelp_Image: string;

  // Freezwe
  freezer_DateHeader: string;
  freezer_ExpDateHeader: string;
  freezer_SelectADateDotDot: string;
  freezer_SelectADate: string;
  freezer_PersonCntHeader: string;
  freezer_10Persons: string;
  freezer_0Of1Persons: string;
  freezer_FoodTypeHeader: string;
  freezer_ContentTypeHeader: string;
  freezer_AmountHeader: string;
  freezer_EditFoodChoiceList: string;
  freezer_EditFoodTypeList: string;
  freezer_EditLocationList: string;
  freezer_Location: string;
  freezer_PageHeaderEdit: string;

  // EditFreezer
  editFreezer_infoHeader: string;
  editFreezer_info0: string;
  editFreezer_info1: string;
  editFreezer_info2: string;
  editFreezer_info3: string;
  editFreezer_info4: string;
  editFreezer_info5: string;
  editFreezer_info6: string;
  editFreezer_info7: string;
  editFreezer_info8: string;
  editFreezer_info9: string;
  editFreezer_info10: string;

  // editTypeModal
  editTypeModal_Delete: string;
  editTypeModal_Add: string;

  // Helpers
  Helpers_Scanned: string;
  Helpers_Unregistered: string;
  Helpers_Home: string;
  Helpers_BorrowedByOther: string;
  Helpers_BorrowedByMe: string;
  Helpers_OtherBorrowedByOther: string;
  Helpers_AwaitingReturnAccept: string;
  Helpers_Found: string;
  Helpers_ManageCategories: string;

  //listReuseItems
  listFreezerItems_Frozen: string;
  listFreezerItems_Expiration: string;

  // ListLostFoundItems
  listLostFoundItems_created: string;
  listLostFoundItems_photo: string;
  listLostFoundItems_photoDescription: string;

  // lostBoxes
  listBoxes_TagID: string;
  listBoxes_Content: string;
  listBoxes_Location: string;
  listBoxes_Room: string;
  listBoxes_Stored: string;
  listBoxes_Photo: string;

  // CreateStoreDialog
  createStoreDialog_Create: string;
  createStoreDialog_CreateStoreQuestion: string;
  createStoreDialog_StoreName: string;

  // ListStores
  ListStores_loading: string;

  // editStores
  editStores_allowResharingOfList: string;

  // joinStore
  joinStore_acceptJoinList: string;
  joinStore_rejectJoinList: string;

  //ImagePanel
  imagePanel_panelHeader: string;
  imagePanel_mainImage: string;
  imagePanel_addNewImage: string;
  imagePanel_removeImage: string;
  imagePanel_private: string;
  imagePanel_deleteImage: string;
  imagePanel_deleteSelectedImages: string;
  imagePanel_title: string;
  imagePanel_text: string;
  imagePanel_disableInFuture: string;

  // ViewStore
  viewStore_infoHeader: string;
  viewStore_info1: string;
  viewStore_info2: string;
  viewStore_info3: string;
  viewStore_info4: string;
  viewStore_info5: string;
  viewStore_info7: string;
  viewStore_info8: string;
  viewStore_info9: string;
  viewStore_info10: string;
  viewStore_info11: string;
  viewStore_DeleteStore: string;
  viewStore_Delete: string;
  viewStore_DeleteStoreQuestion: string;
  viewStore_Cancel: string;
  viewStore_SelectCodes: string;
  viewStore_MultiSelectControlled: string;
  viewStore_CodesTitle: string;
  viewStore_EditList: string;
  viewStore_RequireCodesOwnerChangeInfo: string;
  viewStore_RequireCodesOwnerChangeInfoNot: string;
  viewStore_RequireCodesOwnerChange: string;
  viewStore_AccessHandling: string;
  viewStore_Join: string;
  viewStore_CannotReqestJoinList: string;

  // Stores
  stores_CreateStore: string;
  stores_ManageLists: string;
  stores_infoHeader: string;
  stores_listBelongsToYou: string;
  stores_listBelongsToSomeoneElse: string;
  stores_info1: string;
  stores_info2: string;
  stores_info3: string;
  stores_info4: string;

  // UserSettings
  userSettings_infoHeader: string;
  userSettings_manageUserSettings: string;
  userSettings_manageViewTabs: string;
  userSettings_ChooseWhichTabsToShow: string;
  userSettings_ChooseWhichListsToShow: string;
  userSettings_Stuff: string;
  userSettings_boxes: string;
  userSettings_lostFound: string;
  userSettings_freezer: string;
  userSettings_messages: string;
  userSettings_listChoices: string;
  userSettings_enableSMRecievingOnIncommingMessages: string;
  userSettings_enableSMSRecieveLabel: string;
  userSettings_whereYouManageSettings: string;
  userSettings_theWarningIcon: string;
  userSettings_chooseToDisableReception: string;
  userSettings_UserContactInfo: string;
  userSettings_FeedbackSupport: string;
  userSettings_organizationAccount: string;
  userSettings_organizationsYouAreAdminFor: string;

  // AccesChangeOwnerDialog
  accessChangeOwnerDialog_ChangeOwner: string;
  accessChangeOwnerDialog_SubText: string;

  // StoreContextualMenu
  storeContextualMenu_listProperties: string;
  storeContextualMenu_moreOptions: string;
  storeContextualMenu_listName: string;
  storeContextualMenu_listOwner: string;

  // AccessAddUSerDialot
  accessAddUserDialog_addUser: string;
  accessAddUserDialog_subText: string;

  // AccessTable
  accessTable_FaildAddUser: string;
  accessTable_InviteUser: string;
  accessTable_ChangeOwner: string;
  accessTable_changeRole: string;
  accessTable_changeRoleInfo: string;
  accessTable_roleAdmin: string;
  accessTable_roleMaintain: string;
  accessTable_roleEditor: string;
  accessTable_rolePending: string;
  accessTable_roleMember: string;
  accessTable_setRole: string;
  accessTable_role: string;
  accessTable_command: string;
  accessTable_delete: string;
  accessTable_removeUser: string;
  accessTable_removeUserFromList: string;

  // Prices
  currency_prefix: string;
  currency_suffix: string;
  pricePhysicalDelviery: number;
  skuList: string[];

  freezer_FoodChoiceList: string[];
  freezer_FoodTypeList: string[];
  freezer_locationChoicesList: string[];
  freezer_AmountTypeList: string[];

  EditBoxes_ContentTypeList: string[];
  EditBoxes_ContentList: string[];
  EditBoxes_SiteList: string[];
  EditBoxes_RoomList: string[];
  EditBoxes_CategoryList: string[];
  // EditBoxes_PriorityList: string[];
  // EditBoxes_BoxStateList: string[];
  EditBoxes_DefaultContentType: string;
  EditBoxes_DefaultPriority: string;
  EditBoxes_DefaultFragility: boolean;
  EditBoxes_DefaultLocation: string;
  EditBoxes_DefaultBoxState: string;
  EditBoxes_BoxStateList2: { [key: string]: string };
  EditBoxes_PriorityList2: { [key: string]: string };
  EditBoxes_BoxColorChoices: { [key: string]: string };
  EditStatic_ItemTypesList: string[];
  AccessTypes: { [key: string]: string };
}

export class Language {
  static get strings() {
    switch (this.getLanguage()) {
      case "sv":
        return this.swedish;
      case "en":
        return this.english;
      default:
        return this.english;
    }
  }

  static setLanguage(language: "gb" | "sv" | "de" | "en") {
    localStorage.setItem("language", language);
  }

  static getLanguage() {
    const language = localStorage.getItem("language") || navigator.language;
    return language;
  }

  static english: IStrings = {
    QRitDefault: true,
    Shureguard: false,

    // Generic
    generic_yes: "Yes",
    generic_no: "No",
    generic_description: "Description",
    generic_edit: "Edit",
    generic_copy: "Copy",
    generic_name: "Name",
    generic_cancel: "Cancel",
    generic_save: "Save",
    generic_share: "Share",
    generic_properties: "Properties",
    generic_back: "Close",
    generic_close: "Close",
    generic_PageInfo: "Information",
    generic_noCodes: "No codes",
    generic_ListsShow: "Member of the following lists",
    generic_QRCode: "QR-code: ",
    generic_noLists: "No lists",
    generic_noTypeSet: "No type chosen",
    generic_phone: "Phone",
    generic_add: "Add",
    generic_noMessages: "No messages",
    generic_user: "User",
    generic_manageImages: "Manage images",
    generic_quickAddImage: "Quick add",

    // Header
    header_whatIsQRit: "What is QRit?",
    header_buyQRcodes: "Buy QR-codes",
    header_scan: "Scan",
    header_manualQRentry: "Manual code entry",
    header_termsAndConditions: "Terms",
    header_feedback: "BETA Feedback",
    header_itemFound: "Item found",
    header_account: "Account",
    header_logout: "Logout",
    header_login: "Login",
    header_stores: "Lists",
    header_settings: "Settings",

    // Home.ts
    welcome: "Welcome to QRit!",
    environmentFuture:
      "Dare to take care of the enviroment and a work for a sustainable future – lend or rent your stuff to family, friends, and neighbours! This app can, with the help of unique QR-codes, help you keep track of things you lend and rent, or just use it to keep track of your own stuff.",
    found:
      'If you have scanned a QR-code on something you found and want to help the owned get it back, press "Lost&Found".',
    home_loginRegister: "Register/log in",
    home_lostFound: "Found lost item",
    home_WarnMsgYouHaveNewCode: "You have scanned a new QR code. Register/log in to register it.",
    home_WarnMsgYouHaveExistingCode: "You have scanned an <b>existing</b> qr code. Register/login to register it.",
    home_ButtonSignIn: "Sign in",
    home_ButtonItemFound: "Item found",
    home_codeDetailsFailedGetInfo: "Failed to get QR code details.",
    home_items: "Stuff",
    home_FreezerReuse: "Freezer",
    home_LostFound: "Lost & Found",
    home_Freetext: "Message",
    home_Boxes: "Boxes",
    home_Search: "Search",

    home_iconTableWhatDoTheIconsMean: "What do the icons mean?",
    home_iconTableHeaderIcon: "Icon",
    home_iconTableHeaderDescription: "Description",
    home_iconTableStaticHome: 'The item is currently "at home" (with you)',
    home_iconTableStaticHomeAtOther: 'This is someone elses item that is "at home" for that person',
    home_iconTableStaticMyBorrowedByOther: "This is your item that somone else is borrowing from you",
    home_iconTableStaticOtherBorrowedByMe: "This is someone elses item that you currently are borrowing",
    home_iconTableStaticOtherBorrowedByOther: "This is someone elses item that someone else is borrowing",
    home_iconTableBoxOpen:
      'This box is tagged and is "open" (e.g., typically just waiting to be packed, or have been unpacked)',
    home_iconTableBoxPacking: 'This boxed has status "being packed"',
    home_iconTableBoxClosed: "This box is currently closed (possibly full after having been packed)",
    home_iconTableBoxStorageHome: "This box is currently in storage in your home",
    home_iconTableBoxStorageAway: "This box is currently in storage somewhere else (not in your home)",
    home_iconTableBoxUnpacking: "This box is currently being unpacked",
    home_iconTableBoxWhatAreTagTypes: "What are the code types?",
    home_tagTypeTableWhatAreTheTagTypes: "What are the code types?",
    home_tagTypeTableOtherTextOnly: "Other",
    home_tagTypeTableCodeTypeName: "Code type name",
    home_tagTypeTableDescription: "Description",
    home_tagTypeTableStatic:
      "This is a tag on an item to keep track of it. It can be a tool, a computer, a book, or something else",
    home_tagTypeTableBoxes:
      "This is a tag on a box that has some content. Typically the content is described (in text and/or photo) when clicking on it",
    home_tagTypeTableLostFound:
      "This is a tag on an item that is explicity taged for it to be easier to be returned to it's owner",
    home_tagTypeTableFreezer: "This is a tag for items you put in the freezer. It is to keep track of your food",
    home_tagTypeTableMessage:
      "This is a tag that simply has a text (or html) message attached to it. A text that anyone that the owner will see as just the message/html",
    home_tagTypeTableOther:
      "If it is not one of the standard tags, the sheets (at the top of the page) can also be lists (go to 'Manage lists' in the menu to learn more)",

    //Item.tsx
    item_shareItemText: "I want to share my item with you.",
    item_status: "Status:",
    item_whoBorrowed: "Who borrowed the item?:",
    handleBorrowingItem_whenGiveBack1: "When should it be returned? in ",
    handleBorrowingItem_whenGiveBack2: "days",
    item_photo: "Image:",
    item_atHome: "At home",
    item_isPublicViewable: "Is publicly visible",

    // ItemView
    itemView_CodeDoesNotExist: "This code does not exist or cannot be scanned properly",

    // ListItems.ts
    listItems_state: "State",
    listItems_loading: "Loading...",
    listItems_home: "Home",
    listItems_giveBack1: "Should be returned in ",
    listItems_giveBack2: "days",
    listItems_awayFor1: "Borrowed in ",
    listItems_awayFor2: " days more",
    listItems_photo: "",

    // ListHtmlItems
    listHtmlItems_created: "Created",
    listHtmlItems_description: "Message",

    //ItemImage
    itemImage_NoImage: "No image",

    //ListPuchases
    listPurchases_errorMsgUpdatePurchases: "Unable to read purchasing data from database.",
    listPurchases_purchaseCompletedColText: "Paid",
    listPurchases_tagSentColText: "Sent",
    listPurchases_stateOrActionColText: "Action",
    listPurchases_digitalColText: "Pdf",
    listPurchases_createdColText: "Created",

    // HandleBorrowedItem.ts
    handleBorrowedItem_reportReturned: "Report as returned",

    // HandleBorrowingItem.ts
    handleBorrowingItem_infoHeader: "About view borrowed item",
    handleBorrowingItem_Info1: "You have scanned a code from someone else.",
    handleBorrowingItem_Info2: "This page shows information about that code, but you can also choose to borrow it.",
    handleBorrowingItem_Info3: "Before clicking Borrow, choose how long you would like to borrow it.",
    handleBorrowingItem_Info4:
      "When you click Borrow, the owner will have the information that you now are borrwing the item.",
    handleBorrowingItem_Info5: "You and the owner will get a notification when you are to return it.",
    handleBorrowingItem_wantToBorrowFrom: "Borrow item?",
    handleBorrowingItem_oneDay: "in one day",
    handleBorrowingItem_threDays: "in three days",
    handleBorrowingItem_oneWeek: "in one week",
    handleBorrowingItem_onemonth: "in one month",
    handleBorrowingItem_threeMonths: "in three months",
    handleBorrowingItem_oneYear: "in one year",
    handleBorrowingItem_noEndDate: "no end date",
    handleBorrowingItem_borrow: "Borrow",
    handleBorrowingItem_OwnerName: "Owners name: ",
    handleBorrowingItem_TypeOfItem: "Type of item",
    handleBorrowingItem_ItemName: "Item name: ",
    handleBorrowingItem_InfoAboutRent: "Information about renting",
    handleBorrowingItem_SendMessToOwner: "Send message to owner",
    handleBorrowingItem_whenReturnItem: "When do you plan to return the item?",
    handleBorrowingItem_Category: "Category: ",

    // HandleItemBorrowedByMe.ts
    handleItemBorrowedBy_reportItemBack: "Report item as returned",
    handleItemBorrowedByMe_YouBorrowedThis: "You borrow this item",
    handleItemBorrowedByMe_Info1: "You have scanned a code on an item that you have borrowed from someone else.",
    handleItemBorrowedByMe_Info2: "This page shows information about that code.",
    handleItemBorrowedByMe_Info3:
      "By clicking on the confirm return you mark this item as returned. It may be that the owner is requiring to confirm it his/herself.",
    handleItemBorrowedByMe_ShouldBeReturnedBy: "Should be returned by: ",
    handleItemBorrowedByMe_ReturnIn: "Return in ",
    handleItemBorrowedByMe_Days: "days STR",
    handleItemBorrowedByMe_RentCost: "Rent cost",
    handleItemBorrowedByMe_infoHeader: "About view item borrowed by me",
    handleItemBorrowedByMe_oneDay: "one day",
    handleItemBorrowedByMe_threDays: "three days",
    handleItemBorrowedByMe_oneWeek: "one week",
    handleItemBorrowedByMe_onemonth: "one month",
    handleItemBorrowedByMe_threeMonth: "three months",
    handleItemBorrowedByMe_oneYear: "one year",
    handleItemBorrowedByMe_noEndDate: "no end date",
    handleItemBorrowedByMe_extend: "Extend",
    handleItemBorrowedByMe_extendDate: "Extend return date",
    handleItemBorrowedByMe_addNumDays: "Add number of days",

    //HandleItemBorrowedByOther.ts
    handleItemBorrowedByOther_ReportReturned: "Report item as returned",
    handleItemBorrowedByOther_BorrowedItem: "Someone is borrowng this item",
    handleItemBorrowedByOther_BorrowedBy: "Borrowed by:",
    handleItemBorrowedByOther_ShouldBeReturnedBy: "Should be returned by: ",
    handleItemBorrowedByOther_ReturnIn: "Return in",
    handleItemBorrowedByOther_Days: "days",
    handleItemBorrowedByOther_info1: "You have scanned a code that somone else has borrowed from you",
    handleItemBorrowedByOther_info2: "This page shows information about that code",
    handleItemBorrowedByOther_info3:
      "By clicking on the confirm return you mark this as returned. Do not do this unless you have gotten the item back.",
    handleItemBorrowedByOther_rentCost: "Rent cost",

    // HandleItemOtherBorrowedByOther.tsx
    handleItemOtherBorrowedByOther_Title: "Someone elses item borrowed by other",
    handleItemOtherBorrowedByOther_infoHeader: "About item borrowed by other",
    handleItemOtherBorrowedByOther_info1: "You scanned an item that somone else is borrowing from someone else",
    handleItemOtherBorrowedByOther_info2:
      "You could contact the owner and ask if you can borrow it after it is returned",

    // HandleOwnItem.ts
    handleOwnItem_itemHeader: "About view own item",
    handleOwnItem_unregisterItem: "Unregister item",
    handleOwnItem_TypeOfItem: "Type of item:",
    handleOwnItem_unregister: "Unregister",
    handleOwnItem_close: "Close",
    handleOwnItem_realyUnregister: "Do you really want to unregister the item?",
    handleOwnItem_resetCode: "Removed from freezer",
    handleOwnItem_QRnumber: "QR number",
    handleOwnItem_QRCode: "QR code: ",
    handleOwnItem_PageHeader: "Item info",

    // handleOwnHtmlItem.ts
    handleHtmlItem_PageHeader: "Message",
    handleHtmlItem_reset: "Unregister",
    handleHtmlItem_QRCode: "QR-code: ",
    handleHtmlItem_TagId: "Short id: ",
    handleOwnHtml_infoHeader: "About view own message",
    handleOwnHtml_TextOrHTML: "Message (Text or HTML):",
    handleOwnHtml_info1: "You have scanned one of your own QR-codes with a message. ",
    handleOwnHtml_info2: "The short message name is the name this message will have in your message list.",
    handleOwnHtml_info3:
      'The "Free text or HTML" is what others will see when they scan this code, in addition to any added images',
    handleOwnHtml_info4:
      "If you have choosen to just write text, it will be seen by the person scanning it as just like that.",
    handleOwnHtml_info5: "If you have choosen to write HTML, the person scanning it will see that formated HTML.",
    handleOwnHtml_info6: "If you want to edit the text, press Edit.",

    // HandleOwnLostFound
    handleOwnLostFound_LostFound: "Lost&found info",
    handleOwnLostFound_ShowFindersFee: "Show finders fee: ",
    handleOwnLostFound_FindersFee: "Finder's fee: ",
    handleOwnLostFound_ShowYourPhoneNum: "Show your phone number: ",
    handleOwnLostFound_MessageSendingEnabled: "Message sending to owner enabled: ",
    handleOwnLostFound_ResetCode: "Reset code",
    handleOwnLostFound_FinderCanSendGPS: "Finder can send GPS position",
    handleOwnLostFound_infoOnLostFound: "Info on view own lost&found",
    handleOwnLostFound_thisIsLostFoundCode: "This is a lost&found code.",
    handleOwnLostFound_whenYouMarkSometingWithTag:
      "When you mark something with this tag, anyone that finds it will be able to help you bring this back. ",
    handleOwnLostFound_whenYouEditThisTag:
      "When you edit this tag you will be able to set all the options you can see on this page, and possibly more.",

    // HandleOtherLostFound
    handleOtherLostFound_HowToReturnItem: "How to return item to owner?",
    handleOtherLostFound_YouHaveScannedCode:
      "You have scanned a code on an item that the owner really would like to get back. This page explains how you can help returning it to the owner. Please help out, the owner will be super happy!",
    handleOtherLostFound_FindersFee: "Finder's fee",
    handleOtherLostFound_ThereIsAFindersFee: "There is a finder's fee! If you return item to the owner you will get ",
    handleOtherLostFound_OwnersPhoneNum: "The owner's phone number is ",
    handleOtherLostFound_PhoneNum: "Phone number",
    handleOtherLostFound_PleaseCallOrSend: ". Please call or send a message to return the item to the owner!",
    handleOtherLostFound_SendMessToOwner: "Send a message to owner",
    handleOtherLostFound_YouCanChooseSendMess:
      "You can choose to send an anonymous message to the owner through this app. You can for example explain where the item can be picked up, or arrange a meeting/pickup.",
    handleOtherLostFound_SendMess: "Send message",
    handleOtherLostFound_infoHeader: "About view other's lost&found",
    handleOtherLostFound_info1: "You have found something that someone else has lost.",
    handleOtherLostFound_info2: "This app can help you get the item back to the owner, who will be very happy!",
    handleOtherLostFound_info3: "There is a finders fee that you will recieve it you get the item back to the owner.",
    handleOtherLostFound_info4: "You can call or text the owner on the phone number shown.",
    handleOtherLostFound_info5:
      "You can send a message to the owner anonymously. There you can write where to pick it up (e.g., if you brought it to some lost&found venue (police or other).",
    handleOtherLostFound_info6:
      "You can mark the spot where you placed/found the item for the owner by sending a GPS position.",
    handleOtherLostFound_SendGPSPosToOwnerHeader: "Send a GPS position to the owner",
    handleOtherLostFound_SendGPSPositionToOwnerInfo:
      "You can choose to send a GPS position to the owner so that he/she can easily find where you left the item.",
    handleOtherLostFound_SendGPSPosToOwnerButton: "Send GPS positon",
    handleOtherLostFound_SendingGPSPosToOwner: "Sending GPS position to owner",
    handleOtherLostFound_GeolocationIsNotSupported: "Geolocation is not supported by your browser",
    handleOtherLostFound_HasSentGPSPosToOwner: "GPS position sent!",
    handleOtherLostFound_GpsLinkMessage: "Someone has sent you a GPS position this item ",

    // HandleOthersFreezer
    handleOthersFreezer_infoHeader: "About view others' freezer",
    handleOthersFreezer_info1: "You have scanned someone elses food/box.",
    handleOthersFreezer_info2: "This page shows information about the item.",
    handleOthersFreezer_info3:
      "If you have the right to edit the information about this item, there will be Edit button that you can press.",
    handleOthersFreezer_PageHeader: "Someone elses food",
    handleOthersFreezer_Owner: "Owner:",
    handleOthersFreezer_ContentType: "Type of food:",
    handleOthersFreezer_FoodType: "Content:",
    handleOthersFreezer_FreezDate: "Freeze date:",
    handleOthersFreezer_ExparationDate: "Expiration date:",
    handleOthersFreezer_ForHowMany: "For how many persons:",
    handleOthersFreezer_HowMuch: "How much:",
    handleOthersFreezer_Location: "Location",

    //handleOthersBoxes
    handleOthersBoxes_PageHeader: "Someone elses box",
    handleOthersBoxes_Info1: "You have scanned someone elses storage box.",
    handleOthersBoxes_Info2: "This page shows information about that box.",
    handleOthersBoxes_Info3:
      "If you have the right to edit the information about this box, there will be Edit button that you can press.",
    handleOthersBoxes: "About view other's box",

    //handleOthersBoxes
    handleOwnBoxes_PageHeader: "My box",
    handleOwnBoxes_infoHeader: "About view own box",
    handleOwnBoxes_PageHeaderEdit: "Edit",

    // HandleBoxes
    handleBoxes_Owner: "Owner: ",
    handleBoxes_ShortID: "Short id: ",
    handleBoxes_ContentType: "Content type: ",
    handleBoxes_Content: "Content: ",
    handleBoxes_Description: "Details: ",
    handleBoxes_Location: "Site: ",
    handleBoxes_Room: "Room/location: ",
    handleBoxes_Category: "Category: ",
    handleBoxes_Fragility: "Fragile",
    handleBoxes_Priority: "Priority: ",
    handleBoxes_QRcode: "QR-code",
    handleBoxes_BoxColor: "Color marking: ",
    handleBoxes_BoxState: "Box state: ",
    handleBoxes_StorageDate: "Last updated: ",
    handleBoxes_ShortId: "Short id:",
    handleBoxes_0: "This is where you see the detailed information about the box/storage container.",
    handleBoxes_1: "The box has a short ID of 2-4 letters, which is the marker on the right part of the sticker.",
    handleBoxes_2:
      "The Box State tells you if the box is being packed, if it is closed, stored, or being unpacked, for example.",
    handleBoxes_3: "The Category is general description/categorization of what the box containt.",
    handleBoxes_4: "The Description is describing the content to the extent that you wish.",
    handleBoxes_5:
      "The Site is the information about what general physical location the box is at (for example adress).",
    handleBoxes_6: "The Room/Location tells you where in the Site it is located.",
    handleBoxes_7: "Fragility is a simple flag that will tell you about if the content is fragile or not.",
    handleBoxes_8:
      "Priority is for you to indicate if this box should be moved as soon as possibly (high) or normally, or if it can be moved whenever (later).",
    handleBoxes_9: "The last edit date tells you when you last updated this item' information.",
    handleBoxes_10: "The QR-code is the unique code associated with the tag.",

    // EditBoxes
    EditBoxes_ContentTypeHeader: "Box content type",
    EditBoxes_ContentHeader: "Content",
    EditBoxes_BoxName: "Box name",
    EditBoxes_Location: "Site",
    EditBoxes_Room: "Room/location",
    EditBoxes_QRID: "Short ID",
    EditBoxes_BoxID: "Short ID",
    EditBoxes_QRcode: "QR-code",
    EditBoxes_LocationHeader: "Site",
    EditBoxes_RoomHeader: "Room",
    EditBoxes_CategoryHeader: "Category",
    EditBoxes_BoxStateHeader: "Box state",
    EditBoxes_BoxPriorityHeader: "Box priority",
    EditBoxes_BoxColorHeader: "Box color",
    EditBoxes_InfoHeader: "About edit box",

    // EditBoxInfo
    EditBoxInfo_BoxInfoHeader: "How does QR-tagging boxes work?",
    EditBoxInfo_PlaceQR: "Place a QR code on your moving box.",
    EditBoxInfo_ScanQR: "Scan the QRcode and register/log in as needed.",
    EditBoxInfo_Packing: "Now you can start packing and maybe set the box state to Packing, to keep track.",
    EditBoxInfo_AddingCatDetails:
      "As you pack it you can add either one or more Categories or write the content under Details.",
    EditBoxInfo_BackLater:
      "If you come back at a later time, just scan the label again and continue packing and edit the content in the app.",
    EditBoxInfo_FinishedPacking:
      "When you have finished packing a box, set the Box State as Closed. If you want it moved to a specific room in the move, you can set the Room/Place.",
    EditBoxInfo_AddingPrioFrag: "You can in the app set the box Priority and Fragility on a box",
    EditBoxInfo_PutInStorage:
      "If you put it in storage, you can set Box State to Stored. Then it is also good to set the Location, and if applicable, the Room/Place.",

    // HandleFreezer.ts
    handleFreezer_freezing_date: "Freezing date",
    handleFreezer_persons: "Persons",
    handleFreezer_noDateSet: "No date set",

    // HomePageText.ts
    homePageText_howWorks: "How does it work?",
    homePageText_prices: "Prices",
    homePageText_toThinkAbout: "To think about",
    homePageText_tagsFromUs: "Order tags from us - digitally or sent to you via mail.",
    homePageText_tagOntoTool: "Stick a unique QR-code on, for example a tool.",
    homePageText_scanWithCell:
      "Scan the QR-code with your smartphone - if it is the first time you use QRit, register.",
    homePageText_addDescript: "Add a description of the item and maybe add a photo.",
    homePageText_ifSomoneBorrow:
      "If someone wants to borrow or rentthe item, it is only for them to scan the QR-code and register a borrowing/renting with their smart phone. No app installation is needed.",
    homePageText_weKeepTrack:
      "We keep track of when it should be returned and give reminders to both you and the borrower when it is time to return it.",
    homePageText_qritIsServiceSubscr:
      "QRit.app is a subscription service where you subscribe to QR-code usage on a yearly basis.",
    homePageText_subscript8kr: " tags cost ",
    homePageText_subscript8krEnd: "/tag and year",
    homePageText_12TagsCost: " tags cost ",
    homePageText_12TagsCostEnd: "/year",
    homePageText_24TagsCost: " tags cost ",
    homePageText_24TagsCostEnd: "/year",
    homePageText_buyPhysicalTags:
      "If you want to buy printed tags that you print yourself on standard stickers, order Avery L4778 - for example here: ",
    homePageText_alsoJusgtPrintAndTejp: "You can also just print the tags on normal paper, and use scissors and tejp.",
    homePageText_attTankaPa: "To think about",
    homePageText_ifNotPrintWeSend:
      "If you do not want to print the tags yourself you can order them from us as physical sticky tags for ",
    homePageText_ifNotPrintWeSendEnd: " (up to 48 stickers per shipment) – this is on-top of the subscription fee.",
    homePageText_tagsMustPlanar: "The tags must be attached to a fairly planar surface for the QR-scanning to work",
    homePageText_size2x4: "The tags are today available in the size 2x4 cm",
    homePageText_partOfTagsWhite:
      "Part of the tags are white – you can use it to write on, or remove it with scissors.",
    homePageText_ifPDF:
      "If you choose to download digital (pdf) tags, you can print them yourself directly – the tags are available in standard formats, but you can also just print it yourself and use tejp.",
    homePageText_priceTablePrice: "Price",
    homePageText_priceTableCurrency: "Currency",

    // HomePage
    homePage_infoHeader: "About home view",
    homePage_infoGeneralHelp: "General help",
    homePage_info0: "This is the main page of the QRit app",
    homePage_info1: "You can click on an item",
    homePage_info2: "or you can choose to switch between types of tags by clicking on the tabs",
    homePage_info3: "You can also switch between tag types by swiping left or right",
    homePage_info4: "If you want to search all tags, just start typing what you want to find in the search field",
    homePage_info5:
      "You can switch between viewing the items as lists or as a grid. The latter works best when all items has images",
    homePage_info6:
      "You can add more types of tags as additional tabs or change what types are shown in the tabs in Settings in the menu",
    homePage_info7: "You can click on the header of an item to sort it",
    homePage_info8: "If you want to purchase tags on-line, you do that through the menu at the top right",
    homePage_grid: "Grid",
    homePage_list: "List",

    // TagTypeOverview
    tagTypeOverview_pageHeader: "QRit - bridge the physical/digital gap",
    tagTypeOverview_intro:
      "QRit is a set of services that through the use of QR-codes helps you keeping track of things in your life. The services fits both private persons and companies. See our services below:",
    tagTypeOverview_RegisterLoginInfo:
      "To be able to use QRit services you need to register (or, if you are registered, log in).",
    tagTypeOverview_RegisterLogin: "Register/log in",
    tagTypeOverview_lblHeaderQRitStorage: "QRit Storage",
    tagTypeOverview_QRitStorage1:
      "Don’t forget where you keep your stuff! With QRit Storage tags you can keep track of where you store what. Tag the boxes in the attic, in the garage, or where it is in your apartment or house. By scanning the QR-code you can then set for example the location and the content of the box.",
    tagTypeOverview_QRitStorage2:
      "You can also add images - for example showing the content of the box, or where you keep it. Through the QRit-app you can search for content and find out where you keep it.",
    tagTypeOverview_QRitStorage3: "Pick up a kit of QRit Storage at your local retailer (find nearest here). ", //, or buy tags directly from us here (either digitally or physically)
    tagTypeOverview_lblHeaderQRitKeys: "QRit keys",
    tagTypeOverview_QRitKeys1:
      "Make it easier to get you keys back if you lose them! Put a QRit Keys tag on you keychain and when you lose it, the finder can scan the tag and communicate with your anonymously. That is, you get direct communication (chat) with the person finding the keys, without them knowing who you are.",
    tagTypeOverview_QRitKeys2:
      "Pick up a kit of QRit Keys tags at your local retailer (find nearest here). QRit Keys is a subscription service.", //, or buy tags directly from us here
    tagTypeOverview_lblHeaderQRitStatic: "QRit Stuff",
    tagTypeOverview_QRitStatic1:
      "Let neighbors, friends and family borrow your tools, books, and other stuff, while keeping track of it all. You can even make public lists of stuff and rent it out. Start with tagging your stuff with our QR tags. Then scan them and add information about what it is and maybe add a photo of it.",
    tagTypeOverview_QRitStatic2:
      "When someone else than the owner scans the tag after it has been registered, the 'scanner' ask to borrow or rent it, including indicating how long they want it. When the return date is getting close both you and the borrower will get a notification. You can also indicate how much someone that rents it should pay per day or maybe week. ",
    tagTypeOverview_QRitStatic3:
      "Pick up a kit of QRit Keys tags at your local retailer (find nearest here). QRit Stuff is a subscription service.",
    tagTypeOverview_lblHeaderQRitLostFound: "QRit Lost&Found",
    tagTypeOverview_QRitLostFound1:
      "Increase the chance of getting back items you lose! With QRit Lost&Found you tag stuff with QRit QR-codes so that when you loose it, others can scan the code and get it back to you. By using QRit you can have the finders communicate with you anonymously and even have them mark the spot they found it with their phone’s GPS so that you can go it.",
    tagTypeOverview_QRitLostFound2:
      "Pick up a kit of QRit Keys tags at your local retailer (find nearest here). QRit Lost&Found is a subscription service.",
    tagTypeOverview_lblHeaderQRitFreezer: "QRit Freezer",
    tagTypeOverview_QRitFreezer1:
      "Tired of not know knowing what is the freezer? With QRit Freezer you can QR-tag your food, list the content of the freezer, and get notifications when items are about to expire. You just tag your food items, scan the QR-code, and then enter what it is (e.g., the type of food or dish), how much (e.g., servings or weight, and, if you want, when it expires. ",
    tagTypeOverview_QRitFreezer2:
      "When you take it out of the freezer you quickly scan the QR-code and update to “Removed from freezer”. ",
    tagTypeOverview_QRitFreezer3:
      "Pick up a kit of QRit Keys tags at your local retailer (find nearest here). QRit Stuff is a subscription service.",
    tagTypeOverview_lblHeaderQRitHtml: "QRit Message codes",
    tagTypeOverview_QRitHtml1:
      "A QRit Message code can contain a webpage with information, or simply some text or an image.",
    tagTypeOverview_QRitHtml2: "[TODO: Write]",
    tagTypeOverview_business: "Business",
    tagTypeOverview_private: "Private",

    tagTypeOverview_pageHeader_BB: "QRit - bridge the physical/digital gap",
    tagTypeOverview_intro_BB:
      "QRit is a set of services that through the use of QR-codes helps you keeping track of things in your life:",
    tagTypeOverview_RegisterLoginInfo_BB:
      "To be able to use QRit services you need to register (or, if you are registered, log in). To create a business account, log in with your phone number and go to settings. If you are a QRit admin for an organisation already, you will get the option to choose if you want to log in privately or for the company when you have filled in the verification code.",
    tagTypeOverview_lblHeaderQRitStorage_BB: "QRit Storage",

    tagTypeOverview_lblHeaderQRitKeys_BB: "QRit Anonymous",
    tagTypeOverview_lblHeaderQRitStatic_BB: "QRit ItemTracking",
    tagTypeOverview_lblHeaderQRitLostFound_BB: "QRit Lost&Found",
    tagTypeOverview_lblHeaderQRitFreezer_BB: "QRit Restaurant",
    tagTypeOverview_lblHeaderQRitHtml_BB: "QRit Information",

    tagTypeOverview_lblHeaderQRitRestaurant_BB: "QRit Restaurant (future service/feature)",
    tagTypeOverview_QRitStorage1_BB:
      "Don’t forget where you keep your boxes! With QRit Storage tags you can keep track of what you store where. Tag the boxes in offices, storage, when moving, or across office buildings. By scanning the QR-code you can then set for example the location and the content of the box.",
    tagTypeOverview_QRitStorage2_BB:
      "You can also add images - for example showing the content of the box, or an image of where it is stored. Through the QRit-app you and your collegues can search for content and find out where you keep what.",
    tagTypeOverview_QRitOrderCodesHere_BB: "",
    tagTypeOverview_QRitKeys1_BB:
      "Make it easier to get keys, cards or other sensitive items at your organisation back if they are lost. Put a QRit Anonymous tag on you keychain, your RFid card, and when anythng is lost, the finder can scan the tag and communicate with you anonymously. You can also set a finders fee that a finder can see, incentivicing them to get it back to you. You can also include information about the item that only authorized people in your organisation can see. This may include what the key is for, a password (but care should always be taken with passwords), or the content (e.g., if a USB-stick or hard drive).",
    tagTypeOverview_QRitKeys2_BB: "",
    tagTypeOverview_QRitStatic1_BB:
      "Keep track of equipment across your organisation. Tag your tools, books, computers, and other items with QRit ItemTracking tags and add information about it, such as what it is and including pictures of it. Anyone that scans the code will get custom information about the item. For example, if someone on the companies trusted list (e.g., employees) scans the QR-code, he/she may get information about where it is located or who has it now. If anyone external scans it, it is possible to set it up in several differnet ways, such as making it possibel for 'scanners' to communicate with you to hand it back (if it is lost), or the code is used as a way to keep track of items that customers are borrwing (with the possibility to add how long they shoudl borrow it, and with hand-back notifications).",
    tagTypeOverview_QRitStatic2_BB:
      "When someone else than the owner scans the tag after it has been registered, the 'scanner' can ask to borrow or rent it, including indicating when they should return it. When the return date is getting close both you and the borrower will get a notification. You can also indicate how much someone that rents it should pay per day or maybe week. ",
    tagTypeOverview_QRitStatic_BB: "",
    tagTypeOverview_QRitLostFound1_BB:
      "Increase the chance of getting back items that are lost. With QRit Lost&Found you tag stuff with QRit QR-codes so that when you loose it, others can scan the code and get it back to you. By using QRit you can have the finders communicate with you anonymously and even have them mark the spot they found it with their phone’s GPS so that you can go it. This is similar to QRit Anonymous, but as stickers.",
    tagTypeOverview_QRitLostFound2_BB:
      "This is useful for keys, cards, and other items that are easily lost - with the added benefit of the finder being able to communicate without knowing who you are. This type of code can also be used to add information for yourself (or others in your organisation), that no one else can see. It may be a password (but still take care...), or what akey is for, or the content of a USB-stick or hard drive.",
    tagTypeOverview_QRitHtml1_BB:
      "A QRit Information code can contain a webpage with information, or simply some text. This can directly be contact information, instructions on how to work a piece of equipmnet, or it can be used as a custom price tag (this typically require some integration of your priceing database with the QRit service). ",
    tagTypeOverview_QRitRestaurant1_BB:
      "A QRit Restaurant code is a way to hande recycling of for example take-away boxes in restaurants (http://publications.europa.eu/resource/cellar/7ec2bc0d-e5b2-4c09-bf6f-dce483160b6d.0015.03/DOC_2)",
    tagTypeOverview_QRitRestaurant2_BB:
      "The restaurant tags 'hard shell' boxes with a QRit Restaurant code and when the cutomer gets it, he or she scans it, confirming that they have recieved it. When it is returned, the restaurant scans it again, for reuse with other customers.",

    // Privacy.ts
    privacy_deliveryTermsHeader: "Terms of delivery",
    privacy_deliveryTerms:
      "QR-codes that are ordered as a digital file (pdf) is typically  fil (pdf) is typically sent within two working days. QR-codes that are ordered at printed tags (via normal mail) is typically sent as as an economy letter within 4 dagar. Deviations may occur. For the printed tags, if the address you supply is wrong (and thus the tags do not arrive) we reserve the right to charge a new full delivery cost for a second shipment. If the given email address is wrong, send us the correct one via email (order@qrit.app) and we will update it and send it again.",
    privacy_deliveryTermsMissDelivery:
      "If we cannot deliver within withing the agreed time, we have the responsbility to inform you about a new approximate delivery time. If we cannot deliver in a reasonable time, you have the right to cancel the purchase via email (order@qrit.app – provide the the registered phone number and the date or your order). If you want to cancel your purchase, the payed amount will be payed back, including potential delivery fees. QRit will return it as soon as possible, but no later than 14 days after the cancelation date. Repayment will be done via the same payment option that you payed with.",
    privacy_personalInfoHeader: "Personal details",
    privacy_personalInfoUseInfo:
      "Personal data that you provide when you use the QRit.app is used to fullfill our obligation (service) towards you, and, in general provide you good service. Ths includes contacting you if there are problems with our service (e.g., delivery), and, if you choose physical delivery, make it possible for us to send you a package with QR-tags via mail. QRit will, as personal data responsbile , not hand over your personal details to any third party for marketing purposes. However, data may be be sent to a third party, if needed to fullfill our obligations to you. We may, for example, print tags at a third party (printer compary). You can at any time recall your consent to QRit.app use of your data. You can contact us via email on info@qrit.app.",
    privacy_cookies: "Cookies",
    privacy_cookiesInfo:
      "QRit.app are using cookies on our website to make sure we can fullfill our services toward you. When you in the future visit us (e.g., our website), or when you open an email message from us, QRit.app may get access to the cookies that are stored on your computer/phone. If you want to remove cookies that are already on your computer/phone, we ask you to go through the instructions on your device. Removal of our cookies and/or do not allowing cookies from QRit.app, we may not be able to provide you with the full service (e.g., not getting access to all functions or having to log in manually every time). QRitapp will also store your user information on your device (phone), to enable scanning of codes etc. Such registration will happen as soon as the registration is stored, and for all our services.",
    privacy_infoStorageHeader: "Storage and security",
    privacy_infoStorageInfoSSL:
      "Our site www.QRit.app uses the HTTPS-protocol with SSL-certifikat, to protect our and your data.",
    privacy_infoStorageInfoEncrypt:
      "QRit.app protect  data, phone number, address, etc, from others access, using techneques such as dynamic data masking and transparent data encryption.",
    privacy_personalInfoPurchaseHeading: "Personal information on payment",
    privacy_personalInfoPurchaseInfo:
      "We use Klarna (www.klarna.se) for payments with creditcard. See https://www.klarna.com/se/dataskydd/. Klarna fullfills the requirements for PCI DSS",
    privacy_pricesHeader: "Prices",
    privacy_pricesInfo:
      "When purchasing via the webservice/app (QRit) it is the prices that are shown on the website that are used. The unit of the currency is the one shown, and include VAT. The subscription prices does not include any additional costs, such as the costs of physical tags (sent via mail). That is, you will, if you purchase pre-printed tags pay a handling cost for printing and delivery (economy mail), and this cost is not included in the subscription fee. You can only pay in the ways shown on the website. QRit.app reserves the right not to offer all shown ways of payment, if, for some reason, it does not work, or you are not eligible to pay in that particular way. Confirmation of your payment will be sent via email.",
    privacy_giveBackHeader: "Reklamation",
    privacy_giveInfo:
      "For any complaint, contact us at info@qrit.app, and we will inform you how to proceed (e.g., to get a refund, when applicable).",
    privacy_returningHeader: "Product return right",
    privacy_returningInfo:
      "Consumers typically have 14 days right-to-return a product when purchased on-line. However, digital content is not included in this right. By accepting these terms you confirm that you understand that you cannot return QR-codes purchased digitally for a refund. However, if you purchase the QR-codes physically (via mail – physical delivery), you have the right to return the product (tags) within 14 days for a full refund.",
    privacy_exchangeHeader: "Exchange rights",
    privacy_exchangeInfo: "It is not possible to exchange/switch services.",
    privacy_payBackHeader: "Refund",
    privacy_payBackInfo:
      "We want to make you aware that it may take a few days before the refund arrives in your account. Any refund will be paid through the same payment method as the original payment was made in.",
    privacy_payBackInfoDelivery:
      "Refund of shipping costs after complaint – if the user returns tags unused (or damaged on arrival), any mail costs (cheapest option) should be returned (payed) to the user within 14 days from when QRit recieves the tags.",
    privacy_goodToHaveLinksHeader: "Links",
    privacy_goodToHaveLinksConsumerService: "Ångerblankett från Konsumentverket ",
    privacy_goodToHaveLinksConsumerARN: "ARN (ARN) Postadress: Box 174, 101 23 Stockholm ",
    privacy_goodToHaveLinksArbitration: "Tvistelösning på Internet (Tvist)",
    privacy_complaintsHeader: "Complaints",
    privacy_complaintsInfo: "Complaints should be send to info@QRit.app",

    // Purchasing.tx
    purchasing_payment: "Payment",
    purchasing_ifPurchaseDigital: "If you order QR-codes digitally (pdf-file), verify the email address.",
    purchasing_ifPurchasePhysical: "If you order physical tags that we sent to you, verify your postal address.",
    purchasing_order: "Order",
    puchasing_youLoggedInLoginNow: "You have to be logged in in order to make a purchase. Please login/register first.",
    purchasing_payPaypal: "Pay with Paypal",
    purchasing_paySwish: "Pay with Swish",
    purchasing_payKlarna: "Pay with Klarna",
    purchasing_paySwishInstructions: "Pay with the Swish app by scanning the QR code.",
    purchasing_fullName: "Full name",
    purchasing_firstName: "First name",
    purchasing_lastName: "Last name",
    purchasing_address: "Address",
    purchasing_zipCode: "Zip code",
    purchasing_city: "City",
    purchasing_country: "Country",
    purchasing_emailPlaceholder: "Write email address here",
    purchasing_email: "Email",
    purchasing_phone: "Phone",
    purchasing_username: "Username",

    // RegisterItem.ts
    registerItem_itemRegistration: "Register item tag",
    registerItem_freezerItemRegistration: "Register freezer tag",
    registerItem_boxItemRegistration: "Register box tag",
    registerItem_lostFoundItemRegistration: "Register lost&found tag",
    registerItem_messageTagItemRegistration: "Register message tag",
    registerItem_messageHtml: "Free text or HTML",
    registerItem_messageName: "Short message name",
    registerItem_alwaysConfirmReturn: "I always want to confirm item return:",
    registerItem_registrerMustHaveBankID: "The borrower must be registered with BankID ([framtida funkt.])",
    registerItem_putItemForRent: "I want to make this item as rentable",
    registerItem_rentInfo: "Info about rent/cost",
    registerItem_lostFound: "I want to configure this item for Lost&Found",
    registerItem_lostFoundInfo: "If this item is lost, enabling this setting will help getting it back.",
    registerItem_configRent: "Settings Renting",
    registerItem_configLostFound: "Settings Lost&Found",
    registerItem_itemRegister: "Register",
    registerItem_failedRegisterCode: "Failed to register the code. Try again later!",

    // EditHtml
    editHtml_PageHeaderEdit: "Edit message",
    editItem_PageHeaderEdit: "Edit",
    editHtml_Instructions: "Instructions",
    editHtml_infoHeader: "Message edit info",
    editHtml_Info1: "Here you can write a message that a person scanning the QR-code will see. ",
    editHtml_Info2: "The short name is what you will see when you list your message codes.",
    editHtml_Info3: 'The "Free text or HTML" is where you add your messag:',
    editHtml_Info4: "If you choose to just write text, it will be seen by the person scanning it as just like that.",
    editHtml_Info5: "If you choose to write HTML, the person scanning it will see that formated HTML.",

    // titleWithInfo
    titleWithInfo: "Information",
    titleWithInfo_reportMisuse: "Give feedback/report misuse",
    titleWithInfo_reportMisuseDescript:
      "Here you can also give us feedback on specific issues with QRit, or general feedback about the services we provide. It is also where you can report misuse, such as when a code has offensive images or in other ways violate our policies.",
    titleWithInfo_reportMisuseSent: "Your feedback has been sent to us. Thank you!",
    titleWithInfo_reportMisuseError: "Error! Try again later!",

    // EditItem.ts
    editItem_typeStatic: "Static (e.g., tools)",
    editItem_typeReuse: "Freezer content handling)",
    editItem_typeReuseLostFound: "Lost&Found",
    editItem_typeReuseFreetext: "Free text",
    editItem_typeStorage: "Storage",
    editItem_typeLabel: "Choose item type",
    editItem_failedSaveCode: " Failed to save code",

    // Edit Static
    editStatic_infoHeader: "About edit item",
    editStatic_PageHeaderEdit: "Edit",
    editStatic_1: "This is where you edit the information about the item that you have tagged with a QR-code.",
    editStatic_2: "Choose a name that makes it easy for you to recognize the item by it.",
    editStatic_3: "Describe the item as much as you want.",
    editStatic_3_1: "Choose if you want to post this item as rentable",
    editStatic_3_2:
      "If you post this item as rentable, add a description about the costs for renting it. For example, describe the cost for renting it a day and/or a week and/or a weekend, and maybe information about how you would like to be paid (e.g. cash, SWISH, apple pay etc).",
    editStatic_4: "You can add a photo, if you wish.",
    editStatic_5:
      "If you want our service to notify you when the return time is approaching (when someone has borrowed it), check the box.",
    editStatic_6: "if you want others to be able to rent this item, check the appropriate box.",
    editStatic_7: "You can also change code type, but then you need to turn on advanced settings.",
    editStatic_photo: "Image",
    editStatic_photoChoice: "Select image",
    editStatic_ISBNScanningInfo: " - scan/add ISBN number",
    editStatic_alwaysConfirmReturn: "I always want to confirm item return",
    editStatic_registrerMustHaveBankID: "The borrower must be registered with BankID ([framtida funkt.])",
    editStatic_putItemForRent: "I want to post this item for rental ",
    editStatic_configLostFound: "Settings Lost&Found",
    editStatic_configRent: "Settings Renting",
    editStatic_lostFound: "I want to configure this item for Lost&Found",
    editStatic_lostFoundInfo: "If this item is lost, enabling this setting will help getting it back.",
    EditStatic_ItemTypesListHeader: "Type of item",
    EditStatic_ItemTypesHeader: "Type of item",
    editStatic_ListsHeader: "Lists",
    editStatic_codeCannotBelongToMoreThanOne:
      "Code cannot belong to more than one list (when it requires ownershop change",
    editStatic_codeWillChangeOwner: "Code will change its owner to the list owner when saved",
    editStatic_ISBNScanningError: "Scanning error, or ISBN not found.",

    // UploadDialog
    uploadDialogImageChoice: "Image selection",
    uploadDialogChooseImage: "Select image",
    uploadDialogUpload: "Save image",
    uploadDialogRotate: "Rotate",

    // ScanDialog
    scanDialog_manualEnter: "or, enter number (e.g., ISBN) here...",

    // useObserveCode
    useObserveCode_ErroTryRefresh: "Error! Try refreshing the page...",

    // RegisterUser.ts
    registerUser_registerUser: "Register/login",
    registerUser_InfoAboutFree:
      "Registering is free, as is using others QR-codes. You must be logged in to purchase tags.",
    registerUser_registerUserName: "Name",
    registerUser_registerPhone: "Phone number",
    registerUser_registerEmail: "Email",
    registerUser_registerphoneNumMustStartWith: "The phone number must start with the country code (e.g., +1)",
    registerUser_registerUsernameNeeded:
      "Username needed to register/login (note that it will be visible to all that scans your codes)",
    registerUser_sendCode: "Send code",
    registerUser_code: "Code",
    registerUser_privacyAgreement: "agreement on personal integrity",
    registerUser_privacyAgreementHere: "Here ",
    registerUser_iAgreeTerms: "By registering I agree to the QRit ",
    registerUser_iAgreeTermsEnd: " (including GDPR agreement).",

    // registerUser
    registerUser_registerUserButton: "Register/login",
    registerUser_networkErrorTryAgain: "Network error! Wait some and try again!",
    registerUser_checkCodeTryAgain: "Check the registration code and try again!",
    registerUser_networkErrorTryAgain2: "Network error! Wait some and try again!",
    registerUser_goToPayment: "Go to payment",
    registerUser_smsTextCode: "Here is your qrit.app activation code:\n",
    registerUser_organization: "Organisation",
    registerUser_meAsUser: "Me as a person",

    // BuyDigitalTags
    buyDigialTags_buyTags: "Buy digital tags (pdf)",
    buyDigialTags_fillInEmail: "Write your email address here",
    buyDigialTags_email: "Email",
    buyDigialTags_howHandlePersonaInfoHere: "Here",
    buyDigialTags_howHandlePersonaInfo: "you can read about how we handle personal integrity.",

    // BuyPhysicalTags
    buyPhysicalTags_buyPhysicalTags: "Buy tags for printing",
    buyPhysicalTags_fillInAddressInfo: "Fill in address information",
    buyPhysicalTags_firstName: "First name",
    buyPhysicalTags_lastName: "Last name",
    buyPhysicalTags_address: "Address",
    buyPhysicalTags_zipCode: "Zip code",
    buyPhysicalTags_city: "City",
    buyPhysicalTags_country: "Country",

    // listMessages
    listMessages_Messages: "Messages",

    // BuyTags
    buyTags_headerBuyQRcodes: "Buying QR-codes",
    buyTags_toUseMustHaveInfo:
      "UNDER DEVELOPMENT (NOT POSSIBLE TO PURCHASE) To use QRit.app you must have unique QR codes to attach to your stuff. Here you order the codes, either for download (pdf-files) for you to print yourselves, or as adhesive labels/tags with the QR codes printed on them. All codes are valid for a year from the registration of the first tag in a purchase (for example 12 tags in a batch/purchase). After a year the subscription of the tags can be renewed.",
    buyTags_chooseHereToBuyDigitalPhysical:
      "Choose here if you want to order QR codes digitally, or ready-made adhesive tags",
    //buyTags_ifYouBuyDigial: 'If you buy the QR-codes digitally we create a pdf for printing. The tag page will look like this [link to example pdf]. It is possible to buy adhesive tags/labels in this format (Avery L4778) both in physical stores and on-line [https://www.amazon.com/Avery-L4778-20-Ultra-Resistant-Labels-Printers/dp/B0040SP0AS]. If you instead want to print the tags on normal paper and use tejp, that works too. There are no additional costs if you choose digital tags.',
    buyTags_ifYouBuyDigial1:
      "If you buy the QR-codes digitally we create a pdf for printing. An example of the tag page can be downloaded ",
    buyTags_ifYouBuyDigialPdfExampleHere: "here",
    buyTags_ifYouBuyDigialPdfExampleURL: "/DemoDemo_ExampleSheet_Empty_90a53177-696a-4905-aa8a-b3815e108ac5.pdf",
    buyTags_ifYouBuyDigial2:
      " (pdf). It is possible to buy adhesive tags/labels in this format (Avery L4778) both in physical stores and ",
    buyTags_ifYouBuyDigialAveryExampleURLHere: "on-line",
    buyTags_ifYouBuyDigialAveryExampleURL:
      "https://www.amazon.com/Avery-L4778-20-Ultra-Resistant-Labels-Printers/dp/B0040SP0AS",
    buyTags_ifYouBuyDigial3:
      ". If you instead want to print the tags on normal paper and use tejp, that works too. There are no additional costs if you choose digital tags.",
    buyTags_selectedTagEmpty: "/buyTags_selectedTagEmptyEng.png",
    buyTags_selectedTagLostFound: "/buyTags_selectedTagLostFoundEng.png",
    buyTags_selectedTagNumber: "/buyTags_selectedTagLostNumberEng.png",
    buyTags_unSelectedTagEmpty: "/buyTags_UnSelectedTagEmptyEng.png",
    buyTags_unSelectedTagLostFound: "/buyTags_unSelectedTagLostFoundEng.png",
    buyTags_unSelectedTagNumber: "/buyTags_unSelectedTagLostNumberEng.png",
    buyTags_ifYouBuyPhysical: "To buy printed adhesive tags cost ",
    buyTags_ifYouBuyPhysical2:
      " (up to 96 tags in one purchase). If you buy them ready-made you will in the next step be asked to add (or verify) your postal address. After you have payed the subscription + the cost for the physical pages, we print them and send them to you.",
    buyTags_downloadPdf: "Next", //'Download a pdf with the tags (price:',
    buyTags_downloadPhysical: "Buy physical tags (price:",
    buyTags_qrCodeToX1: "QR-codes for ",
    buyTags_qrCodeToX2: " items (price: ",
    buyTags_chooseNumCodes: "Select number of codes you want to purchase",
    buyTags_ChoosePhysDigit: "Choose physical or digital tags",
    buyTags_ChooseTagLayout: "Choose tag layout",
    buyTags_Price: "Price: ",
    buyTags_emptyTag: "Empty tag",
    buyTags: "Empty (white)",
    buyTags_lostAndFound: "Lost & found",
    buyTags_numbered: "Unique short id",
    buyTags_numberedForeezer: "Unique short id",

    buyTagsPhysicalOnly_toUseMustHaveInfo:
      "UNDER DEVELOPMENT (NOT POSSIBLE TO PURCHASE). To use QRit.app you must have unique QR codes from us. Here you order them (as adhesive tags with the QR codes printed on them). The codes are valid for a year from the registration of the first tag in a purchase. After a year the subscription of the tags can be renewed. You need to choose...",
    buyTagsPhysicalOnly_chooseNumCodes: "Select the number of codes you want to buy",
    buyTagsPhysicalOnly_selectedFormatSimple: "/selectedSimplePhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_unSelectedFormatSimple: "/unSelectedSimplePhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_selectedFormatRugged: "/selectedRuggedPhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_unSelectedFormatRugged: "/unSelectedRuggedPhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_simpleTagFormat: "Simple/thinner",
    buyTagsPhysicalOnly_ruggedTagFormatInfo: "Rugged/thicker",
    buyTagsPhysicalOnly_freeText: "Free text",
    buyTagsPhysicalOnly_normalOrThickSticker:
      "if you want a normal sticker or a thicker sterdier one. The normal is cheaper.",
    buyTagsPhysicalOnly_typeOfTagYouWant: "the type of tag you want",
    buyTagsPhysicalOnly_theNumberOfTagsYouWant: "the number of tags you want to buy",

    // RegisterItemManually
    registerItemManually_infoHeader: "About manual registering",
    registerItemManually_manualRegisteringItem: "Enter code manually",
    registerItemManually_manualRegisteringItemInfo1:
      "You should only need to do this if a QR-code cannot be scanned. The reason why it cannot may be that the code is damaged, or it is not placed on a flat enough surface (for the camera to be able to scan it properly).",
    registerItemManually_manualRegisteringItemInfo2:
      "Type the 8 character code (found on the tag, next to the QR-code) into the box below. After you press Send you will be sent to the page equivallent to scanning the QR-code. We will check that the code is correct before sending you on. Note, only the characters a-z, A-Z, and 0-9 are allowed.",
    registerItemManually_writeQRcode8Char: "Enter the 8 character long code next to the QR code.",
    registerItemManually_code: "Code",
    registerItemManually_register: "Continue",
    registerItemManually_codeDoesNotExist: "This QR code does not exist. Check the code and try again.",

    // ItemFound
    itemFound_youFoundQR: "You have found an item with a QRit-code!",
    itemFound_someoneMarkedItem:
      "Someone has marked the item you have scanned the QR-code on to make it possible to someone that finds it to return it, if it is lost. You now have the opportunity to make the owner happy and make sure he/she gets it back!",
    itemFound_ownersPhoneNumber: "Owners phone number",
    itemFound_whatMessageDoYouWantToSend: "What message do you want to send to the owner?",
    itemFound_ownersOffersThisAmount: "The owner offers the following reward on returning the item",
    itemFound_insteadSendMessageToOwner:
      "Instead of calling the owner (of the phone number is available) you can send a message anonymously to the owner. In it you can, for example write where you left the item, so that the owner can pick it up him/herself. You can also send your own phone number, if you want to.",
    itemFound_sendMessage: "Send message",
    itemFound_sendGPS: "Send GPS position",
    itemFound_sendGPSInfo:
      'Instead of (or in addition to) sending a message, you can choose to send a GPS message to the owner. You can, for example, place the item somewhere and "tag" it with a GPS position. After you press "Send GPS-position", you will be redirected to allow for GPS-position sharing through QRit. When the owner gets the position, he/she can pick it up where you placed it, without you having to do anything. You can choose to send only the position, or include a message (recommended). If you want to include a message, write it above, before you press the button.',

    // ConfigRent
    configRent_choiceRentHeading: "Settings for renting",
    configRent_howMuchCostLbl: "How much should it cost to rent this item?",
    configRent_enableChatLbl: "I want to be able to disucuss price via chat",
    configRent_allowTimeExtent: "I want the renter to be able to extend the renting time",
    configRent_enableRentQueue: "It should be possible to queue for renting this item",

    // EditLostFound
    // TODO: Clean up
    editLostFound_settingsLost: "Edit",
    editLostFound_settingsLostText:
      'This is where you set the ways a finder can get back to you, making it easier for you to get the item back if you loose it- especially if you have chosen to provide a finders fee (the "finder" will see how much you offer").',
    editLostFound_IwantFinderToSeePhoneNumber: "I want the person that finds it to see my phone number",
    editLostFound_IwantFinderAbleSendMess:
      "I want the person that finds it to be able to send an anonymous message to me",
    configLost_withThisSettingFinderShowPos:
      "With this setting, the person finding the item can send a message to you about where he/she have put it, without revealing his/her phone number.",
    editLostFound_IwantOfferLostPay: "I want to offer a finders fee",
    configLost_IwantOfferLostPayInfo:
      "If you choose to offere a finders fee, the person that find it will know that you offer a finders fee.",
    editLostFound_IwantFinderSeeHowMuchPay: "I want the person finding the item to see how much they will get.",
    configLost_IwantFinderSeeHowMuchPayInfo:
      "If you check this box, the person finding the item will see how much they get in finders fee.",
    editLostFound_HowMuchLostPay: "How much do you want to offer as finders fee for this item?",
    editLostFound_HowMuchLostPayInfo:
      "Make sure you are prepared to pay this when the item is returned! Include currency (e.g., $ or €)",
    configLost_IwantEnablePosition: "I want to enable GPS positioning ",
    configLost_IwantEnablePositionInfo:
      "Enabling GPS positioning means that the person finding the item can choose to only mark the spot where they left it, so that you can find it there (you will get the GPS position). This may make it easier to get it back, as it is a hazzle to meet up/bring something found to an owner.",
    editLostFound_infoHeader: "About edit lost&found",
    editLostFound_info0: "This is where you can edit the lost&fond tag information",
    editLostFound_info1: "Name is just the name if the tag, which will be visible in the code list",
    editLostFound_info2:
      "In the descrption field you can write a description of the item. Note that id someone finds it, this infomration (as well as then name) will be visible to the finder. Consider not revieling you identity in any way there, if you want the tag to be anonymous",
    editLostFound_info3:
      'The "I want the person... phone number" means that whomever scans this code will see the phone number',
    editLostFound_info4:
      'The "Finder can send GPS position" means that the finder will be able to chose a GPS position, which will be sent to you. In this way the finder can mark the place of finding the item',
    editLostFound_info5:
      'The "I want the person... anonymous message" means that whomever scans this code will have the possiblity to send an anonymous message to you',
    editLostFound_info6:
      'The "I want to offer a finders fee" means that you will be able to set a finders fee, which anyone that scans this will get information about. Make sure you are willing to pay!',
    editLostFound_info7:
      'If the "I want the person... see how much they get" i checked, the person scanning the code will see the finders fee value',
    editLostFound_info8:
      "You can then set the value of the finders fee, but do not forget to set the unit (e.g., $, € or kr)",
    // editLostFound_info9: 'The "I want the person... phone number" means that whomever scans this code will see the phone number',
    editLostFound_info10:
      "If you enable advanced settings you will be able to change the type of code to something that is not a freezer tag",
    editLostFound_info11:
      "If you click unregister the code will be unregistered That is, all information will be removed and it will disapear from the app alltogether, including all lists",

    // ItemFooter
    itemFooter_unregisterItem: "Unregister item",
    itemFooter_unregister: "Unregister",
    itemFooter_close: "Close",
    itemFooter_realyUnregister:
      "Really unregister? When unregistering all data will be removed from the app. It will not be visible and you need to scan and add again for it to be useful.",
    itemFooter_ShowAdvSettings: "Show advanced settings",
    itemFooter_WarningDataLostRegisterNew:
      "Warning! If you change code type data for this code will be lost. It will be as if you are registering a code. See details about the code types here [link].",
    itemFooter_ChangeCodeType: "Change code type",
    itemFooter_areYouReadyToLeavePage: "Are you sure you want to leave this page? You will lose unsaved changes.",

    // ListItemHelp
    listItemHelp_Header: "Help on item list",
    listItemHelp_WhereIconHeader: "Understanding where my items are",
    listItemHelp_WhereIconDescr:
      "The icons to the left tell you where the item is. The following describe their meanings",
    listItemHelp_WhereIcon_Home:
      'The item is "at home". That is, you no one is borrowing or renting it. It should be available to you.',
    listItemHelp_WhereIcon_In: "You have borrowed or rented this item from someone. It shoudl be available to you.",
    listItemHelp_WhereIcon_Out:
      "Someone has borrowed or rented this item from you. You should not have it at home now.",
    listItemHelp_WhereIcon_Freezer:
      "This is a QR-tag that you reuse. Typically used to keep track of what is in your freezer. It can be reused multiple times.",
    listItemHelp_OtherHeader: "Other",
    listItemHelp_Descr: "You can add a description to each item.",
    listItemHelp_Image:
      "You can add an image to each item (except for reuse-items). Go to edit if you want to change it. Note that when you change the image the old one is immediatelly removed. ",

    // Freezer
    freezer_DateHeader: "Freeze date",
    freezer_ExpDateHeader: "Expiration date (default 90 days)",
    freezer_SelectADateDotDot: "Select a date...",
    freezer_SelectADate: "Select a date",
    freezer_PersonCntHeader: "For how many",
    freezer_10Persons: "10 persons",
    freezer_0Of1Persons: "{0} of {1} persons",
    freezer_FoodTypeHeader: "Type of food",
    freezer_ContentTypeHeader: "Content",
    freezer_AmountHeader: "Amount",
    freezer_EditFoodChoiceList: "Edit food choice list",
    freezer_EditFoodTypeList: "Edit food type list",
    freezer_EditLocationList: "Edit site list",
    freezer_Location: "Site",
    freezer_PageHeaderEdit: "Edit",

    // EditFreezer
    editFreezer_infoHeader: "About edit freezer info",
    editFreezer_info0: "This is where you set the content of the item you want to put in the freezer",
    editFreezer_info1: 'Type of food is a short name of the content of the freezer item (e.g, "meat" or "soup"',
    editFreezer_info2: "Content is information about the overall type of dish",
    editFreezer_info3: "Description is where you write the detailed information about what it contains",
    editFreezer_info4:
      "Expriation date is the date you set to be able to get notifications. The day of the expiration date you will get a message about its expiration",
    editFreezer_info5: "How many persons is exactly what is says is is...",
    editFreezer_info6:
      "In the Amount field you can add weight, volume or any other metric, you can then choose the unit",
    editFreezer_info7: "The Site is where you say which freezer (or where the freezeris) you put the item in",
    editFreezer_info8:
      "Note that you can choose to customize the list option for Type of food, Content and Site by pressing Edit",
    editFreezer_info9:
      "If you enable advanced settings you will be able to change the type of code to something that is not a freezer tag",
    editFreezer_info10:
      "If you click unregister the code will be unregistered That is, all information will be removed and it will disapear from the app alltogether, including all lists",

    // editTypeModal
    editTypeModal_Delete: "Delete",
    editTypeModal_Add: "Add",

    // Helpers
    Helpers_Scanned: "Scanned",
    Helpers_Unregistered: "Unregistered",
    Helpers_Home: "Home", // ':Inte ,utlånad'
    Helpers_BorrowedByOther: "Borrowed by other",
    Helpers_BorrowedByMe: "Borrowed by you",
    Helpers_OtherBorrowedByOther: "Someone else borrowing someone elses item",
    Helpers_AwaitingReturnAccept: "Awaiting confirmation",
    Helpers_Found: "Found!",
    Helpers_ManageCategories: "Manage choices",

    //listReuseItems
    listFreezerItems_Frozen: "Frozen",
    listFreezerItems_Expiration: "Expiration",

    // ListLostFoundItems
    listLostFoundItems_created: "Created",
    listLostFoundItems_photo: "Photo",
    listLostFoundItems_photoDescription: "Photo/description",

    // lostBoxes
    listBoxes_TagID: "Box id",
    listBoxes_Content: "Content",
    listBoxes_Location: "Site",
    listBoxes_Room: "Room/location",
    listBoxes_Stored: "Stored",
    listBoxes_Photo: "Photo",

    // CreateStoreDialog
    createStoreDialog_Create: "Create",
    createStoreDialog_CreateStoreQuestion: "Create new list?",
    createStoreDialog_StoreName: "Name",

    //listStores
    ListStores_loading: "Loading",

    // editStores
    editStores_allowResharingOfList: "Allow resharing of list",

    // joinStore
    joinStore_acceptJoinList: "Accept joining list",
    joinStore_rejectJoinList: "Reject joining list",

    //ImagePanel
    imagePanel_panelHeader: "Main image",
    imagePanel_mainImage: "Main image",
    imagePanel_addNewImage: "Add new image",
    imagePanel_removeImage: "Remove image",
    imagePanel_private: "Private",
    imagePanel_deleteImage: "Delete image",
    imagePanel_deleteSelectedImages: "Delete image",
    imagePanel_title: "Really make this non-private?",
    imagePanel_text:
      "Are you sure you want to turn this image non-private? If you do, anyone that scans the code will be able to see it.",
    imagePanel_disableInFuture: "Disable this warning in the future?",

    // ViewStore
    viewStore_infoHeader: "About view store",
    viewStore_info1: "This is where you can edit the information and access rights for a list",
    viewStore_info2:
      "The name is the text you will see in the lists overview. It is also the name that others will see about the list, if you share it. Be careful when you choose the name of a list that you will share",
    viewStore_info3:
      "Description is where you add details about what this list is for/about. If you are to share it (more or less) publically, consider not adding any personal identifiable information in the description, if you want to be anonymous",
    viewStore_info4:
      "The checkbox 'Require code owner transfer when adding code to list' is about handling joint lists where one person or organisation will be the owner of both the list and the QR-code information about all items in that list. This may be if you in the family have one person that handles all tools, or at work, where your organisation should be the QRit-owner of all inforamtion added about work (e.g., books or equipment), regarless of who adds the item to the list. Use this option sparsely",
    viewStore_info5:
      "You can choose to delete the store. Note however that if you do that all information about the store will be gone. The codes in it will still exist, but there will be no way back, except adding all codes again",
    viewStore_info7: "You can add users and manage their access by checking the different types of access",
    viewStore_info8:
      "'Administator' means that the user can remove the list, add and remove users, and do everything else, is if he/she were the owner",
    viewStore_info9: "'Edit codes' means that the user can add and remove codes from the list",
    viewStore_info10: "'List users' means that the user can see the users that have access to the list",
    viewStore_info11: "'Pending new user' means that ????",
    viewStore_DeleteStore: "Delete",
    viewStore_Delete: "Delete",
    viewStore_DeleteStoreQuestion: "Delete list?",
    viewStore_Cancel: "Close",
    viewStore_SelectCodes: "Select codes",
    viewStore_MultiSelectControlled: "Codes",
    viewStore_CodesTitle: "Codes",
    viewStore_EditList: "Edit list",
    viewStore_RequireCodesOwnerChangeInfo:
      "Important: Any code that is added to this list will change owner to that of the list owner (this behavior can only be changed when the list is empty).",
    viewStore_RequireCodesOwnerChangeInfoNot:
      "A user adding a code to the list will still be the owner of the code (this behavior can only be changed when the list is empty).",
    viewStore_RequireCodesOwnerChange:
      "If checked, any added code will change owner to the list owner (this behavior can only be changed when the list is empty). If unchecked, the user adding a code to the list will still be the owner of the code.",
    viewStore_AccessHandling: "Manage list access",
    viewStore_Join: "Ask to join list",
    viewStore_CannotReqestJoinList: "Cannot request to join list, please contact support",

    // Stores
    stores_CreateStore: "Create",
    stores_ManageLists: "Manage lists",
    stores_infoHeader: "About managing lists view",
    stores_listBelongsToYou: "This list belongs to you",
    stores_listBelongsToSomeoneElse: "This list belongs to someone else",
    stores_info1: "This is where you can create and manage lists",
    stores_info2:
      "A List is a list of items, where you, or others, have explicilty associated an item with a specific list",
    stores_info3:
      "An owner of a list can add access rights to a list (e.g., view, edit, admin), so that lists can be managed jointly, i.e., by family members, friends, or co-workers",
    stores_info4: "In this view you can create new lists or click on already existing lists to manage them",

    // UserSettings
    userSettings_infoHeader: "About user settings",
    userSettings_manageUserSettings: "User settings",
    userSettings_manageViewTabs: "Manage view tabs",
    userSettings_ChooseWhichTabsToShow: "Choose which code types to show:",
    userSettings_ChooseWhichListsToShow: "Choose which code types to show:",
    userSettings_Stuff: "Stuff",
    userSettings_boxes: "Boxes",
    userSettings_lostFound: "Lost&found",
    userSettings_freezer: "Freezer",
    userSettings_messages: "Message codes",
    userSettings_listChoices: "ListChoices",
    userSettings_enableSMRecievingOnIncommingMessages:
      "Allow reception of text messages (SMS) when somone sends a message to you",
    userSettings_enableSMSRecieveLabel: "Messaging",
    userSettings_whereYouManageSettings: "This is where you manage settings for your QRit.",
    userSettings_theWarningIcon:
      "The triangle/warning icon means that when you add an item to that list the ownership of the QRit entry will be handed over to the owner of the list (if it is not your list).",
    userSettings_chooseToDisableReception:
      "You can choose to disable the reception of SMS messages from other users. Note, however, that it is good to enable it when you have lost something and wait for someone to contact you!",
    userSettings_UserContactInfo: "Contact information",
    userSettings_FeedbackSupport: "Feedback and support",
    userSettings_organizationAccount: "Organization account",
    userSettings_organizationsYouAreAdminFor: "Organisations you are administrator for",

    // AccesChangeOwnerDialog
    accessChangeOwnerDialog_ChangeOwner: "Change list owner",
    accessChangeOwnerDialog_SubText: "Change owner of list",

    // StoreContextualMenu
    storeContextualMenu_listProperties: "List properties",
    storeContextualMenu_moreOptions: "More options",
    storeContextualMenu_listName: "List name",
    storeContextualMenu_listOwner: "List owner",

    // AccessAddUSerDialot
    accessAddUserDialog_addUser: "Add user",
    accessAddUserDialog_subText: "Add user to list",

    // Accesstable
    accessTable_FaildAddUser: "Failed to add user to list",
    accessTable_InviteUser: "Invite user",
    accessTable_ChangeOwner: "Change owner",
    accessTable_changeRole: "Change role",
    accessTable_changeRoleInfo: "The role controls what access a user has to the list.",
    accessTable_roleAdmin: "Role administrator",
    accessTable_roleMaintain: "Maintainer",
    accessTable_roleEditor: "Editor",
    accessTable_rolePending: "Pending",
    accessTable_roleMember: "Member",
    accessTable_setRole: "Set role",
    accessTable_role: "Role",
    accessTable_command: "Edit",
    accessTable_delete: "Delete",
    accessTable_removeUser: "Remove user",
    accessTable_removeUserFromList: "Remove user from list?",

    // Chat messages
    messages_footerMessage: "-- sent from QRit.app\n",
    messages_messageTitle: "Message",
    messages_send: "Send",
    messages_reply: "Reply",
    messages_replyTitle: "Reply",
    messages_replyText: "Reply...",
    messages_delete: "Delete",
    messages_deleteTitle: "Delete Message",
    messages_deleteText: "Are you sure you want to delete this message?",

    //prices: [[12, 9], [24, 18], [36, 27], [48, 36]],
    //pricePhyical: 10,
    currency_prefix: "$",
    currency_suffix: "",
    pricePhysicalDelviery: 10,
    skuList: [
      "00000000-0001-0002-0003-000000000001",
      "00000000-0001-0002-0003-000000000002",
      "00000000-0001-0002-0003-000000000003",
      "00000000-0001-0002-0003-000000000004",
      "00000000-0001-0002-0003-000000000005",
      "00000000-0001-0002-0003-000000000006",
      "00000000-0001-0002-0003-000000000007",
      "00000000-0001-0002-0003-000000000008",
    ],

    freezer_FoodChoiceList: [
      "Meat",
      "Fish",
      "Vegitarian",
      "Soup",
      "Pasta sause",
      "Vegetables",
      "Bread",
      "Ice cream",
      "Berries or fruit",
    ],
    freezer_FoodTypeList: ["Full dish", "Main", "Side", "Raw"],
    freezer_locationChoicesList: ["Kitchen freezer", "Freezer box"],
    freezer_AmountTypeList: ["g", "styck", "kg", "liters", "ml"],

    EditBoxes_ContentTypeList: ["Moving", "Storage"],
    EditBoxes_ContentList: ["Childrens clothes", "Memories"],
    //TODO: Make the content list dependent on content type.
    EditBoxes_SiteList: ["Home", "Summer house"],
    EditBoxes_RoomList: ["Kitchen", "Bedroom", "Attic", " Garage"],
    EditBoxes_CategoryList: ["Uncategorized"],

    // EditBoxes_PriorityList: ['High','Normal','Low'],
    // EditBoxes_BoxStateList: ['Tagged','Packing','Closed','Stored','Unpacked'],
    EditBoxes_DefaultContentType: "Storage",
    EditBoxes_DefaultPriority: "Normal",
    EditBoxes_DefaultFragility: false,
    EditBoxes_DefaultLocation: "Home",
    EditBoxes_DefaultBoxState: "Tagged",
    EditBoxes_BoxStateList2: {
      tagged: "Tagged",
      packing: "Packing",
      closed: "Closed",
      storedHome: "Stored at home",
      storedAway: "Stored (not at home)",
      unpacking: "Unpacking",
      unpacked: "Unpacked",
    },
    EditBoxes_PriorityList2: {
      low: "Low",
      normal: "Normal",
      high: "High",
    },

    EditBoxes_BoxColorChoices: {
      green: "Green",
      blue: "Blue",
      red: "Red",
      black: "Black",
      yellow: "Yellow",
      pink: "Pink",
      brown: "Brown",
    },

    EditStatic_ItemTypesList: ["Books", "Tools"],

    AccessTypes: {
      listAdmin: "Administrator",
      addUser: "Add users",
      editCodes: "Edit codes",
      // "addCode",
      // "deleteCode",
      // "viewCode",
      // "editCode",
      // "viewLimitCode",
      // "anonView",
      // "borrow",
      // "request",
      // "confirm",
      // "invite",
      // "approve",
      listUser: "List users",
      pendingUser: "Pending new user",
    },
  };

  static swedish: IStrings = {
    QRitDefault: true,
    Shureguard: false,

    // Generic
    generic_yes: "Ja",
    generic_no: "Nej",
    generic_description: "Beskrivning",
    generic_edit: "Ändra",
    generic_copy: "Kopiera",
    generic_name: "Namn",
    generic_cancel: "Avbryt",
    generic_save: " Spara",
    generic_share: "Dela",
    generic_properties: "Properties",
    generic_back: "Stäng",
    generic_close: "Stäng",
    generic_PageInfo: "Information",
    generic_noCodes: "Inga koder",
    generic_ListsShow: "Medlem i följande listor",
    generic_QRCode: "QR-kod: ",
    generic_noLists: "Inga listor",
    generic_noTypeSet: "Ingen typ vald",
    generic_phone: "Telefon",
    generic_add: "Lägg till",
    generic_noMessages: "Inga meddelanden",
    generic_user: "Användare",
    generic_manageImages: "Hantera bilder",
    generic_quickAddImage: "Snabbt tillägg",

    // Header
    header_whatIsQRit: "Vad är QRit?",
    header_buyQRcodes: "Köp QR-koder",
    header_scan: "Skanna",
    header_manualQRentry: "Manuell inmatning",
    header_termsAndConditions: "Villkor",
    header_feedback: "BETA-feedback",
    header_itemFound: "Hittat pryl",
    header_account: "Konto",
    header_logout: "Logga ut",
    header_login: "Logga in",
    header_stores: "Listor",
    header_settings: "Inställningar",

    // Home.ts
    welcome: "Välkommen till QRit!",
    environmentFuture:
      "Våga värna om miljön och en hållbar framtid – låna eller hyr ut dina saker till familj, vänner och grannar! Denna app kan, med hjälp av unika QR-koder hjälpa dig att hålla reda på utlånade och uthyrda prylar, eller om du vill, bara hjälpa dig hålla koll på dina prylar.",
    found:
      'Om du skannat en QR-kod på något du hittat och vill hjälpa till att lämna tillbaka detta, tryck på "Upphittat".',
    home_loginRegister: "Logga in / registrera dig",
    home_lostFound: "Upphittat",
    home_WarnMsgYouHaveNewCode: "Du har skannat en ny QR-kod. Registrera dig/logga in för att registrera den.",
    home_WarnMsgYouHaveExistingCode:
      "Du har skannat en existerande QR-kod. Registrera dig/logga in för att registrera den.",
    home_ButtonSignIn: "Logga in",
    home_ButtonItemFound: "Upphittat",
    home_codeDetailsFailedGetInfo: "Misslyckades med att hämta QR-kod info.",
    home_items: "Prylar",
    home_FreezerReuse: "Frysen",
    home_LostFound: "Borttappat",
    home_Freetext: "Meddelande",
    home_Boxes: "Lådor",
    home_Search: "Sök",

    home_iconTableWhatDoTheIconsMean: "Vad betyder ikonerna?",
    home_iconTableHeaderIcon: "Ikon",
    home_iconTableHeaderDescription: "Beskrivning",
    home_iconTableStaticHome: "Denna pryl är just nu hos dig",
    home_iconTableStaticHomeAtOther: "Detta är någon annans pryl som har den hos sig",
    home_iconTableStaticMyBorrowedByOther: "Detta är din pryl som någon annan just nu lånar",
    home_iconTableStaticOtherBorrowedByMe: "Detta är någon annans pryl som du just nu lånar",
    home_iconTableStaticOtherBorrowedByOther: "Detta är någon annans pryl som någon annan lånar",
    home_iconTableBoxOpen:
      'Denna låda är märkt med en kod och är "öppen" (t.ex. redo att packas eller har packats upp)',
    home_iconTableBoxPacking: 'Denna låda har status "packas"',
    home_iconTableBoxClosed: "Denna låda är stängd (möjligen för att den är full efter att ha packats)",
    home_iconTableBoxStorageHome: "Denna låda lagras just nu hos dig",
    home_iconTableBoxStorageAway: "Denna låda lagras just nu någon annastans (inte hos dig)",
    home_iconTableBoxUnpacking: "Denna låda packas just nu upp",
    home_iconTableBoxWhatAreTagTypes: "Vad är de olika kodtyperna?",
    home_tagTypeTableWhatAreTheTagTypes: "Vad är de olika kodtyperna?",
    home_tagTypeTableOtherTextOnly: "Annat",
    home_tagTypeTableCodeTypeName: "Kodtypnamn",
    home_tagTypeTableDescription: "Beskrivning",
    home_tagTypeTableStatic:
      "Kod på en pryl som märkt just för att hålla koll på prylen (t.ex. vid utlåning). Det kan vara ett verktyg, en dator, en bok, eller något helt annat",
    home_tagTypeTableBoxes:
      "En kod för uppmärking av en låda. Typiskt är innehållet beskrivet (i text och/eller foto) för lådan",
    home_tagTypeTableLostFound:
      "En kod på en sak som är uppmärkt just för att det ska vara lättare för någon som hittar den att kunna lämna tillbaka den till ägaren",
    home_tagTypeTableFreezer: "En kod att sätta på någon som ska in i frysen, för att hålla koll på vad som finns där",
    home_tagTypeTableMessage:
      "En kod som är kopplad till en enkel text eller html kod. Den som skannar koden får se texten (och bara texten)",
    home_tagTypeTableOther:
      "Om det inte är en av standardkoderna som kan det vara en en lista som lagts till som en flik (högst upp på sidan). Gå till 'Hantera listor' i menyn för att få veta mer",

    //Item.tsx
    item_shareItemText: "Jag vill dela min pryl med dig.",
    item_status: "Status:",
    item_whoBorrowed: "Vem har lånat prylen?:",
    handleBorrowingItem_whenGiveBack1: "När ska den lämnas tillbaka? om ",
    handleBorrowingItem_whenGiveBack2: "dagar",
    item_photo: "Bild",
    item_atHome: "Hemma",
    item_isPublicViewable: "Kan ses av alla",

    // ItemView
    itemView_CodeDoesNotExist: "Denna QR-kod finns inte eller kan inte skannas korrekt",

    // ListItems.ts
    listItems_state: "Status",
    listItems_loading: "Laddar...",
    listItems_home: "Hemma",
    listItems_giveBack1: "Ska återlämnas om ",
    listItems_giveBack2: "dagar",
    listItems_awayFor1: "Utlånad i ",
    listItems_awayFor2: " dagar till",
    listItems_photo: "Bild",

    // ListHtmlItems
    listHtmlItems_created: "Skapad",
    listHtmlItems_description: "Meddelande",

    // ItemImage
    itemImage_NoImage: "Ingen bild",

    //ListPuchases
    listPurchases_errorMsgUpdatePurchases: "Misslyckades med att läsa köp från databasen.",
    listPurchases_purchaseCompletedColText: "Betalt",
    listPurchases_tagSentColText: "Skickade",
    listPurchases_stateOrActionColText: "Händelse",
    listPurchases_digitalColText: "Pdf",
    listPurchases_createdColText: "Skapad",

    // HandleBorrowedItem.ts
    handleBorrowedItem_reportReturned: "Anmäl som tillbakalämnad",

    // HandleBorrowingItem.ts
    handleBorrowingItem_infoHeader: "Om pryllånevy",
    handleBorrowingItem_Info1: "Du har skannat någon annans kod.",
    handleBorrowingItem_Info2: "Denna sida visar information om prylen, men du kan också välja att låna prylen.",
    handleBorrowingItem_Info3: 'Innan du klicka "Låna", välj hur länge du vill låna den.',
    handleBorrowingItem_Info4: 'När du klickar "Låna" som kommer ägaren att ha information om att du lånat den.',
    handleBorrowingItem_Info5: "Du och ägaren kommer at få ett meddelande när du ska lämna tillbaka den.",
    handleBorrowingItem_wantToBorrowFrom: "Låna pryl?",
    handleBorrowingItem_oneDay: "om en dag",
    handleBorrowingItem_threDays: "om tre dagar",
    handleBorrowingItem_oneWeek: "om en vecka",
    handleBorrowingItem_onemonth: "om en månad",
    handleBorrowingItem_threeMonths: "om tre månader",
    handleBorrowingItem_oneYear: "om ett år",
    handleBorrowingItem_noEndDate: "inget slutdatum",
    handleBorrowingItem_borrow: "Låna",
    handleBorrowingItem_OwnerName: "Ägarens name: ",
    handleBorrowingItem_TypeOfItem: "Typ av pryl",
    handleBorrowingItem_ItemName: "Prylnamn: ",
    handleBorrowingItem_InfoAboutRent: "Följande information om hyra och hyrkostnader",
    handleBorrowingItem_SendMessToOwner: "Skicka meddelande till ägaren",
    handleBorrowingItem_whenReturnItem: "När vill du lämna tillbaka prylen?",
    handleBorrowingItem_Category: "Kategori: ",

    // HandleItemBorrowedBy.ts
    handleItemBorrowedBy_reportItemBack: "Anmäl prylen som återlämnad",
    handleItemBorrowedByMe_YouBorrowedThis: "Du lånar denna pryl",
    handleItemBorrowedByMe_Info1: "Du har skannat en kod på en pryl som du lånat från någon annan.",
    handleItemBorrowedByMe_Info2: "Denna sida innehåller information om den prylen.",
    handleItemBorrowedByMe_Info3:
      "Genom att klicka på returnera så kommer denna att markeras som returnerad hos den du lånder den av. Det kan vara så att ägaren kräver att återlämning ska godkännas av ägaren.",
    handleItemBorrowedByMe_ShouldBeReturnedBy: "Ska återlämnas senast: ",
    handleItemBorrowedByMe_ReturnIn: "Återlämna om",
    handleItemBorrowedByMe_Days: "dagar",
    handleItemBorrowedByMe_RentCost: "Hyrkostnad",
    handleItemBorrowedByMe_infoHeader: "Om pryl som lånats av mig",
    handleItemBorrowedByMe_oneDay: "en dag",
    handleItemBorrowedByMe_threDays: "tre dagar",
    handleItemBorrowedByMe_oneWeek: "en vecka",
    handleItemBorrowedByMe_onemonth: "en månad",
    handleItemBorrowedByMe_threeMonth: "tre månader",
    handleItemBorrowedByMe_oneYear: "ett år",
    handleItemBorrowedByMe_noEndDate: "inget slutdatum",
    handleItemBorrowedByMe_extend: "Förläng",
    handleItemBorrowedByMe_extendDate: "Förläng returdatum",
    handleItemBorrowedByMe_addNumDays: "Lägg till antal dagar",

    //HandleItemBorrowedByOther.ts
    handleItemBorrowedByOther_ReportReturned: "Anmäl prylen som återlämnad",
    handleItemBorrowedByOther_BorrowedItem: "Någon lånar denna pryl",
    handleItemBorrowedByOther_BorrowedBy: "Lånad av:",
    handleItemBorrowedByOther_ShouldBeReturnedBy: "Ska lämnas tillbaka: ",
    handleItemBorrowedByOther_ReturnIn: "Lämna tillbaka om",
    handleItemBorrowedByOther_Days: "dagar",
    handleItemBorrowedByOther_info1: "Do har skannat en kod som något som någon annan lånat från dig.",
    handleItemBorrowedByOther_info2: "Denna sida visar information om den saken",
    handleItemBorrowedByOther_info3:
      "Genom all klocka på Anmäl som återlämnad markeras denna som återlämnad. Gör inte detta om du inte har fått tillbaka prylen.",
    handleItemBorrowedByOther_rentCost: "Hyreskostnad",

    // HandleItemOtherBorrowedByOther.tsx
    handleItemOtherBorrowedByOther_Title: "Någon annans lånad av någon annan",
    handleItemOtherBorrowedByOther_infoHeader: "Om annans pryl lånad av annan",
    handleItemOtherBorrowedByOther_info1: "Du har skannat en kod på en pryl som någon annan lånar från någon annan",
    handleItemOtherBorrowedByOther_info2:
      "Du kan kontakta ägaren för att höra om du kan låna den efter den som nu lånar den",

    // HandleOwnItem.ts
    handleOwnItem_itemHeader: "Om att titta på min pryl",
    handleOwnItem_unregisterItem: "Avregistrera prylen",
    handleOwnItem_TypeOfItem: "Typ av pryl:",
    handleOwnItem_unregister: "Avregistrera",
    handleOwnItem_close: "Stäng",
    handleOwnItem_realyUnregister: "Vill du verkligen avregistrera prylen?",
    handleOwnItem_resetCode: "Utagen från frysen",
    handleOwnItem_QRnumber: "QR nummer",
    handleOwnItem_QRCode: "QR-code",
    handleOwnItem_PageHeader: "Prylinfo",

    // handleOwnHtmlItem.ts
    handleHtmlItem_PageHeader: "Medelande",
    handleHtmlItem_reset: "Avregistrera",
    handleHtmlItem_QRCode: "QR-kod: ",
    handleHtmlItem_TagId: "Kodmärkning: ",
    handleOwnHtml_infoHeader: "Om att titta på eget meddelande",
    handleOwnHtml_TextOrHTML: "Meddelande (text eller HTML)",
    handleOwnHtml_info1: "Du har skannat en av dina egna koder med ett meddelande",
    handleOwnHtml_info2: "Kodmärkning är det namn som som kommer att synas i listan av dina meddelanden",
    handleOwnHtml_info3:
      '"Meddelande (text eller HTML)" är den text som andra som skannar koden kommer att se, utöver de bilder du lagt till',
    handleOwnHtml_info4:
      "Om du har valt att skriva bara en text så kommer den so skannar koden att se exact det du skrev, och ingenting annat (ingen formatering etc.)",
    handleOwnHtml_info5:
      "Om du har valt att skriva HTML så kommer personen som skannar den att se den formaterade HTML-texten",
    handleOwnHtml_info6: "Om du vill ändra text/HTML, klicka på Ändra",

    // HandleOwnListFound
    handleOwnLostFound_LostFound: "Om borttappat",
    handleOwnLostFound_ShowFindersFee: "Visa hittelön: ",
    handleOwnLostFound_FindersFee: "Hittelön: ",
    handleOwnLostFound_ShowYourPhoneNum: "Visa ditt telefonnummer: ",
    handleOwnLostFound_MessageSendingEnabled: "Möjligt att skicka meddelande till ägaren: ",
    handleOwnLostFound_ResetCode: "Avregistrera kod",
    handleOwnLostFound_FinderCanSendGPS: "Upphittare kan skicka GPS-position",
    handleOwnLostFound_infoOnLostFound: "Info om egen Borttappad",
    handleOwnLostFound_thisIsLostFoundCode: "Detta är en Borttappat-kod.",
    handleOwnLostFound_whenYouMarkSometingWithTag:
      "När du markerar något med denna kod så kan vem som än hittar den hjälpa dig att få tillbaka den.",
    handleOwnLostFound_whenYouEditThisTag:
      "När du ändrar informationen för denna kod kan du välja att ändra alla val du kan se på denna sida och möjligen ännu mer.",

    // HandleOtehrsLostFound
    handleOtherLostFound_HowToReturnItem: "Hur lämna tillbaka saken?",
    handleOtherLostFound_YouHaveScannedCode:
      "Du har skannat en kod på en sak som ägaren väldigt gärna till ha tillbaka. Denna sida beskriver hur du kan lämna tillbaka den till ägaren. Ägaren skulle bli jätteglad om du kan hjälpa till!",
    handleOtherLostFound_FindersFee: "Hittelön",
    handleOtherLostFound_ThereIsAFindersFee: "Du får hittelön om du lämnar tillbaka den! Du kommer att få ",
    handleOtherLostFound_OwnersPhoneNum: "Ägarens telefonnummer ",
    handleOtherLostFound_PhoneNum: "Telefonnummer",
    handleOtherLostFound_PleaseCallOrSend:
      ". Ring eller skicka ett meddelande för att komma i kontakt med ägaren så att saken kan lämnas tillbaka.",
    handleOtherLostFound_SendMessToOwner: "Skicka meddelande till ägaren",
    handleOtherLostFound_YouCanChooseSendMess:
      "Du kan här välja att skicka ett anonymt meddelande till ägaren via denna app. I meddelandet kan du beskriva vad hen kan hämta saken eller kommunicera för att stämma möte.",
    handleOtherLostFound_SendMess: "Skicka meddelande",
    handleOtherLostFound_infoHeader: "Om upphittat",
    handleOtherLostFound_info1: "Du har hittat något som någon annan har tappat bort",
    handleOtherLostFound_info2:
      "Denna app kan hjälpa did att lämna tillbaka saken till ägaren, som kommer att bli jätteglad!",
    handleOtherLostFound_info3: "Om du lämnar tillbaka den så kommer du att få hittelön!",
    handleOtherLostFound_info4: "Du kan ringa eller skicka ett SMS till ägaren via telefonummret som visas på sidan",
    handleOtherLostFound_info5:
      "Du kan skicka ett meddelande till ägaren anonymt. Där kan du skriva var ägaren kan hämta upp pryle (t.ex. om du lägger den någonstans eller lämnar in den någonstans)",
    handleOtherLostFound_info6:
      "Du kan markera platsen där du hittade/lämnar prylen så att ägaren kan komma dit och hämta den (du skickar då GPS-positionen till ägaren)",
    handleOtherLostFound_SendGPSPosToOwnerHeader: "Skicka GPS-position till ägaren",
    handleOtherLostFound_SendGPSPositionToOwnerInfo:
      "Du kan skcka din GPS-position till ägaren så att hen kan ta sig dit för att hämta saken.",
    handleOtherLostFound_SendGPSPosToOwnerButton: "Skicka GPS-positon",
    handleOtherLostFound_SendingGPSPosToOwner: "Skickar GPS-position till ägaren",
    handleOtherLostFound_GeolocationIsNotSupported: "Geolocation is not supported by this browser.",
    handleOtherLostFound_HasSentGPSPosToOwner: "GPS-position skickad!",
    handleOtherLostFound_GpsLinkMessage: "Någon har skickat den här länken till GPS-position för denna pryl: ",

    // HandleOthersFreezer
    handleOthersFreezer_infoHeader: "Om se annans frysinfo",
    handleOthersFreezer_info1: "Do har skannat någon annans mat",
    handleOthersFreezer_info2: "Denna sida visar information om denna mat.",
    handleOthersFreezer_info3: "Om du har rätt att ändra iniformaitonen så kommer du att kunna klicka på 'Ändra'",

    handleOthersFreezer_PageHeader: "Någon annans mat",
    handleOthersFreezer_Owner: "Ägare:",
    handleOthersFreezer_ContentType: "Typ av mat:",
    handleOthersFreezer_FoodType: "Innehåll:",
    handleOthersFreezer_FreezDate: "infrysningsdatum:",
    handleOthersFreezer_ExparationDate: "Utgångsdatum:",
    handleOthersFreezer_ForHowMany: "För hur många personer:",
    handleOthersFreezer_HowMuch: "Hur mycket:",
    handleOthersFreezer_Location: "Var:",

    //handleOthersBoxes
    handleOthersBoxes_PageHeader: "Någon annans låda",
    handleOthersBoxes_Info1: "Du har skannat någon annans låda.",
    handleOthersBoxes_Info2: "Denna sida visar information om lådan och dess innehåll.",
    handleOthersBoxes_Info3:
      'Om du har rätt att ändra information så kan du även klicka på "Ändra" för att göra detta.',
    handleOthersBoxes: "Om annans låda",

    // HandleOwnBoxes
    handleOwnBoxes_infoHeader: "Om titta på lådinfo om min låda",
    handleOwnBoxes_PageHeader: "Min låda",
    handleOwnBoxes_PageHeaderEdit: "Ändra",
    handleBoxes_Owner: "Ägare: ",
    handleBoxes_ShortID: "Lådmärkning: ",
    handleBoxes_ContentType: "Typ av innehåll: ",
    handleBoxes_Content: "Innehåll: ",
    handleBoxes_Description: "Detaljer",
    handleBoxes_Location: "Plats: ",
    handleBoxes_Room: "Rum: ",
    handleBoxes_Category: "Kategory: ",
    handleBoxes_Fragility: "Ömtåligt",
    handleBoxes_Priority: "Prioritet: ",
    handleBoxes_QRcode: "QR-kod",
    handleBoxes_BoxColor: "Färgmarkering: ",
    handleBoxes_BoxState: "Lådstatus: ",
    handleBoxes_StorageDate: "Senast ändrad: ",
    handleBoxes_ShortId: "Id:",
    handleBoxes_0: "Här hittar du detaljerad information om lådan och dess innehåll.",
    handleBoxes_1:
      "Varje klistermärka (låda) har ett unikt ID på 2-3 bokstäver som du använder för att identifiera de individuella lådorna.",
    handleBoxes_2:
      "Boxstatus informerar dig om om lådan packas, om den till exempel är fylld/stängd, om det är lagrad eller håller på att packas upp.",
    handleBoxes_3: "Kategori är en övergripande beskrivning av innehållet i lådan.",
    handleBoxes_4: "Beskrivning är vad du kan hitta den detaljerade informationen om lådan och dess innehåll.",
    handleBoxes_5: "Plats är var lådan är (till exempel address) TODO: FIXA NAMNEN.",
    handleBoxes_6: "Rum/plats talar om var i platsen lådan finns.",
    handleBoxes_7: "Om Ömtåligt är iklickat är innehållet ömtåligt.",
    handleBoxes_8:
      "Prioritet är för att du ska veta om en låda ska flyttas tidig, om den kan flyttas med övriga, eller om det inte är någon brådska alls.",
    handleBoxes_9: "Senaste ändringsdatum är det datum som denna lådas innehåll uppdaterades senast.",
    handleBoxes_10: "QR-kod är det unika ID som hör till just denna kod",

    // EditBoxes
    EditBoxes_ContentTypeHeader: "Typ av innehåll",
    EditBoxes_ContentHeader: "Innehåll",
    EditBoxes_BoxName: "Lådnamn",
    EditBoxes_Location: "Plats",
    EditBoxes_Room: "Rum/placering",
    EditBoxes_QRID: "Märknings-ID",
    EditBoxes_BoxID: "Låd-ID",
    EditBoxes_QRcode: "QR-kod",
    EditBoxes_LocationHeader: "Plats",
    EditBoxes_RoomHeader: "Rum/placering",
    EditBoxes_CategoryHeader: "Kategori",
    EditBoxes_BoxStateHeader: "Lådstatus",
    EditBoxes_BoxPriorityHeader: "Prioritet",
    EditBoxes_BoxColorHeader: "Lådfärg",
    EditBoxes_InfoHeader: "Info om ändra lådinfo",

    // EditBoxInfo
    EditBoxInfo_BoxInfoHeader: "Hur fungerar QR-märking av lådor?",
    EditBoxInfo_PlaceQR: "Klistra på en QR-kod på din låda.",
    EditBoxInfo_ScanQR: "Skanna QR-koden och registrera/logga in, om det behövs.",
    EditBoxInfo_Packing:
      "Nu kan du börja packa och kanske sätta lådstatus till Packas, för att hålla koll på dina lådor.",
    EditBoxInfo_AddingCatDetails:
      "Medan du packar kan du lägga till en eller flera Kategorier eller skriva innehållet under Detaljer.",
    EditBoxInfo_BackLater:
      "Om du kommer tillbaka senare så skanna bara QR-koden igen och fortsätt packa och uppdatera innehållet i appen.",
    EditBoxInfo_FinishedPacking:
      "När en låda är färdig så kan du sätta Lådstatus till Stängd.  Om du vill att den ska flyttas till ett speciellt rum så kan du uppdatera Rum/Plats för lådan.",
    EditBoxInfo_AddingPrioFrag: "Du kan i appen sätta prioritet och hur ömtåligt innehållet är också",
    EditBoxInfo_PutInStorage:
      "Om du lagrar lådan någonstans så kan du ändra Lådstatus till Lagrad. Det kan också då vara bra att ändra",

    // HandleFreezer.ts
    handleFreezer_freezing_date: "Frysdatum",
    handleFreezer_persons: "Personer",
    handleFreezer_noDateSet: "Inget datum valt",

    // HomePageText.ts
    homePageText_howWorks: "Hur funkar det?",
    homePageText_prices: "Priser",
    homePageText_toThinkAbout: "Att tänka på",
    homePageText_tagsFromUs: "Beställ klisterlappar från oss - digitalt eller skickade till dig.",
    homePageText_tagOntoTool: "Klistra på en unik QR-kod på t.ex. ett verktyg.",
    homePageText_scanWithCell:
      "Skanna QR-koden med din mobil - om det är första gången du använder appen, registrera dig.",
    homePageText_addDescript: "Lägg till en beskrivning av prylen och kanske lägg till ett foto.",
    homePageText_ifSomoneBorrow:
      "Om någon vill låna eller hyra prylen är det bara för dem att skanna QR-koden och registrera uthyrningens med sin smartphone.",
    homePageText_weKeepTrack:
      "Vi håller koll på utlån/uthyrningar och ger påminnelser när det är dags att lämna tillbaka.",
    homePageText_qritIsServiceSubscr: "QRit.app är än tjänst där du abonnerar på QR-kod användning per år.",
    homePageText_subscript8kr: " etiketter kostar ",
    homePageText_subscript8krEnd: "/etikett och år",
    homePageText_12TagsCost: " etiketter kostar ",
    homePageText_12TagsCostEnd: "/år",
    homePageText_24TagsCost: " etiketter kostar ",
    homePageText_24TagsCostEnd: "/år",
    homePageText_buyPhysicalTags:
      "Vill du köpa klisteretiketter färdiga för egen utskrift i vårt format, beställ Avery L4778 - till exempel här: ",
    homePageText_alsoJusgtPrintAndTejp:
      "Du kan också bara skriva ut etiketterna på vanligt papper, klippa ut dem och sedan använda tejp.",
    homePageText_attTankaPa: "Att tänka på",
    homePageText_ifNotPrintWeSend: "Vill du inte skriva ut etiketterna själv kan du beställa dem färdiga via brev för ",
    homePageText_ifNotPrintWeSendEnd: " (upp till 48 etiketter i en sändning) – abonnemangskostnad tillkommer.",
    homePageText_tagsMustPlanar: "Klisterlapparna måste fästas på en (ganska) plan yta",
    homePageText_size2x4: "Lapparna finns i dagsläget i storleken 2x4 cm",
    homePageText_partOfTagsWhite:
      "En del av etiketterna är vita – du kan använda detta att skriva på, eller bara klippa bort det.",
    homePageText_ifPDF:
      "Väljer du att ladda hem en PDF-fil med QR-koder så kan du skriva ut dem själv direkt – etiketterna finns i standardformat, men du kan också bara skriva ut och använda tejp.",
    homePageText_priceTablePrice: "Pris",
    homePageText_priceTableCurrency: "Valuta",

    // HomePage
    homePage_infoHeader: "Om hemvy",
    homePage_infoGeneralHelp: "Generell hjälp",
    homePage_info0: "Detta är hemmasidan i QRit-appem",
    homePage_info1: "Du kan klicka på en rad (eller ruta) för att se detaljerna om den",
    homePage_info2: "eller så kan du välja att byta mellan olika typer av koder genom att klicka på flikarna",
    homePage_info3: 'Du kan också välja att byta mellan flikar genom att "swajpa" höger eller vänster',
    homePage_info4: "Om du vill söka efter en vis sak, skriv in söktext i sökfältet",
    homePage_info5:
      "Du kan byta mellan att se sakerna som en lista eller som ett rutor. Rutor funkar bästa om alla sakerna har bilder",
    homePage_info6:
      "Du kan lägga till mer typer av koder eller ändra vilka koder som ska visas i flikarna genom att gå in i Inställningar i menyn upp till höger",
    homePage_info7: "Du kan klicka på en rubrik (i listvyn) för att sortera på den",
    homePage_info8: "Om du vill köpa coder on-line så gör du det via menyn upp till höger",
    homePage_grid: "Rutnät",
    homePage_list: "Lista",

    // TagTypeOverview
    tagTypeOverview_pageHeader: "QRit - Bryggan mellan det fysiska och det digitala",
    tagTypeOverview_intro:
      "QRit är en uppsättning tjänster som hjälper dig hålla koll på saker privat i vardagen, eller på jobbet:",
    tagTypeOverview_RegisterLogin: "Registrering/inloggning",
    tagTypeOverview_RegisterLoginInfo:
      "För att kunna använda tjänster så behöver du registrera dig (eller om du är registrerad, logga in)",
    tagTypeOverview_lblHeaderQRitStorage: "QRit Lagring",
    tagTypeOverview_QRitStorage1:
      "Glöm inte var du har dina saker! Med QRit Lagring håller du koll på i vilken låda på vinden, i förrådet, garaget, lägenheten eller huset du har lagt dina saker, och var den står. Genom att märka en låda med en QRit-kod och skanna den kan du lägga in information om vad som finns i en låda och var den finns. ",
    tagTypeOverview_QRitStorage2:
      "Det går även att lägga till en bild, t.ex. med innehållet. QRit Lagring är ett måste för flytten! Med QRit.app can du sedan söka efter innehåll och hitta vilken låda en viss pryl är i, och var den står.",
    tagTypeOverview_QRitStorage3:
      "Köp ett QRit Lagrings-kit där du handlar dina flyttlådor (hitta närmaste här). QRit Lagring är en prenumerationstjänst.",
    tagTypeOverview_lblHeaderQRitKeys: "QRit Nycklar",
    tagTypeOverview_QRitKeys1:
      "Gör det enklare att få tillbaka nycklarna när du tappat bort dem! Sätt en QRit Nycklar-bricka på din nyckelring så att när du tappar bort den så kan den som hittar nyckarna skanna koden och kontakta (chatta med) dig anonymt.",
    tagTypeOverview_QRitKeys2:
      "Köp ett QRit Nycklar-kit i butik (hitta närmaste här). QRit Nycklar är en prenumerationstjänst.",
    tagTypeOverview_lblHeaderQRitStatic: "QRit Prylar",
    tagTypeOverview_QRitStatic1:
      "Låt dina grannar, vänner och familj lätt se vad du har och låna dina dina verktyg, böcker eller annat, samtidigt som då håller koll på dina utlånade prylar. Du kan även göra en lista av pryla publik och hyra ut dem. Börja med att märka upp dina prylar med QRit-koder. Skanna dem sedan och lägg in information om prylen och kanske en bild.",
    tagTypeOverview_QRitStatic2:
      "Du kan skriva hur mycket du tänker dig att det ska kosta att hyra/låna prylen. När någon annan skannar QRit-koden kan de be att få låna den och skriva hur länge de vill låna den. När återlämningsdatum närmar sig får du och den som lånar en pryl ett meddelande.",
    tagTypeOverview_QRitStatic3:
      "Köp ett QRit Prylar-kit i butik (hitta närmaste här). QRit Prylar är en prenumerationstjänst.", //, eller köp QR-koder direkt från oss här (antingen digitala eller fysiska)
    tagTypeOverview_lblHeaderQRitLostFound: "QRit Borttappat",
    tagTypeOverview_QRitLostFound1:
      "Öka möjlighen att få tillbaka borttappade saker! Med QRit Borttappat märker du upp dina prylar med QR-koder så att när du tappar dem så kan andra skanna koden och göra så att du får tillbaka den. Upphittaren kan kommunicera med dig anonymt och till och med markera en (GPS) position där prylen finns, för att underlätta.",
    tagTypeOverview_QRitLostFound2:
      "Köp ett QRit Borttappat-kit i butik (hitta närmaste här). QRit Borttappat är en prenumerationstjänst.", //, eller köp QR-koder direkt från oss här (antingen digitala eller fysiska)
    tagTypeOverview_lblHeaderQRitFreezer: "QRit Frysen",
    tagTypeOverview_QRitFreezer1:
      "Trött på att hålla koll på vad som finns i frysen? Med QRit Frysen kan du märka upp din mat, lista vilken mat du har i frysen och och få notifieringar när något börjar bli gammalt. Märk upp. Skanna. Lägg in information som vilken typ av mat, hur mycket, och vad som är bäst-före datum.",
    tagTypeOverview_QRitFreezer2:
      'När du tagit ut något ur frysen kan du enkelt skanna koden och välja "Uttagen ur frysen". QRit Frysen koder tål maskindisk om klistrad på t.ex. en plastlåda',
    tagTypeOverview_QRitFreezer3:
      "Köp ett QRit Frysen-kit i butik (hitta närmaste här). QRit Frysen är en prenumerationstjänst.", //, eller köp QR-koder direkt från oss här (antingen digitala eller fysiska)
    tagTypeOverview_lblHeaderQRitHtml: "QRit Meddelanden",
    tagTypeOverview_QRitHtml1:
      "En QRit Medelanden-kod kan innehålla en webbsida med information, eller bara lite text eller en bild.",
    tagTypeOverview_QRitHtml2:
      "Köp ett QRit Medelanden-kit i butik (hitta närmaste här). QRit Frysen är en prenumerationstjänst.", //, eller köp QR-koder direkt från oss här (antingen digitala eller fysiska)
    tagTypeOverview_business: "Företag",
    tagTypeOverview_private: "Privat",

    tagTypeOverview_pageHeader_BB: "Bron mellan det digitala och fysiska!",
    tagTypeOverview_intro_BB:
      "QRit är en uppsättning tjänster som genom användandet av unika QR-koder hjälper dig att hålla koll på saker i ditt företag. Nedan kan du se vilka tjänster som finns.",
    tagTypeOverview_RegisterLoginInfo_BB:
      "För att kunna använda QRits tjänser behöver du registrera dig (om du är registrerad, logga in). För att skapa ett organisationskonto, logga in med ditt telefonnummer och gå till Inställningar. Om du är administratör för en organisation så kommer du att få välja om du vill logga in som dig själv eller som organisationen när du fyllt i verifieringskoden.",
    tagTypeOverview_lblHeaderQRitStorage_BB: "QRit Lagring",
    tagTypeOverview_lblHeaderQRitKeys_BB: "QRit Anonymt",
    tagTypeOverview_lblHeaderQRitStatic_BB: "QRit Inventarier",
    tagTypeOverview_lblHeaderQRitLostFound_BB: "QRit Borttappat",
    tagTypeOverview_lblHeaderQRitFreezer_BB: "QRit Restaurang",
    // TODO: Change name from freezer
    tagTypeOverview_lblHeaderQRitHtml_BB: "QRit Meddelandekoder",

    tagTypeOverview_QRitStorage1_BB:
      "Glöm inte var du har dina lådor och vad som är i dem! Med QRit Lådor kan du hålla koll på vad du har var. Sätt än kod på lådorna på kontoret, på lagret eller i garaget. När du skannat en QR-kod första gången så kan du lägga upp information om var den är, vad som är i, och kanske också ta ett kort på vad som är i lådan.",
    tagTypeOverview_QRitStorage2_BB:
      "Du kan också lägga till bilder. Till exempel på innehållet i en låda eller var den lagras. Genom QRit kan du och dina kollegor leta efter innehåll och titta på pilder för att hitta vad som vinns var.",
    tagTypeOverview_QRitOrderCodesHere_BB: "",
    tagTypeOverview_QRitKeys1_BB:
      "Gör det lättare att få tillbaka nycklar, nyckelkort, eller andra känsliga saker på ett säkert sätt. sätt en QRit Anonymt kod på dina nycklar eller ditt RFid-kort, och när någon skannar någon av dem så kan de kommunicera med dig anonymt. Du kan också lägga till hittelön så att upphittaren har mer incitament att lämna tillbaka det. Du kan också lägga till information om det du märkt med en anynym kod. Information som bara behöriga personer inom din organisation kan se. Detta kan vara vad en nyckel hör hemma, ett lösenord (var dock försiktig med lösenord) eller innehållet (t.ex. om ett USB-stick eller en hårddisk märks upp).",
    tagTypeOverview_QRitKeys2_BB: "",
    // tagTypeOverview_lblHeaderQRitStatic_BB: "QRit ItemTracking",
    tagTypeOverview_QRitStatic1_BB:
      "Håll koll på utrusting och inventarier i din organisation. Märk upp verktyg, böcker, datorer coh andra saker i med QRit Inventatier-koder, lägg till information om dem, och kanske ta ett kort på dem. Den som skannar koden får information om saker. Till exempel, om någon är på företagets lista (t.ex. anställda) skannar en sådan kod kan han eller hon få information om var saken finns just nu eller vem som har den. Om någon extern skannar den går det att sätta upp det på olika sätt, så som att låta den som skannar kommunicera med dig, eller så går det genom dessa koder att ha koll på vem som lånar vad. Det går då också att sätta låne-(eller hyr)-tid, och skicka meddelanden när lånetiden är på väg att gå ut.",
    tagTypeOverview_QRitStatic2_BB: "TBD",
    tagTypeOverview_QRitStatic_BB: "",
    tagTypeOverview_QRitLostFound1_BB:
      "Öka sannolikheten att få tillbaka saker som du tappar bort. Med QRit Borttappat kan du märka upp saker så att när du tappar bort dem så får andra som skannar koden möjligheten att skicka meddelanden till dig och även skicka GPS position. Detta liknar QRit Nycklar, men är klisterlappar med koder istället.",
    tagTypeOverview_QRitLostFound2_BB: "",
    // tagTypeOverview_lblHeaderQRitHtml_BB: "QRit Information",
    tagTypeOverview_QRitHtml1_BB:
      "En QRit Informaiton kan inehålla en länk till en hemsida, HTML-kod, eller bara enkel text. Du kan ändra texten eller länken änär som helst. Det du skriver kan vara direkt information om en maskin eller en process, eller en länk till ett document eller hemsida med denna informaiton. Man kan även använda det som dynamisk prismärkning (detta kräver troligen integrering mot er backend med prisdata).",
    tagTypeOverview_lblHeaderQRitRestaurant_BB: "QRit Restaurang (framtida tjänst/feature)",
    tagTypeOverview_QRitRestaurant1_BB:
      "En QRit Restaurang-kod är vägen till att hantera återvinning/pant av till exempel take-away-lådor - något som blir krav i EU 2024 (http://publications.europa.eu/resource/cellar/7ec2bc0d-e5b2-4c09-bf6f-dce483160b6d.0015.03/DOC_2)",
    tagTypeOverview_QRitRestaurant2_BB:
      "Det kan till exempel göras genom att restaurangen sätter på en QRit Restaurant-kod på en hårdplastlåda som kunden som tar emot lådan skannar. Då har ni information om vem som har vilken låda. När lådan återlämnas skannar restaurangen den, för att sedan diska och återanvända till nästa kund. Dvs ett pantsystem för take-away-lådor. ",

    // Privacy.ts
    privacy_deliveryTermsHeader: "Leveransvillkor",
    privacy_deliveryTerms:
      "QR-koder som beställs som digital fil (pdf) skickas normalt inom 2 arbetsdagar. QR-koder som beställs färdigtryckta skickas normalt som ekonomibrev inom 4 dagar. Avvikelser kan förekomma. För förtryckta etiketter, om adressen är felaktig så att försändelsen inte kommer fram förbehåller vi oss rätten att ta ut full utskickningsavgift för en andra försändelse. Om angiven emailadress är felaktig, skicka oss den korrekta via email (best@qrit.app) så uppdaterar vi.",
    privacy_deliveryTermsMissDelivery:
      "Om vi inte kan leverera inom avtalad tid har vi skyldighet att informera om detta och upplysa er om ny tidpunkt. Om vi inte kan leverera inom rimlig tid så har du rätt att häva köpet via email (best@qrit.app – ange registrerat telefonnummer och beställningsdatum). Om du ångrar ditt köp betalas det belopp som du betalat tillbaka till dig, inklusive eventuella leveranskostnader. QRit betalar snarast tillbaka beloppet, dock senast inom 14 dagar från och med det datum ditt meddelande om utövande av ångerrätten mottogs. Återbetalningen kommer att ske till dig via det betalningsalternativ som du valde.",
    privacy_personalInfoHeader: "Personuppgifter",
    privacy_personalInfoUseInfo:
      "Personuppgifter som lämnas till oss i samband med att du använder QRit.app används för att kunna fullgöra åtaganden (tjänsten) gentemot dig och i övrigt ge dig god service. Härvid avses att komma i kontakt med dig om det uppstår problem vid leverans, samt, vid postförsändelse, kunna leverera QR-koderna till dig. QRit kommer, i egenskap av personuppgiftsansvarig, inte att lämna ut dina uppgifter till tredje part för marknadsändamål. Uppgifter kan däremot komma att vidarebefordras till tredje part uteslutande i syfte att QRit.app ska kunna uppfylla sina åtaganden gentemot dig. Vi kan, till exempel, komma att skriva ut både etiketter och adresslappar hos tredje part. Du kan när som helst återkalla ditt medgivande till att QRit.app använder dina uppgifter i marknadsföringssyfte (t.ex. nyhetsbrev eller erbjudanden). Du kan kontakta oss per e-post till info@qrit.app.",
    privacy_cookies: "Cookies",
    privacy_cookiesInfo:
      "QRit.app använder cookies på sin webbplats för att se till att vi kan utföra de tjänster som du förväntar dig när du besöker webbplatsen. När du i framtiden besöker vår webbplats eller när du öppnar ett e-postmeddelande från oss kan QRit.app få tillgång till cookies som finns lagrade på din dator. Om du vill radera cookies som redan finns på din dator ber vi dig gå igenom instruktionerna i din webbläsare genom att klicka på Hjälp i webbläsarmenyn. Om du raderar våra cookies eller inte tillåter framtida cookies så kan detta resultera i att du inte får tillgång till eller inte kan använda vissa områden eller funktioner på vår webbplats. QRitapp kommer också att spara din användarinformation på din telefon, för att möjliggöra säker hantering av skanning av koder med mera. Sådan registrering sker så fort användarinformation registreras, och för samtliga tjänster.",
    privacy_infoStorageHeader: "Lagring av information och säkerhet",
    privacy_infoStorageInfoSSL:
      "Vår sajt www.QRit.app använder HTTPS-protokollet med SSL-certifikat för att skydda information.",
    privacy_infoStorageInfoEncrypt:
      "QRit.app skyddar även data, telefonnummer och address, från obehöriga, med tekniker såsom dynamic data masking och transparent data encryption.",
    privacy_personalInfoPurchaseHeading: "Personuppgifter vid betalning",
    privacy_personalInfoPurchaseInfo:
      "Vi använder Klarna (www.klarna.se) för betalningar med kort. Se https://www.klarna.com/se/dataskydd/. Klarna uppfyller kraven för PCI DSS",
    privacy_pricesHeader: "Priser",
    privacy_pricesInfo:
      "Vid beställning via webbtjänsten/appen gäller de priser som anges på webbplatsen. Priser anges i svenska kronor och är inklusive moms. Abonnemangspriserna inkluderar inte eventuella            betalningavgifter, som anges separat. Dessutom, om du väljer att köra färdigtryckta etiketter betalar du en hanteringskostnad för utskrift och leverans, som inte är inkulderat i abonnemangspriserna. Du kan betala på sätt som anges på webbplatsen. QRit.app förbehåller sig rätten att inte alltid erbjuda alla betalsätt samt att ändra betalsätt om det som du valt av någon anledning inte fungerar vid tillfället för köpet eller du inte godkänns för det valda betalsättet. Bekräftelse på din betalning samt QR-koder kommer skickas via epost till din angivna epost-adress, alternativs via ekonomipost.",
    privacy_giveBackHeader: "Reklamation",
    privacy_giveInfo: "Vid reklamation Kontakta oss på order@QRit.app, så berättar vi hur du ska gå vidare.",
    privacy_returningHeader: "Ångerrätt",
    privacy_returningInfo:
      "Konsumenter har normalt 14 dagars ångerrätt men digitalt innehåll innefattas inte i detta. Genom att du accepterar dessa villkor samtycker du till att ångerrätten inte gäller för digitalt innehåll som levereras på annat sätt än på ett fysiskt medium. 14 dagars ångerrätt gäller dock för QR-koder som beställs via ekonomipost (fysisk leverans).",
    privacy_exchangeHeader: "Bytesrätt",
    privacy_exchangeInfo: "Det går ej att byta tjänster.",
    privacy_payBackHeader: "Återbetalning",
    privacy_payBackInfo:
      "Vi vill uppmärksamma att det i vissa fall kan ta någon bankdag från det att QRit.app gjort överföringen, innan pengarna kommer in på ditt konto. Återbetalning sker med samma betalningssätt som vid köpet.",
    privacy_payBackInfoDelivery:
      "Återbetalning fraktkostnader vid reklamation - Om företaget företar omleverans eller avhjälpande av felet vid reklamation ska eventuella fraktkostnader återbetalas inom 14 dagar från den dag företaget mottagit den reklamerade varan. Retur av vara samt återbetalning ska ske utan onödigt dröjsmål. Konsumenten skall ersättas för eventuella fraktkostnader samt andra kostnader som uppkommit i samband med reklamationen, tex bensinkostnader, parkeringskostnader, telefonkostnader och liknande.",
    privacy_goodToHaveLinksHeader: "Nyttiga länkar",
    privacy_goodToHaveLinksConsumerService: "Ångerblankett från Konsumentverket ",
    privacy_goodToHaveLinksConsumerARN: "ARN (ARN) Postadress: Box 174, 101 23 Stockholm ",
    privacy_goodToHaveLinksArbitration: "Tvistelösning på Internet (Tvist)",
    privacy_complaintsHeader: "Klagomål",
    privacy_complaintsInfo: "Klagomål kan framföras till info@QRit.app eller med brev till: [fyll i]]",

    // Purchasing.tx
    purchasing_payment: "Betalning",
    purchasing_ifPurchaseDigital:
      "Om du beställt QR-koder digitalt (pdf-fil), kontrollera att emailaddressen är korrekt.",
    purchasing_ifPurchasePhysical: "Om du beställt färdiga klisterlappar, kontrollera att addressen är korrekt.",
    purchasing_order: "Order",
    puchasing_youLoggedInLoginNow: "Du måste vara inloggad för att köpa. Logga in innan du går vidare.",
    purchasing_payPaypal: "Betala med Paypal",
    purchasing_paySwish: "Betala med Swish",
    purchasing_payKlarna: "Betala med Klarna",
    purchasing_paySwishInstructions: "Betala med Swish-appen genom att skanna QR-koden.",
    purchasing_fullName: "För och efternamn",
    purchasing_firstName: "Förnamn",
    purchasing_lastName: "Efternamn",
    purchasing_address: "Adress",
    purchasing_zipCode: "Postkod",
    purchasing_city: "Stad",
    purchasing_country: "Land",
    purchasing_emailPlaceholder: "Skriv email adress här",
    purchasing_email: "Epost",
    purchasing_phone: "Telefon",
    purchasing_username: "Användarnamn",

    // RegisterItem.ts
    registerItem_itemRegistration: "Registrera pryl",
    registerItem_freezerItemRegistration: "Registera infrysning",
    registerItem_boxItemRegistration: "Registera låda",
    registerItem_lostFoundItemRegistration: "Registera borttappat",
    registerItem_messageTagItemRegistration: "Registera meddelande",
    registerItem_messageHtml: "Fritext eller  HTML",
    registerItem_messageName: "Kort namn på meddelande",
    registerItem_alwaysConfirmReturn: "Jag vill alltid konfirmera återlämnande:",
    registerItem_registrerMustHaveBankID: "Den som lånar måste vara registrerad med BankID ([framtida funkt.])",
    registerItem_putItemForRent: "Jag vill lägga ut denna prylen för uthyrning",
    registerItem_rentInfo: "Info om hyra/kostnad",
    registerItem_lostFound: 'Jag vill registrera denna för "Borttappat"',
    registerItem_lostFoundInfo:
      "Om denna pryl tappas bort kommer denna inställning att göra det lättare att få tillbaka den.",
    registerItem_configRent: "Inställningar hyra",
    registerItem_configLostFound: "Inställningar borttappat",
    registerItem_itemRegister: "Registrera",
    registerItem_failedRegisterCode: "Misslyckades med att registrera koden. Försök igen senare!",

    // EditHtml
    editHtml_PageHeaderEdit: "Ändra",
    editItem_PageHeaderEdit: "Ändra",
    editHtml_Instructions: "Instruktioner",
    editHtml_infoHeader: "Om meddelandeändring",
    editHtml_Info1: "Här skriver du in det meddelande du vill att personen som skannar koden ska se.",
    editHtml_Info2: "Meddelandenamnet är det som du kommer att se när du listar meddelandet.",
    editHtml_Info3: "Du kan sedan skriva in meddelandet.:",
    editHtml_Info4: "Om du vänjer att skriva in text så kommer den som skannar koden att se texten precis som den är.",
    editHtml_Info5: "Om du väljer att använda HTML som kommer den som skannar koden att se formaterad HTML.",

    // titleWithInfo
    titleWithInfo: "Information",
    titleWithInfo_reportMisuse: "Ge återkoppling/rapportera missbruk",
    titleWithInfo_reportMisuseDescript:
      "Här kan du också ge oss återkoppling om problem med QRit, eller bara ge oss generell återkoppling om tjänsterna. Här kan du också rapportera missbruk av våra tjänster, som när någon lägger ut bilder eller annat innehåll som är stötande eller på annat sätt är olämpligt eller bryter mot våra regler. Vi kommer att granska ditt meddelande och återkommer till dig så snart vi kan.",
    titleWithInfo_reportMisuseSent: "Tack för din återkoppling!",
    titleWithInfo_reportMisuseError: "Något gick fel när vi försökte skicka din återkoppling. Försök igen senare.",

    // EditItem.ts
    editItem_typeStatic: "Statisk (t.ex. verktyg)",
    editItem_typeReuse: "Hålla koll på frysen)",
    editItem_typeReuseLostFound: "Borttappat",
    editItem_typeReuseFreetext: "Free text",
    editItem_typeStorage: "förvaring",
    editItem_typeLabel: "Välj typ",
    editItem_failedSaveCode: "Misslyckades att spara koden!",

    // Edit Static
    editStatic_infoHeader: "Om att ändra prylinfo",
    editStatic_PageHeaderEdit: "Ändra",
    editStatic_1: "Här kan du ändra informationen om prylen du har märkt med QR-kod.",
    editStatic_2: "Välj ett namn som gör det lätt att komma ihåg vad det är.",
    editStatic_3: "Beskriv prylen så detajerat du vill.",
    editStatic_3_1: 'Välj om du vill lägga ut denna som "hyrbar (dvs. att du vill ha betalt om någon lånar den)"',
    editStatic_3_2:
      'Om du lägger ut den som "hyrbar" så lägg till en beskrivning av hyrkostnad (t.ex. kostnad per dag och/eller per vecka, eller kanske kostnader för en helg), samt kanske information om hur du vill ha betalt (t.ex. kontakt eller SWISH)',
    editStatic_4: "Du kan också välja att ta ett kort på prylen.",
    editStatic_5:
      "Om du vill att vi ska skicka dig en notifiering när det är dags för den som lånat prylen att lämna tillbaka den, klicka i rutan för det.",
    editStatic_6: "Om du vill att andra ska kunna hyra denna pryl, klicka i den rutan.",
    editStatic_7: "Du kan även ändra kodtyp, men då får du slå på avancerade inställningar.",
    editStatic_photo: "Bild",
    editStatic_photoChoice: "Välj bild",
    editStatic_ISBNScanningInfo: " - skanna/lägg till ISBN-nummer",
    editStatic_alwaysConfirmReturn: "Jag vill alltid konfirmera återlämnande",
    editStatic_registrerMustHaveBankID: "Den som lånar måste vara registrerad med BankID ([framtida funkt.])",
    editStatic_putItemForRent: "Jag vill lägga ut denna prylen för uthyrning",
    editStatic_lostFound: 'Jag vill registrera denna för "Borttappat"',
    editStatic_lostFoundInfo:
      "Om denna pryl tappas bort kommer denna inställning att göra det lättare att få tillbaka den.",
    editStatic_configRent: "Settings Renting",
    editStatic_configLostFound: "Settings Lost&Found",
    EditStatic_ItemTypesListHeader: "Typ av pryl",
    EditStatic_ItemTypesHeader: "Typ av pryl",
    editStatic_ListsHeader: "Listor",
    editStatic_codeCannotBelongToMoreThanOne: "En kod kan inte tillhöra mer än en lista (när den kräver ägarändring)",
    editStatic_codeWillChangeOwner: "Koden kommer att ändra ägare till listägaren när den sparas",
    editStatic_ISBNScanningError: "Problem med skan. ISBN nummret hittas inte.",

    // UploadDialog
    uploadDialogImageChoice: "Bildval",
    uploadDialogChooseImage: "Välj bild",
    uploadDialogUpload: "Spara bilden",
    uploadDialogRotate: "Rotera",

    // ScanDialog
    scanDialog_manualEnter: "eller skriv in nummer (t.ex. ISBN) här...",

    // useObserveCode
    useObserveCode_ErroTryRefresh: "Fel! Försök att ladda om sidan...",

    // RegisterUser.ts
    registerUser_registerUser: "Registrera/logga in",
    registerUser_registerUserName: "Namn",
    registerUser_InfoAboutFree:
      "Registrering är gratis, liksom att använda andras QR-koder. Du behöver vara inloggad för att köpa och hantera koder.",
    registerUser_registerPhone: "Telefonnummer",
    registerUser_registerEmail: "Epost",
    registerUser_registerphoneNumMustStartWith: "Telefonnummer måste börja med +46",
    registerUser_registerUsernameNeeded:
      "Fyll i ett användarnamn (notera att detta kommer att ses av alla som skannar dina koder)",
    registerUser_sendCode: "Skicka kod",
    registerUser_code: "Kod",
    registerUser_privacyAgreement: "Personuppgiftsavtal",
    registerUser_privacyAgreementHere: "Här ",
    registerUser_iAgreeTerms: "Jag godkänner ovanstående QRit:s personuppgiftsavtal (dvs, standard GDPR-avtal).",
    registerUser_iAgreeTermsEnd: " (dvs, standard GDPR-avtal)",
    registerUser_registerUserButton: "Registrera/logga in",
    registerUser_networkErrorTryAgain: "Nätverksfel! Vänta lite och försök igen!",
    registerUser_checkCodeTryAgain: "Kontrollera registreringskoden och försök igen!",
    registerUser_networkErrorTryAgain2: "Nätverksfel! Vänta lite och försök igen!",
    registerUser_goToPayment: "Gå till betalning",
    registerUser_smsTextCode: "Här kommer din qrit.app aktiveringskod:\n",
    registerUser_organization: "Organisation",
    registerUser_meAsUser: "Jag som person",

    // BuyDigitalTags
    buyDigialTags_buyTags: "Köp klisterlappar",
    buyDigialTags_fillInEmail: "Fyll i epost nedan så vi kan skicka dig qr-koderna",
    buyDigialTags_email: "Epost",
    buyDigialTags_howHandlePersonaInfoHere: "Här",
    buyDigialTags_howHandlePersonaInfo: "kan du läsa om hur vi hanterar dina personuppgifter",

    // BuyPhysicalTags
    buyPhysicalTags_buyPhysicalTags: "Köp färdiga klisterlappar",
    buyPhysicalTags_fillInAddressInfo: "Fyll i adressuppgifter nedan så vi kan skicka dig QR-koderna",
    buyPhysicalTags_firstName: "Förnamn",
    buyPhysicalTags_lastName: "Efternamn",
    buyPhysicalTags_address: "Adress",
    buyPhysicalTags_zipCode: "Postnummer",
    buyPhysicalTags_city: "Stad",
    buyPhysicalTags_country: "Land",

    // listMessages
    listMessages_Messages: "Meddelanden",

    // BuyTags
    buyTags_headerBuyQRcodes: "Köpa QR-koder",
    buyTags_toUseMustHaveInfo:
      "UNDER UTVECKLING (INTE MÖJLIGT ATT KÖPA) För att använda qrit.app måste du ha unika QR-koder att fästa på dina prylar. Här beställer du koder, antingen som för nerladdning av PDF för egen utskrift, eller färdiga klisterlappar. Alla koder gäller i ett år från tidpunkten för registreringen av den första koden i en beställning. Efter ett år kan abonemanget för koderna förlängas.",
    buyTags_chooseHereToBuyDigitalPhysical:
      "Välj här om du vill beställa QR-koderna digitalt eller färdiga klisterlappar",
    //buyTags_ifYouBuyDigial: 'Köper du QR-koderna digitalt skapar vi en fil för utskrift (pdf). Utskriften kommer att se ut så här [länk till exempel-pdf]. Det går att köpa färdiga klisterlappar i detta format (Avery L4778) både i butik och online [länk]. Om du istället vill skriva ut på vanligt papper och sätta fast med tejp, så ska det också fungera bra. Digitala etiketter ingår i QR-kod kostnaden.',
    buyTags_ifYouBuyDigial1:
      "Köper du QR-koderna digitalt skapar vi en fil för utskrift (pdf). Ett exempel kan laddas ner ",
    buyTags_ifYouBuyDigialPdfExampleHere: "här",
    buyTags_ifYouBuyDigialPdfExampleURL:
      "https://www.amazon.com/Avery-L4778-20-Ultra-Resistant-Labels-Printers/dp/B0040SP0AS",
    buyTags_ifYouBuyDigial2:
      " (pdf). Det går att köpa färdiga klisterlappar i detta format (Avery L4778) både i butik och ",
    buyTags_ifYouBuyDigialAveryExampleURLHere: "online",
    buyTags_ifYouBuyDigialAveryExampleURL:
      "https://www.amazon.com/Avery-L4778-20-Ultra-Resistant-Labels-Printers/dp/B0040SP0AS",
    buyTags_ifYouBuyDigial3:
      " för ett exempel).. Om du istället vill skriva ut på vanligt papper och sätta fast med tejp, så ska det också fungera bra. Digitala etiketter ingår i QR-kod kostnaden.",
    buyTags_selectedTagEmpty: "/buyTags_selectedTagEmptyEng.png",
    buyTags_selectedTagLostFound: "/buyTags_selectedTagLostFoundEng.png",
    buyTags_selectedTagNumber: "/buyTags_selectedTagLostNumberEng.png",
    buyTags_unSelectedTagEmpty: "/buyTags_UnSelectedTagEmptyEng.png",
    buyTags_unSelectedTagLostFound: "/buyTags_unSelectedTagLostFoundEng.png",
    buyTags_unSelectedTagNumber: "/buyTags_unSelectedTagLostNumberEng.png",
    buyTags_ifYouBuyPhysical: "Att köpa färdiga etiketter kostar ",
    buyTags_ifYouBuyPhysical2:
      " (upp till 96 coder vid ett inköp). Om du köper dem färdiga kommer du i nästa steg att bli ombedd att skriva in (eller, om de redan finns, verifiera) din postadress. Efter att du betalat för coderna samt kostanden för skickandet så skriver vi ut dem och skickar dem till dig.",
    buyTags_downloadPdf: "Ladda hem PDF med klisterlappar (pris:",
    buyTags_downloadPhysical: "Köpa färdiga klisterlappar (pris:",
    buyTags_qrCodeToX1: "QR-koder till ",
    buyTags_qrCodeToX2: " prylar (pris: ",
    buyTags_chooseNumCodes: "Välj antal koder",
    buyTags_ChoosePhysDigit: "Välj fysiska eller digitala etiketter",
    buyTags_ChooseTagLayout: "Välj hur etiketterna ska se ut",
    buyTags_Price: "Pris: ",
    buyTags_emptyTag: "Tom etikett",
    buyTags: "Tom (vit)",
    buyTags_lostAndFound: "Borttappat",
    buyTags_numbered: "Unikt id",
    buyTags_numberedForeezer: "Unikt id",

    buyTagsPhysicalOnly_toUseMustHaveInfo:
      "UNDER UTVECKLING (INTE MÖJLIGHT ATT KÖPA).För att använda QRit.app måste du ha unika QR-koder från oss. Här beställer du dem (som klistermärken med QR-koderna tryckta på dem). Koderna är giltiga i ett år från registreringen av det första märket i en köp. Efter ett år kan prenumerationen på märkena förnyas. Du behöver välja...",
    buyTagsPhysicalOnly_chooseNumCodes: "Välj antal koder du vill köpa",
    buyTagsPhysicalOnly_selectedFormatSimple: "/selectedSimplePhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_unSelectedFormatSimple: "/unSelectedSimplePhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_selectedFormatRugged: "/selectedRuggedPhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_unSelectedFormatRugged: "/unSelectedRuggedPhysicalTagExampleImage.png",
    buyTagsPhysicalOnly_simpleTagFormat: "Enkel/tunnare",
    buyTagsPhysicalOnly_ruggedTagFormatInfo: "Robust/tjockare",
    buyTagsPhysicalOnly_freeText: "Fritext",
    buyTagsPhysicalOnly_normalOrThickSticker:
      "om du vill ha ett vanligt klistermärke eller en mer robust (tjockare). Det vanliga är billigare.",
    buyTagsPhysicalOnly_typeOfTagYouWant: "typ av märning du vill ha",
    buyTagsPhysicalOnly_theNumberOfTagsYouWant: "antal klistermärken du vill köpa",

    // RegisterItemManually
    registerItemManually_infoHeader: "Om manuell inmatning av kod",
    registerItemManually_manualRegisteringItem: "Manuell inmatning",
    registerItemManually_manualRegisteringItemInfo1:
      'Detta ska endast behövas om du inte kan skanna QR-koden. Kanske den är skadad eller den inte sitter tillräckligt "platt" för att kameran ska kunna skanna den.',
    registerItemManually_manualRegisteringItemInfo2:
      'Skriv in koden som står till höger om QR-koden du vill registrera i "Kod"-fältet nedan. Övrigt är samma som vid registrering av en pryl när QR-kod skannas. Vi kontrollerar att koden är korrekt innan du kan gå vidare. Vid inmatning tillåts bara a-z och A-Z samt 0-9 som tecken.',
    registerItemManually_writeQRcode8Char: "Skriv in den 8 tecken långa koden jämte QR-koden.",
    registerItemManually_code: "Kod",
    registerItemManually_register: "Fortsätt",
    registerItemManually_codeDoesNotExist: "Denna QR-kod finns inte. Kontrollera koden och försök igen",

    // ItemFound
    itemFound_youFoundQR: "Du har hittat en pryl med QRit-kod!",
    itemFound_someoneMarkedItem:
      "Någon har märkt prylen du skannat QR-koden på för att underlätta återlämnande om den tappas bort. Du har nu möjlighet att göra ägaren glad och se till att hen får tillbaka den!",
    itemFound_ownersPhoneNumber: "Ägarens telefonnummer",
    itemFound_whatMessageDoYouWantToSend: "Vilket meddelande vill du skicka till ägaren?",
    itemFound_ownersOffersThisAmount: "Ägaren erbjuder följande hittelön vid återlämnande",
    itemFound_insteadSendMessageToOwner:
      "Istället för att skicka ett meddelande till telefonnummret (om angivet) kan du här skicka ett meddelande till ägaren. Du kan t.ex. skriva var du lämnat prylen eller lagt den, så att ägaren kan hämta den själv. Du kan också skicka ditt eget telefonnummer.",
    itemFound_sendMessage: "Skicka meddelande",
    itemFound_sendGPS: "Skicka GPS-position",
    itemFound_sendGPSInfo:
      "Istället för att bara skicka ett meddelande så kan du välja att skicka en GPS-position till ägaren istället. Du kan, till exempel, lägga det du hittat på en viss plats, och klicka på knappen nedan. Då kommer du att få välja om du vill dela din GPS-position (telefonens). När du gjort det så kommer ägaren att ha möjlighet att hämta prylen där du lagt den, utan att du behöver göra något. Du kan välja att skicka positionen med eller utan meddelande. Om du vill skicka ett meddelande tillsammans med positionen, skriv in det ovan innan du klickar nedan.",

    // ConfigRent
    configRent_choiceRentHeading: "Val för pryluthyrning",
    configRent_howMuchCostLbl: "Hur mycket ska det kosta att hyra denna pryl",
    configRent_enableChatLbl: "Jag vill genom chat kunna diskutera pris",
    configRent_allowTimeExtent: "Jag vill att den som hyr ska kunna förlänga hyrtid",
    configRent_enableRentQueue: "Jag vill att man ska kunna ställa sig i kö för att hyra denna pryl",

    // EditLostFound
    // TODO: Clean up
    editLostFound_settingsLost: "Ändra info om borttappat",
    editLostFound_settingsLostText:
      'Genom att aktivera "Upphittad" funktionen för denna pryl kommer kan lämna tillbaka prylen till dig, och, om du valt att erbjuda hittelön också få veta hur mycket de kan förvänta sig få i hittelön. ',
    editLostFound_IwantFinderToSeePhoneNumber: "Jag vill att den som hittar denna ska få se mitt telefonnummer",
    editLostFound_IwantFinderAbleSendMess:
      "Jag vill att den som hittar prylen anonymt ska kunna skicka meddelande till mig",
    configLost_withThisSettingFinderShowPos:
      "Med denna inställning kan den som hittat prylen skicka ett meddelande till dig om var hen lagt den eller lämnat in den, utan att röja sitt telefonnummer.",
    editLostFound_IwantOfferLostPay: "Jag vill erbjuda hittelön",
    configLost_IwantOfferLostPayInfo:
      "Om du väljer att erbjuda hittelön kommer den som skannar koden att få veta att du erbjuder hittelön. Du kan i nästa steg välja att även visa upphittaren hur mycket denna ska få.",
    editLostFound_IwantFinderSeeHowMuchPay: "Jag vill att upphittaren ska se hur mycket hen kommer att få i hittelön",
    configLost_IwantFinderSeeHowMuchPayInfo:
      "Om du kryssar för denna ruta kommer den som hittar prylen att få veta hur mycket du tänker ge i hittelön",
    editLostFound_HowMuchLostPay: "Hur mycket vill du erbjuda i hittelön för denna pryl?",
    editLostFound_HowMuchLostPayInfo:
      "Detta är den information upphittaren får om hittelön. Se till att du är beredd att betala detta till upphittaren.",
    configLost_IwantEnablePosition: "Jag vill slå på positionsangivelse?",
    configLost_IwantEnablePositionInfo:
      'Positionsangivelse innebär att den som hittar prylen kan välja att bara markera platsen där hen lämnat prylen, så att du kan hämta den där. Detta kan göra att det troligare att du får tillbaka prylen, då det ofta "tar emot" att ta tiden att försöka träffas för att lämnad tillbaka prylen.',
    editLostFound_infoHeader: "Om ändring av borttappat",
    editLostFound_info0: "Här kan du ändra informationen om borttappat-koden",
    editLostFound_info1: "Namnet är bara namnet på koden. Det är det som blir synligt i listan",
    editLostFound_info2:
      'I fältet beskrivning skriver du in detaljer om saken du märkt. Var försiktig med att lägga med information som identifierar dig, om du vill att saken ska kunna vara "anonym"',
    editLostFound_info3:
      'Om "Jag vill att personnen....telefonnumber" är iklickat kommer den som skannar koden att se ditt telefonnummer',
    editLostFound_info4:
      'Om "Upphittade kan skicka GPS-position" är iklickat innebär att den som skannar koden kan välja att skicka GPS position, som han/hon kan skicka till dig. På så sätt kan upphittaren markera var saker finns, så att du kan hämta den',
    editLostFound_info5:
      'Om "Jag vill att... ska kunna skicka ett anonymt meddelande" är iklickat innebär det att den som skannar koden kan skicka ett meddelande till dig anonymt vi QRit. Du får då ett SMS',
    editLostFound_info6:
      'Om "Jag vill erbjuda hittelön" är iklickat inbär det att du kan ange en hittelön som den som återlämnar saken ska få från dig. Se till att du är beredd att betala!',
    editLostFound_info7:
      'Om "Jag vill att upphittaren ser hur stor hittelönen är" är iklickad, så kommer den som skannar koden att se beloppet du erbjuder',
    editLostFound_info8: "Du kan så ange hur stor hittelön du vill ge. Glöm inte att ange enhet (t.ex., kr, eller €)",
    //editLostFound_info9: 'The "I want the person... phone number" means that whomever scans this code will see the phone number',
    editLostFound_info10:
      "Om du slår på avancerade inställningar kommer du att kunna byta vilken typ av kod just denna kod ska vara",
    editLostFound_info11:
      "Om du klikcar på avregistrera så kommer koden att avregistreras. Dvs all information i koden kommer att tas bort (inklusive från alla listor) och du behöver registrera den igen för att använda den",

    // ItemFooter
    itemFooter_unregisterItem: "Avregistrera",
    itemFooter_unregister: "Avregistrera",
    itemFooter_close: "Stäng",
    itemFooter_realyUnregister:
      "Vill du verkligen avregistrera? När du avregistrerar en kod kommer all information tas bort och du måste skanna och lägga in information igen för att den ska vara användbar.",
    itemFooter_ShowAdvSettings: "Visa avancerade inställningar",
    itemFooter_WarningDataLostRegisterNew:
      "Varning! Om du ändra typ så kan du förloga informationen om denna kod. Det kommera att vara som om du registrerar den på nytt. Hitta information om kodtyper här [link].",
    itemFooter_ChangeCodeType: "Ändra kodtyp",
    itemFooter_areYouReadyToLeavePage: "Vill du verkligen lämna denna sida? Du har gjort ändringar som inte sparats.",

    // ListItemHelp
    listItemHelp_Header: "Var är mina prylar?",
    listItemHelp_WhereIconHeader: "Var är mina prylar?",
    listItemHelp_WhereIconDescr: "Figurerna till vänster talar om för dig var en pryl är. Följande beskriver dessa.",
    listItemHelp_WhereIcon_Home:
      'Denna pryl är "hemma". Det vill säga, ingen lånar eller hyr den just nu. Den borde vara tillgänglig för dig.',
    listItemHelp_WhereIcon_In:
      "Du har lånat eller hyrt denna pryl från någon. Det vill säga, det är någon annans pryl som du har.",
    listItemHelp_WhereIcon_Out:
      "Någon har lånat eller hyrt denna pryl från dig. Det vill säga, det är din pryl som någon har lånat eller hyrt, och den är inte tillgänglig för dig nu.",
    listItemHelp_WhereIcon_Freezer:
      "Detta är en QR-kod som du kan återanvända. Typiskt används den för att hålla koll på vad som finns i t.ex. frysen.",
    listItemHelp_OtherHeader: "Övrigt",
    listItemHelp_Descr: "Du kan lägga till en beskrivning till varje pryl.",
    listItemHelp_Image:
      'Du kan lägga till en bild till varje pryl (utom för återanvändningskoder). Gå till "Ändra" för att lägga till eller byta bild. Notera att när du byter bild tas den andra bort direkt.',

    // Freezer
    freezer_DateHeader: "Infrysningsdatum",
    freezer_ExpDateHeader: "Utgångsdatum (standard 90-dagar)",
    freezer_SelectADateDotDot: "Välj ett datum...",
    freezer_SelectADate: "Välj ett datum",
    freezer_PersonCntHeader: "För hur många",
    freezer_10Persons: "10 personer",
    freezer_0Of1Persons: "{0} av {1} person",
    freezer_FoodTypeHeader: "Typ av mat",
    freezer_ContentTypeHeader: "Innehåll",
    freezer_AmountHeader: "Mängd",
    freezer_EditFoodChoiceList: "Ändra matlista",
    freezer_EditFoodTypeList: "Ändra mattyper",
    freezer_EditLocationList: "Ändra platslistan",
    freezer_Location: "Plats",
    freezer_PageHeaderEdit: "Ändra",

    // EditFreezer
    // TODO: översätt
    editFreezer_infoHeader: "Om att ändra frysinfo",
    editFreezer_info0: "Det är här du ändrar informationen om det du vill frysa in",
    editFreezer_info1: 'Typ av mat är kortnamnet på innehållet (t.ex., "kött" eller "soppa")',
    editFreezer_info2: 'Innehåll är information om vilken typ av innehåll det är (t.ex., "huvudrätt" eller "råvara"',
    editFreezer_info3: "I fälter beskrivning skriver du in detaljerad information om innehållet",
    editFreezer_info4: 'Utgångsdatum är det datum du sätter så att QRit ger dig en notifiering när den "går ut"',
    editFreezer_info5:
      "Hur många personer är helt enkelt hur många personer detta är för. Du kan också skriva i detaljer eller mängden också eller istället",
    editFreezer_info6: "I fältet Mängd kan du skriva in vikt, volym eller någon annat värde, för att sedan välja enhet",
    editFreezer_info7: "Plats är var (i vilken frys) du lägger denna i",
    editFreezer_info8:
      'Notera att du kan välja att ändra (och lägga till) listorna för "Typ av mat", "Innehåll", och "Plats" genom att klicka på ändra',
    editFreezer_info9:
      "Om du slår på avancerade inställningar kommer du att kunna byta vilken typ av kod just denna kod ska vara",
    editFreezer_info10:
      "Om du klikcar på avregistrera så kommer koden att avregistreras. Dvs all information i koden kommer att tas bort (inklusive från alla listor) och du behöver registrera den igen för att använda den",

    // editTypeModal
    editTypeModal_Delete: "Ta bort",
    editTypeModal_Add: "Lägg till",

    // Helpers
    Helpers_Scanned: "Skannad",
    Helpers_Unregistered: "Oregistrerad",
    Helpers_Home: "Hemma", // ':Inte ,utlånad'
    Helpers_BorrowedByOther: "Utlånad",
    Helpers_BorrowedByMe: "Lånad",
    Helpers_OtherBorrowedByOther: "Någon annans lånad av någon annan",
    Helpers_AwaitingReturnAccept: "Väntar på bekräftelse",
    Helpers_Found: "Hittad!",
    Helpers_ManageCategories: "Hantera val",

    //listReuseItems
    listFreezerItems_Frozen: "Infrusen",
    listFreezerItems_Expiration: "Bäst före",

    // ListLostFoundItems
    listLostFoundItems_created: "Skapad",
    listLostFoundItems_photo: "Foto",
    listLostFoundItems_photoDescription: "Foto/beskrivning",

    // lostBoxes
    listBoxes_TagID: "Lådnummer",
    listBoxes_Content: "Innehåll",
    listBoxes_Location: "Plats",
    listBoxes_Room: "Rum",
    listBoxes_Stored: "Lagrad",
    listBoxes_Photo: "Foto",

    // CreateStoreDialog
    createStoreDialog_Create: "Skapa",
    createStoreDialog_CreateStoreQuestion: "Skapa ny lista?",
    createStoreDialog_StoreName: "Namn",

    //listStores
    ListStores_loading: "Laddar",

    // editStores
    editStores_allowResharingOfList: "Tillåt vidaredelning av lista",

    // joinStore
    joinStore_acceptJoinList: "Acceptera att gå med i lista",
    joinStore_rejectJoinList: "Gå inte med i lista",

    //ImagePanel
    imagePanel_mainImage: "Visningsbild",
    imagePanel_panelHeader: "Visningsbild",
    imagePanel_addNewImage: "Lägg till ny bild",
    imagePanel_removeImage: "Ta bort bild",
    imagePanel_private: "Privat",
    imagePanel_deleteImage: "Ta bort bild",
    imagePanel_deleteSelectedImages: "Ta bort bild",
    imagePanel_title: "Vill du verkligen göra denna publik?",
    imagePanel_text:
      "Är du säker på att du vill göra denna bild publik. Om du gör det kan alla som skannar koden se bilden.",
    imagePanel_disableInFuture: "Stäng av denna varning i framtiden?",

    // Viewstore
    viewStore_infoHeader: "Om ändring av lista",
    viewStore_info1: "Det är här du kan ändra information och rättigheter för listor",
    viewStore_info2:
      "Namn är den text du kommer att se i översikten över listor. Det är också den text som andra kommer att se om de har tillgång till en lista. Tänk till när du väljer namn på en lista. Håll namnet kort men beskrivande ",
    viewStore_info3:
      "Beskrivning är där du beskiver detaljerna om vad det är för lista (t.ex., innehåll och vad den är till för. Om du tänker dig att dela en lista (mer eller mindre) publikt så tänk till innan du lägger till någon information som identifierar dig, om du vill vara anonym",
    viewStore_info4:
      "TODO: FIX The checkbox 'Require code owner transfer when adding code to list' is about handling joint lists where one person or organisation will be the owner of both the list and the QR-code information about all items in that list. This may be if you in the family have one person that handles all tools, or at work, where your organisation should be the QRit-owner of all inforamtion added about work (e.g., books or equipment), regarless of who adds the item to the list. Use this option sparsely",
    viewStore_info5:
      "Du kan välja att ta bort en lista. Note dock att all information om listan då försvinner. Koderna finns kvar, men inte länken till listan",
    viewStore_info7:
      "Du kan lägga till användare och hantera deras användarrättigheter genom att klicka för de olika typerna av access",
    viewStore_info8:
      "'Administatör' betyder att användaren kan ta bort listan, ta bort och lägga till koder, samt allt annat en ägare kan göra",
    viewStore_info9: "'Ändra koder' innebär att användaren kan ta bort och lägga till koder från/till",
    viewStore_info10:
      "'Lista användare' innebär att användaren kan se vilka användare som har access och vilka rättigheter de har",
    viewStore_info11: "'Pending new user' means that ???? TBD",
    viewStore_DeleteStore: "Ta bort lista",
    viewStore_Delete: "Ta bort",
    viewStore_DeleteStoreQuestion: "Ta bort lista?",
    viewStore_Cancel: "Stäng",
    viewStore_SelectCodes: "Välj koder",
    viewStore_MultiSelectControlled: "Välj",
    viewStore_CodesTitle: "Koder",
    viewStore_EditList: "Ändra i lista",
    viewStore_RequireCodesOwnerChangeInfo:
      "Viktigt: Alla koder som läggs till listan kommer att ändra ägare till listägaren (detta beteende kan bara ändras när listan är tom).",
    viewStore_RequireCodesOwnerChangeInfoNot:
      "Viktigt: Användaren som lägger till en kod till lisan kommer även fortsättningsvis att vara ägare av den koden (detta beteende kan bara ändras när listan är tom).",
    viewStore_RequireCodesOwnerChange:
      "Om ikryssad kommer alla koder som läggs till listan kommer att ändra ägare till listägaren (detta beteende kan bara ändras när listan är tom).",
    viewStore_AccessHandling: "Hantera listaccess",
    viewStore_Join: "Begär åtkomst till listan",
    viewStore_CannotReqestJoinList: "Kan inte skicka förfrågan om att gå med i lista. Kontakta support.",

    // Stores
    stores_CreateStore: "Skapa lista",
    stores_ManageLists: "Hantera listor",
    stores_infoHeader: "Om vyn för hantering av listor",
    stores_listBelongsToYou: "Denna lista tillhör dig",
    stores_listBelongsToSomeoneElse: "Denna list tillhör någon annan",
    stores_info1: "Det är här du kan skapa och välja att hantera listor",
    stores_info2:
      "En lista är just en lista av saker, där du och andra har explicit 'lagt till' koder till en viss lista",
    stores_info3:
      "En ägare av en lista can ändra vem som har rätt att titta på, ändra och administrera en lista, till exempel så att det går att gemensamt inom familjen eller arbetsplatsen hantera och använda en gemensam lista",
    stores_info4: "På denna sida kan du skapa nya listor samt klicak på befittliga listor för att hantera dessa",

    // UserSettings
    userSettings_infoHeader: "Om användarinställningar",
    userSettings_manageUserSettings: "Användarinställningar",
    userSettings_manageViewTabs: "Hantera flikar",
    userSettings_ChooseWhichTabsToShow: "Välj vilka flikar som ska visas:",
    userSettings_ChooseWhichListsToShow: "Visa vilka listor some ska visas:",
    userSettings_Stuff: "Prylar",
    userSettings_boxes: "Lådor",
    userSettings_lostFound: "Borttappat",
    userSettings_freezer: "Frysen",
    userSettings_messages: "Meddelandekoder",
    userSettings_listChoices: "ListChoices",
    userSettings_enableSMRecievingOnIncommingMessages: "Tillåt mottagande av SMS när någon sänder meddelanden tlll dig",
    userSettings_enableSMSRecieveLabel: "Meddelanden",
    userSettings_whereYouManageSettings: "Här hanterar du dina inställningar för QRit.",
    userSettings_theWarningIcon:
      "Triangeln/varnings-märkningen innebär att om du lägger till en QRit-kod till denna lista så blir ägaren av listan också ägare av den koden. Detta är framför allt till för företag där flera sak kunna lägga till till företagets lista",
    userSettings_chooseToDisableReception:
      "Du kan välja bort att få SMS-meddelanden när någon skickar ett meddelande till någon av dina koder. Notera att det dock är bra att slå på det om du tappat bort något och väntar på att någon ska meddela dig att de hittat det.",
    userSettings_UserContactInfo: "Kontaktinformation",
    userSettings_FeedbackSupport: "Återkoppling och support",
    userSettings_organizationAccount: "Organisationskonto",
    userSettings_organizationsYouAreAdminFor: "Organisationer du är administratör för",

    // accessChangeOwnerDialog.ts
    accessChangeOwnerDialog_ChangeOwner: "Ändra ägare",
    accessChangeOwnerDialog_SubText: "Ändra ägare av listan",

    // StoreContextualMenu
    storeContextualMenu_listProperties: "Listans egenskaper",
    storeContextualMenu_moreOptions: "More options",
    storeContextualMenu_listName: "Listnamn",
    storeContextualMenu_listOwner: "Listägare",

    // AccessAddUSerDialot
    accessAddUserDialog_addUser: "Lägg till användare",
    accessAddUserDialog_subText: "Lägg till användare till listan",

    // Accesstable
    accessTable_FaildAddUser: "Misslyckades med att lägga till användare till lista",
    accessTable_InviteUser: "Bjud in användare",
    accessTable_ChangeOwner: "Ändra ägare",
    accessTable_changeRole: "Ändra roll",
    accessTable_changeRoleInfo: "Roll styr vilken åtkomst användaren har till listan.",
    accessTable_roleAdmin: "Administratör",
    accessTable_roleMaintain: "Hanterare",
    accessTable_roleEditor: "Redaktör",
    accessTable_rolePending: "Avvaktar",
    accessTable_roleMember: "Medlem",
    accessTable_setRole: "Sätt roll",
    accessTable_role: "Roll",
    accessTable_command: "Redigera",
    accessTable_delete: "Ta bort",
    accessTable_removeUser: "Ta bort användare",
    accessTable_removeUserFromList: "Ta bort användare från listan?",

    // Chat messages
    messages_footerMessage: "-- skickat från QRit.app\n",
    messages_messageTitle: "Meddelande",
    messages_send: "Skicka",
    messages_reply: "Svara",
    messages_replyTitle: "Svara",
    messages_replyText: "Svar med...",
    messages_delete: "Radera",
    messages_deleteTitle: "Radera meddelande",
    messages_deleteText: "Är du säker på att du vill radera detta meddelande?",

    //prices: [[12, 99], [24, 169], [36, 249], [48, 339]],
    //pricePhyical: 99,
    currency_prefix: "",
    currency_suffix: "kr",
    pricePhysicalDelviery: 99,
    skuList: [
      "00000000-0001-0002-0003-000000000009",
      "00000000-0001-0002-0003-000000000010",
      "00000000-0001-0002-0003-000000000011",
      "00000000-0001-0002-0003-000000000012",
      "00000000-0001-0002-0003-000000000013",
      "00000000-0001-0002-0003-000000000014",
      "00000000-0001-0002-0003-000000000015",
      "00000000-0001-0002-0003-000000000016",
    ],

    freezer_FoodChoiceList: [
      "Kött",
      "Fisk",
      "Vegetariskt",
      "Soppa",
      "Pastasås",
      "Grönsaker",
      "Bröd",
      "Glass",
      "Frukt eller bär",
    ],
    freezer_FoodTypeList: ["Hel maträtt", "Huvudrätt", "Tillbehör", "Råvara"],
    freezer_locationChoicesList: ["Köksfrysen", "Frysboxen"],
    freezer_AmountTypeList: ["g", "bitar", "kg", "liter", "ml"],

    EditBoxes_ContentTypeList: ["Flytt", "Förvaring"],
    EditBoxes_ContentList: ["Barnkläder", "Minnen"],
    //TODO: Make the content list dependent on content type.
    EditBoxes_SiteList: ["Hemma", "Sommarstugan"],
    EditBoxes_RoomList: ["Köket", "Vinden", " Garaget"],
    EditBoxes_CategoryList: ["Okategoriserad"],

    // EditBoxes_PriorityList: ['Hög','Normal','Låg'],
    // EditBoxes_BoxStateList: ['Märkt','Packar','Stängd','Lagrad','Packar upp'],
    EditBoxes_DefaultContentType: "Förvaring",
    EditBoxes_DefaultPriority: "Normal",
    EditBoxes_DefaultFragility: false,
    EditBoxes_DefaultLocation: "Hemma",
    EditBoxes_DefaultBoxState: "Märkt",
    EditBoxes_BoxStateList2: {
      tagged: "Märkt",
      packing: "Packar",
      closed: "Stängd",
      storedHome: "Lagrad hemma",
      storedAway: "Lagrad borta",
      unpacking: "Packas upp",
      unpacked: "Uppackad",
    },
    EditBoxes_PriorityList2: {
      low: "Låg",
      normal: "Normal",
      high: "Hög",
    },

    EditBoxes_BoxColorChoices: {
      green: "Grön",
      blue: "Blå",
      red: "Röd",
      black: "Svart",
      yellow: "Gul",
      pink: "Rosa",
      brown: "Brun",
    },

    EditStatic_ItemTypesList: ["Böcker", "Verktyg"],

    AccessTypes: {
      listAdmin: "Administratör",
      addUser: "Lägg till användare",
      editCodes: "Redigera koder",
      // "addCode",
      // "deleteCode",
      // "viewCode",
      // "editCode",
      // "viewLimitCode",
      // "anonView",
      // "borrow",
      // "request",
      // "confirm",
      // "invite",
      // "approve",
      listUser: "Lista användare",
      pendingUser: "Väntar på bekräftelse",
    },
  };
}

export const strings = Language.strings;
