import { Helpers } from "./helpers";
import {
  CodeInfo,
  RegisterCodeResponse,
  UserInfo,
  Purchase,
  RegisterUserResponse,
  GetUserNameByPhoneResponse,
  Sku,
  Store,
  StoreInfo,
  ChatMessage,
  Files,
  CompanyInfo,
} from "./models";

export default class Api {
  private static handleUnauthorized = () => {
    localStorage.clear();
    window.location.href = `/register?redirect=${encodeURIComponent(window.location.pathname)}`;
  };

  private static handleResponse = (response: Response) => {
    if (!response.ok) {
      if (response.status === 401) {
        Api.handleUnauthorized();
      }

      throw new Error(`${response.statusText}`);
    }
  };

  private static createHeader = () => {
    return [
      ["Content-Type", "application/json; charset=utf-8"],
      ["Authorization", `Bearer ${Api.getToken()}`],
    ] as [string, string][];
  };

  private static httpGet = async (url: string) => {
    const response = await fetch(url, {
      method: "GET",
      headers: Api.createHeader(),
    });
    Api.handleResponse(response);
    return response;
  };

  private static httpPost = async (url: string, payload: any) => {
    const response = await fetch(url, {
      method: "POST",
      headers: Api.createHeader(),
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      throw new Error(`${response.statusText}`);
    }

    return await response.json();
  };

  private static httpPut = async (url: string, payload: any) => {
    const response = await fetch(url, {
      method: "PUT",
      headers: Api.createHeader(),
      body: JSON.stringify(payload),
    });

    return response.ok;
  };

  private static httpDelete = async (url: string) => {
    const response = await fetch(url, {
      method: "DELETE",
      headers: Api.createHeader(),
    });

    return response.ok;
  };

  static getToken = () => {
    return localStorage.getItem("token") || "";
  };

  static getCode = async (code: string) => {
    const response = await Api.httpGet(`/qrit/GetCode?code=${code}`);
    const json = await response.json();
    json.returnDate = new Date(Date.parse(json.returnDate));
    let codeInfo = json as CodeInfo;
    codeInfo.properties = Helpers.parsePropertiesJsonCode(codeInfo.propertiesJson);
    codeInfo.shortID = codeInfo.shortID || "0";
    return codeInfo;
  };

  static verifyCode = async (code: string) => {
    const response = await Api.httpGet(`/qrit/VerifyCode?code=${code}`);
    const ok = await response.json();
    return ok;
  };

  static verifyCodeUnregistered = async (code: string) => {
    const response = await Api.httpGet(`/qrit/VerifyCodeUnregistered?code=${code}`);
    const ok = await response.json();
    return ok;
  };

  static registerCode = async (code: string, name: string, description: string, properties: any, type: string) => {
    const response = await Api.httpGet(
      `/qrit/RegisterCode?code=${code}&name=${name}&description=${encodeURIComponent(
        description
      )}&properties=${encodeURIComponent(JSON.stringify(properties))}&type=${type}`
    );
    const json = await response.json();
    let resp = json as RegisterCodeResponse;
    return resp;
  };

  static updateCode = Api.registerCode; // TODO: change to PUT updateCode in backend API

  static reportMisuse = async (code: string, description: string) => {
    const response = await Api.httpGet(`/qrit/ReportMisuse?code=${code}}&name=${encodeURIComponent(description)}`);
    // TODO: What to return?
    const json = await response.json();
    let ok = json as boolean;
    return ok;
  };

  static registerLostCfg = async (
    code: string,
    userId: string,
    rentValue: string,
    showHowMuchPay: boolean,
    offerPay: boolean,
    possibleSendMess: boolean,
    showPhoneNumber: boolean
  ) => {
    await Api.httpGet(
      `/qrit/RegisterLostCfg?code=${code}&userId=${encodeURIComponent(
        userId
      )}&rentValue=${rentValue}&showHowMuchPay=${showHowMuchPay}&offerPay=${offerPay}&possibleSendMess=${possibleSendMess}&showPhoneNumber=${showPhoneNumber}`
    );
  };

  static registerRentCfg = async (
    code: string,
    userId: string,
    rentCostDescr: string,
    enableRenterChatRent: boolean,
    enableRenterExtensionRent: boolean,
    enableQueForItemRent: boolean
  ) => {
    await Api.httpGet(
      `/qrit/RegisterRentCfg?code=${code}&userId=${encodeURIComponent(
        userId
      )}&rentCostDescr=${rentCostDescr}&enableRenterChatRent=${enableRenterChatRent}&enableRenterExtensionRent=${enableRenterExtensionRent}&enableQueForItemRent=${enableQueForItemRent}`
    );
  };

  static getCodesForUser = async () => {
    const response = await Api.httpGet(`/qrit/GetCodesForUser`);
    const json = await response.json();
    let resp = json as CodeInfo[];
    // @ts-ignore
    resp.map((ci) => (ci.returnDate = new Date(Date.parse(ci.returnDate))));
    resp.map((ci) => (ci.properties = Helpers.parsePropertiesJsonCode(ci.propertiesJson)));
    return resp;
  };

  static requestRegistrationCode = async (phone: string, message: string, email: string) => {
    const response = await Api.httpGet(
      `/qrit/RequestRegistrationCode?phone=${encodeURIComponent(phone)}&message=${encodeURIComponent(
        message
      )}&email=${encodeURIComponent(email)}`
    );
    const json = await response.json();
    let companies = json as CompanyInfo[];
    return companies;
  };

  static getPurchases = async (userId: string) => {
    const response = await Api.httpGet(`/qrit/GetPurchases?userId=${userId}`);
    const json = await response.json();
    let purchases = json as Purchase[];
    return purchases;
  };

  static getSkus = async () => {
    const response = await Api.httpGet(`/qrit/GetSkus`);
    const json = await response.json();
    let skus = json as Sku[];
    return skus;
  };

  static registerPurchase = async (
    userId: string,
    buyDigital: boolean,
    tagSent: Date,
    stateOrAction: string,
    paymentCompleted: boolean
  ) => {
    await Api.httpGet(
      `/qrit/RegisterPurchase?userId=${userId}&buyDigital=${buyDigital}&tagSent=${tagSent}&stateOrAction=${stateOrAction}&paymentCompleted=${paymentCompleted}`
    );
  };

  static getUserInfo = async (userID: string, code: string = "") => {
    const response = await Api.httpGet(`/qrit/GetUserInfo?userId=${userID}&code=${code}`);
    const json = await response.json();
    let userInfo = json as UserInfo;
    userInfo.properties = JSON.parse(json.propertiesJson) || {
      freezerFoodChoiceListJson: "[]",
      freezerFoodTypeListJson: "[]",
    };
    return userInfo;
  };

  // static getUserProperties = async () => {
  //    const response = await Api.httpGet('/qrit/Properties');
  //    const propertiesJson = await response.text();
  //    if (!propertiesJson || propertiesJson === '') {
  //       return { freezerFoodChoiceListJson: '[]', freezerFoodTypeListJson: '[]' } as UserProps;
  //    } else {
  //       return JSON.parse(propertiesJson) as UserProps;
  //    }
  // }

  // static setUserProperty = async (props: Partial<UserProps>) => {
  //    let properties = Api.getUserProperties();
  //    properties = { ...properties, ...props };
  //    const ok = await Api.httpPut(`/qrit/Properties`, { propertiesJson: JSON.stringify(properties) });
  //    return ok;
  // }

  static setProperty = async (domain: string, key: string, value: string) => {
    let ok = await Api.httpPut(`/qrit/Properties/${domain}.${key}`, {
      propertyValue: value,
    });
    if (!ok) {
      await Api.httpPost(`/qrit/Properties`, {
        id: `${domain}.${key}`,
        propertyValue: value,
      });
    }
  };

  static getProperty = async (domain: string, key: string) => {
    const response = await Api.httpGet(`/qrit/Properties/${domain}.${key}`);
    const property = await response.json();
    return property.propertyValue;
  };

  static getProperties = async (domain: string) => {
    const response = await Api.httpGet(`/qrit/Properties`);
    const properties: any = await response.json();
    return (properties.value || []).filter((p: any) => p.propertyDomain === domain);
  };

  static getPropertiesDict = async (domain: string) => {
    const response = await Api.httpGet(`/qrit/Properties`);
    let properties: any = await response.json();
    properties = (properties.value || []).filter((p: any) => p.propertyDomain === domain);
    let props: { [key: string]: any } = {};
    properties.map(
      (p: { propertyKey: string; propertyValue: string }) => (props[p.propertyKey] = JSON.parse(p.propertyValue))
    );
    return props;
  };

  static registerUser = async (
    phone: string,
    email: string,
    username: string,
    registrationCode: string,
    companyPhone = ""
  ) => {
    const response = await Api.httpGet(
      `/qrit/RegisterUser?phone=${encodeURIComponent(
        phone
      )}&name=${username}&email=${email}&registrationCode=${registrationCode}&companyPhone=${encodeURIComponent(
        companyPhone
      )}`
    );
    const json = await response.json();
    const registerUserResponse = json as RegisterUserResponse;
    return registerUserResponse;
  };

  static getUsernameByPhone = async (phone: string) => {
    const response = await Api.httpGet(`/qrit/GetUsernameByPhone?phone=${encodeURIComponent(phone)}`);
    const json = await response.json();
    const registerUserResponse = json as GetUserNameByPhoneResponse;
    return registerUserResponse.username;
  };

  static registerBuyerInfo = async (
    firstName: string,
    lastName: string,
    address: string,
    zipCode: string,
    city: string,
    country: string,
    email: string
  ) => {
    await Api.httpGet(
      `/qrit/RegisterBuyerInfo?firstName=${firstName}&lastName=${lastName}&address=${address}&zipCode=${zipCode}&city=${city}&country=${country}&email=${email}`
    );
  };

  static registerBuyerEmail = async (email: string) => {
    await Api.httpGet(`/qrit/RegisterBuyerEmail?email=${email}`);
  };

  static returnCode = async (code: string) => {
    await Api.httpGet(`/qrit/returnCode?code=${code}`);
  };

  static acceptPendingReturn = async (code: string) => {
    await Api.httpGet(`/qrit/acceptPendingReturn?code=${code}`);
  };

  static unregisterCode = async (code: string) => {
    await Api.httpGet(`/qrit/unregisterCode?code=${code}`);
  };

  static resetCode = async (code: string) => {
    await Api.httpGet(`/qrit/ResetCode?code=${code}`);
  };

  static registerBorrowing = async (id: string, borrowerUserID: string, returnDate: Date) => {
    await Api.httpGet(
      `/qrit/RegisterBorrowing?id=${id}&borrowerUserID=${borrowerUserID}&returnDate=${encodeURIComponent(
        returnDate.toISOString()
      )}`
    );
  };

  static async uploadFile(filename: string, formData: FormData) {
    const response = await fetch(`/qrit/UploadFile?filename=${encodeURIComponent(filename)}`, {
      method: "POST",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
      body: formData,
    });
    Api.handleResponse(response); // TODO: needed?
  }

  static async uploadFile2(
    filename: string,
    code: string,
    contentType: string,
    formData: FormData,
    metadataJson: string
  ) {
    const response = await fetch(
      `/qrit/UploadFile2?filename=${encodeURIComponent(filename)}&code=${code}&contentType=${encodeURIComponent(
        contentType
      )}&metadataJson=${encodeURIComponent(metadataJson)}`,
      {
        method: "POST",
        headers: [["Authorization", `Bearer ${Api.getToken()}`]],
        body: formData,
      }
    );
    Api.handleResponse(response);
    const fileId = await response.json();
    return fileId;
  }

  // static async getImageUrl(code: string) {
  //   const response = await fetch(`/qrit/DownloadFile?code=${code}`, {
  //     method: "GET",
  //     headers: [["Authorization", `Bearer ${Api.getToken()}`]],
  //   });

  //   if (!response.ok) {
  //     if (response.status === 401) {
  //       // Unauthorized, clear the token?
  //       // token = '';
  //     }

  //     // throw new Error(`${response.statusText}`);
  //     return "";
  //   }

  //   const blob = await response.blob();
  //   const url = window.URL.createObjectURL(blob);
  //   return url;
  // }

  static getFileUrl2 = (fileId: string) => `/qrit/DownloadFile2?fileId=${fileId}`;

  static async getFileUrl(fileId: string) {
    const response = await fetch(`/qrit/DownloadFile2?fileId=${fileId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return "";
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    return url;
  }

  static async getFiles(code: string) {
    const response = await fetch(`/qrit/GetFiles?code=${code}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return [];
    }

    const files = await response.json();
    return files as Files[];
  }

  static async getFileInfo(fileId: string) {
    const response = await fetch(`/qrit/GetFileInfo?fileId=${fileId}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return null;
    }

    const file = await response.json();
    return file as Files;
  }

  static async updateFile(file: Files) {
    const response = await fetch(`/qrit/UpdateFile`, {
      method: "PUT",
      headers: [
        ["Authorization", `Bearer ${Api.getToken()}`],
        ["Content-Type", "application/json; charset=utf-8"],
      ],
      body: JSON.stringify(file),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return false;
    }

    return true;
  }

  static async deleteFile(fileId: string) {
    const response = await fetch(`/qrit/DeleteFile?fileId=${fileId}`, {
      method: "DELETE",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return false;
    }

    return true;
  }

  static getStores = async () => {
    const response = await fetch(`/qrit/Stores`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return [];
    }

    const stores = await response.json();
    return stores.value as Store[];
  };

  static getStore = async (storeId: string) => {
    const response = await fetch(`/qrit/Stores/${storeId}?$expand=Codes,Access`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      throw new Error(`${response.statusText}`);
      // return [];
    }

    const store = (await response.json()) as Store;
    store.codes.map((ci) => (ci.properties = Helpers.parsePropertiesJsonCode(ci.propertiesJson)));

    if (store.access) {
      // TODO: fill username in the backend?
      await Promise.all(store.access.map(async (a) => (a.username = (await Api.getUserInfo(a.userId)).username)));
      // store.access.forEach(async (a) => {
      //   a.username = (await Api.getUserInfo(a.userId)).username;
      // });
    }

    return store;
  };

  static createStore = async (store: Store) => {
    const result = await Api.httpPost(`/qrit/Stores`, store);
    return result as Store;
  };

  static removeStore = async (storeId: string) => {
    const response = await fetch(`/qrit/Stores/${storeId}`, {
      method: "DELETE",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return false;
    }

    return true;
  };

  static updateStore = async (store: Store) => {
    const response = await fetch(`/qrit/Stores/${store.id}`, {
      method: "PUT",
      headers: Api.createHeader(),
      body: JSON.stringify(store),
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return false;
    }

    return true;
  };

  static getStoresByCode = async (code: string) => {
    const response = await fetch(`/qrit/GetStoresByCode?code=${code}`, {
      method: "GET",
      headers: [["Authorization", `Bearer ${Api.getToken()}`]],
    });

    if (!response.ok) {
      if (response.status === 401) {
        // Unauthorized, clear the token?
        // token = '';
      }

      // throw new Error(`${response.statusText}`);
      return [];
    }

    const stores = await response.json();
    return stores as StoreInfo[];
  };

  static updateCodeStores = async (code: string, storeIds: string[]) => {
    return Api.httpPut(`/qrit/UpdateCodeStores?code=${code}`, storeIds);
  };

  static postMessage = async (message: ChatMessage) => {
    const result = await Api.httpPost(`/qrit/ChatMessage`, message);
    return result as ChatMessage;
  };

  static getMessages = async (userId?: string, codeId?: string) => {
    let filter = "";

    if (userId) {
      filter += `userId%20eq%20'${userId}'`;
    }

    if (codeId) {
      if (filter.length > 0) {
        filter += "%20and%20";
      }
      filter += `codeId%20eq%20'${codeId}'`;
    }

    if (filter.length > 0) {
      filter = `?$filter=${filter}`;
    }

    if (filter.length === 0) {
      return [];
    }

    const response = await Api.httpGet(`/qrit/ChatMessage${filter}&$expand=User`);
    const json = await response.json();
    return json.value as ChatMessage[];
  };

  static deleteChatMessage = async (chatMessageId: string) => {
    return Api.httpDelete(`/qrit/ChatMessage/${chatMessageId}`);
  };

  static canChangeOwner = async (code: string) => {
    const response = await Api.httpGet(`/qrit/CanChangeOwner?code=${code}`);
    const text = await response.text();
    return text === "true";
  };

  static changeOwner = async (code: string, newOwnerUserId: string) => {
    return Api.httpGet(`/qrit/ChangeOwner?code=${code}&newOwnerUserId=${newOwnerUserId}`);
  };

  static changeStoreOwner = async (storeId: string, newOwnerUserId: string) => {
    return Api.httpGet(`/qrit/ChangeStoreOwner?storeId=${storeId}&newOwnerUserId=${newOwnerUserId}`);
  };

  static addUserToStore = async (phone: string, storeId: string) => {
    const response = await Api.httpGet(`/qrit/AddUserToStore?phone=${encodeURIComponent(phone)}&storeId=${storeId}`);
    return response.ok;
  };

  static canDoStoreOperation = async (storeId: string, operation: string) => {
    const response = await Api.httpGet(`/qrit/CanDoStoreOperation?storeId=${storeId}&operation=${operation}`);
    const text = await response.text();
    return text === "true";
  };

  static updateStoreAccess = async (accessId: string, storeId: string, userId: string, accessType: string) => {
    return Api.httpPut(`/qrit/Access/${accessId}`, {
      accessId,
      accessType: accessType === "" ? "none" : accessType,
      storeId,
      userId,
    });
  };

  static deleteStoreAccess = async (accessId: string) => {
    return await Api.httpDelete(`/qrit/Access/${accessId}`);
  };

  static requestJoinList = async (storeId: string) => {
    const response = await Api.httpGet(`/qrit/RequestJoinList?storeId=${storeId}`);
    const text = await response.text();
    return text === "true";
  };

  static hasCodeEditAccess = async (code: string) => {
    const response = await Api.httpGet(`/qrit/HasCodeEditAccess?code=${code}`);
    const text = await response.text();
    return text === "true";
  };

  static getBookDetails = async (isbn: string) => {
    const response = await fetch(
      `https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}&key=${"AIzaSyDv8jDPfp7qzWBIa-FGaanwYPIbeW3GEC8"}`
    );
    const info = await response.json();
    return info;
  };

  static postFeedbackMessage = async (text: string) => {
    const response = await Api.httpGet(`/qrit/PostFeedbackItem?text=${encodeURIComponent(text)}`);
    const id = await response.text();
    return id;
  };

  static sendStoreInvite = async (storeId: string) => {
    // TODO: make call to API
    return true;
  };

  static handleStoreInvite = async (inviteId: string, command: "reject" | "accept") => {
    // TODO: call API
    return "storeId"; // on accept
  };
}
