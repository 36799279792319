import React from "react";
import { Checkbox, PrimaryButton, Stack, TextField } from "@fluentui/react";
import { ParagraphLabel, ParagraphText, ParagraphTitle, StackVert } from "./Controls";
import { Language } from "./Strings";
import { useHistory } from "react-router-dom";
import { Content } from ".";
import { TitleWithInfo } from "./TitleWithInfo";
import { useObserveUser } from "../hooks/useObserveUser";
import { Header } from "./Header";
import { FeedbackForm } from "./FeedbackForm";

const strings = Language.strings;

export const UserSettings = () => {
  const history = useHistory();
  const {
    userId,
    enableSMRecievingOnIncommingMessages,
    onEnableSMRecievingOnIncommingMessagesChange,
    firstName,
    lastName,
    address,
    zipCode,
    city,
    email,
    username,
    phone,
    onAddressChange,
    onCityChange,
    onEmailChange,
    onFirstNameChange,
    onLastNameChange,
    onZipCodeChange,
  } = useObserveUser();

  // const [tabState, setTabState] = useState({
  //   showTabStatic: false,
  //   showTabBoxes: false,
  //   showTabLostFound: false,
  //   showTabFreezer: false,
  //   showTabMessages: false,
  //   selectedLists: [] as string[],
  // });

  // useEffect(() => {
  //   if (tabChoices) {
  //     setTabState({
  //       showTabStatic: tabChoices.some((x) => x === "static"),
  //       showTabBoxes: tabChoices.some((x) => x === "boxes"),
  //       showTabLostFound: tabChoices.some((x) => x === "lostFound"),
  //       showTabFreezer: tabChoices.some((x) => x === "freezer"),
  //       showTabMessages: tabChoices.some((x) => x === "html"),
  //       selectedLists: tabChoices.filter((x) => x.length > 20),
  //     });
  //   }
  // }, [tabChoices]);

  // const getTabChoices = (tabState: any) => {
  //   let tabChoices: string[] = [];
  //   if (tabState.showTabStatic) tabChoices.push("static");
  //   if (tabState.showTabBoxes) tabChoices.push("boxes");
  //   if (tabState.showTabLostFound) tabChoices.push("lostFound");
  //   if (tabState.showTabFreezer) tabChoices.push("freezer");
  //   if (tabState.showTabMessages) tabChoices.push("html");
  //   tabChoices = tabChoices.concat(tabState.selectedLists);
  //   return tabChoices;
  // };

  // const setState = (newState: any) => {
  //   setTabState(newState);
  //   onTabChoiceChange(getTabChoices(newState));
  // };

  return (
    <StackVert>
      <Header />
      <Content>
        <Stack.Item>
          <TitleWithInfo
            title={strings.userSettings_manageUserSettings}
            iconFileName=""
            infoTitle={strings.userSettings_infoHeader}
            onClose={() => history.goBack()}
          >
            <ul>
              <li>{strings.userSettings_whereYouManageSettings}</li>
              <li>{strings.userSettings_theWarningIcon}</li>
              <li>{strings.userSettings_chooseToDisableReception}</li>
            </ul>
          </TitleWithInfo>
        </Stack.Item>
        <Stack.Item>
          <ParagraphTitle>{strings.userSettings_enableSMSRecieveLabel}</ParagraphTitle>
        </Stack.Item>
        <Stack.Item>
          <Checkbox
            label={strings.userSettings_enableSMRecievingOnIncommingMessages}
            checked={enableSMRecievingOnIncommingMessages}
            onChange={async (_ev, checked) => await onEnableSMRecievingOnIncommingMessagesChange(!!checked)}
          />
        </Stack.Item>
        <hr />
        <Stack.Item>
          <ParagraphTitle>{strings.userSettings_organizationAccount}</ParagraphTitle>
          <ParagraphLabel>{strings.userSettings_organizationsYouAreAdminFor}</ParagraphLabel>
          <ParagraphText>
            {"[STR: TODO Add list of organisations that user has access too, making them clickable. "}
          </ParagraphText>
          <PrimaryButton onClick={() => history.push("/createOrgAccount/" + userId)}>
            {"STR: TODO Create organisation account"}
          </PrimaryButton>
        </Stack.Item>
        <hr />
        <Stack.Item>
          <ParagraphTitle>{strings.userSettings_UserContactInfo}</ParagraphTitle>
        </Stack.Item>
        <Stack.Item>
          <TextField id="username" label={strings.purchasing_username} value={username} disabled />
          <TextField id="phone" label={strings.purchasing_phone} value={phone} disabled />
          <TextField
            label={strings.purchasing_email}
            value={email}
            onChange={(_event, value) => onEmailChange(value || "")}
          />
          <TextField
            label={strings.purchasing_firstName}
            value={firstName}
            onChange={(_event, value) => onFirstNameChange(value || "")}
          />
          <TextField
            label={strings.purchasing_lastName}
            value={lastName}
            onChange={(_event, value) => onLastNameChange(value || "")}
          />
          <TextField
            label={strings.purchasing_address}
            value={address}
            onChange={(_event, value) => onAddressChange(value || "")}
          />
          <TextField
            label={strings.purchasing_zipCode}
            value={zipCode}
            onChange={(_event, value) => onZipCodeChange(value || "")}
          />
          <TextField
            label={strings.purchasing_city}
            value={city}
            onChange={(_event, value) => onCityChange(value || "")}
          />
        </Stack.Item>
        {/* <hr />
        <Stack.Item>
          <ul>
            {purchases.map(p => <li><code>{JSON.stringify(p)}</code></li>)}
          </ul>
        </Stack.Item> */}
        <br />
        <hr />
        <Stack.Item>
          <ParagraphTitle>{strings.userSettings_FeedbackSupport}</ParagraphTitle>
        </Stack.Item>
        <FeedbackForm extra="" />
      </Content>
    </StackVert>
  );
};
