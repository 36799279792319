import React from "react";
import {
  Stack,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SpinnerSize,
  Spinner,
  MaskedTextField,
} from "@fluentui/react";
import { Helpers } from "../helpers";
import { Header } from "./Header";
import { StackVert, ParagraphText } from "./Controls";
import { Content } from "./Content";
import queryString from "query-string";
import { Language } from "./Strings";
import { TitleWithInfo } from "./TitleWithInfo";

const strings = Language.strings;

interface RegisterItemManuallyProps {
  onChanged: () => void;
}

interface RegisterItemManuallyState {
  qrCode: string;
  itemTitle: string;
  itemDescription: string;
  filename: string;
  pictureOfEquip: string; // wrong type we want to store the "blob" of the image somewhere
  busy: boolean;
  error?: string;
  requireUserRemovalApproval: boolean;
  requireBankID: boolean;
  rentable: boolean;
  codeExist: boolean;
}

export class RegisterItemManually extends React.Component<RegisterItemManuallyProps, RegisterItemManuallyState> {
  constructor(props: RegisterItemManuallyProps) {
    super(props);

    // Take qr code from query string (format: ?s=xxxxxx)
    const { s } = queryString.parse(window.location.search);
    const qrCode = s ? (s as string) : "";

    this.state = {
      qrCode: qrCode,
      itemTitle: "",
      itemDescription: "",
      filename: "",
      pictureOfEquip: "",
      busy: false,
      requireUserRemovalApproval: false,
      requireBankID: false,
      rentable: false,
      codeExist: false,
    };

    this._verifyCode();

    const isLoggedIn = Helpers.isLoggedIn();
    if (!isLoggedIn) {
      window.location.href = "/register?redirect=/RegisterItemManually";
    }
  }

  render() {
    const { busy } = this.state;
    return (
      <StackVert>
        <Header />
        <Content>
          <StackVert>
            <Stack.Item>
              <TitleWithInfo
                title={strings.registerItemManually_manualRegisteringItem}
                iconFileName={""}
                infoTitle={strings.registerItemManually_infoHeader}
              >
                <ul>
                  {/* TODO: Add the information and add in strings */}
                  <li>{strings.registerItemManually_manualRegisteringItemInfo1}</li>
                  <li>{strings.registerItemManually_manualRegisteringItemInfo2}</li>
                </ul>
              </TitleWithInfo>
            </Stack.Item>
            <Stack.Item>
              <ParagraphText>{strings.registerItemManually_writeQRcode8Char}</ParagraphText>
            </Stack.Item>
            <Stack.Item>
              <MaskedTextField
                id="kod"
                label={strings.registerItemManually_code}
                value={this.state.qrCode}
                onChange={(_e, value) =>
                  this.setState({ qrCode: (value || "").toUpperCase() }, () => this._verifyCode())
                }
                mask="********"
                maskFormat={maskFormat}
              />
            </Stack.Item>

            <Stack.Item>
              {this.state.error && <MessageBar messageBarType={MessageBarType.error}>{this.state.error}</MessageBar>}
            </Stack.Item>
            <Stack horizontal tokens={{ childrenGap: 15 }} style={{ marginTop: 6 }}>
              <Stack.Item grow>
                <PrimaryButton disabled={!this.state.codeExist} href={"/" + this.state.qrCode}>
                  {strings.registerItemManually_register}
                </PrimaryButton>
                {busy && <Spinner size={SpinnerSize.large} />}
              </Stack.Item>
              <Stack.Item>
                <PrimaryButton onClick={() => (window.location.href = "/")}>{strings.generic_cancel}</PrimaryButton>
              </Stack.Item>
            </Stack>
          </StackVert>
        </Content>
      </StackVert>
    );
  }

  private _verifyCode = async () => {
    if (this.state.qrCode.replace("_", "").trim().length !== 8) {
      this.setState({ codeExist: false, error: undefined });
      return;
    }

    // Exists?
    var exists = await Helpers.verifyCodeExists(this.state.qrCode);
    // if (!exists) {
    //     // Try creating the code
    //     const userId = localStorage.getItem('userId');
    //     await Api.createCode(this.state.qrCode, userId!);
    //     exists = await Helpers.verifyCodeExists(this.state.qrCode);
    // }

    if (exists) {
      this.setState({ codeExist: true, error: undefined });
    } else {
      this.setState({ codeExist: false, error: strings.registerItemManually_codeDoesNotExist });
    }
  };
}

const maskFormat: { [key: string]: RegExp } = {
  "*": /[a-zA-Z0-9]/,
};
