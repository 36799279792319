import React, { useState } from "react";
import { Dialog, DialogContent, DialogFooter, PrimaryButton, DefaultButton, Stack, IconButton } from "@fluentui/react";
import { ParagraphLabel, StackVert } from "./Controls";
import { SendMessage } from "./SendMessage";
import { useObserveMessages } from "../hooks/useObserveMessages";
import { useObserveStrings } from "../hooks/useObserveStrings";
import { Helpers } from "../helpers";
export const ListMessages = ({ code, showSendMessage }: { code: string; showSendMessage: boolean }) => {
  const { messages, getMessages, deleteMessage } = useObserveMessages(code);
  const [showReply, setShowReply] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [messageToDelete, setMessageToDelete] = useState<string>();
  const strings = useObserveStrings();

  const replaceLinksWithAnchorTags = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, function (url) {
      return `<a href="${url}" target="_blank">${url}</a>`;
    });
  };

  return (
    <Stack.Item>
      <StackVert>
        {messages.length > 0 && <ParagraphLabel>{strings.listMessages_Messages}</ParagraphLabel>}
        <Stack tokens={{ childrenGap: 12 }}>
          {messages.length > 0 &&
            messages.map((m) => (
              <Stack horizontal>
                <Stack.Item grow key={m.chatMessageId} style={{ borderLeft: "2px solid #808080", paddingLeft: 4 }}>
                  <div dangerouslySetInnerHTML={{ __html: replaceLinksWithAnchorTags(m.message) }} /> - (
                  {m.user!.username})
                </Stack.Item>
                <Stack.Item style={{ visibility: m.userId !== Helpers.getCurrentUserId() ? "visible" : "hidden" }}>
                  <PrimaryButton onClick={() => setShowReply(true)}>{strings.messages_reply}</PrimaryButton>
                </Stack.Item>
                <Stack.Item>
                  <IconButton
                    iconProps={{ iconName: "Delete" }}
                    title={strings.messages_delete}
                    ariaLabel={strings.messages_delete}
                    styles={{ root: { cursor: "pointer" } }}
                    onClick={() => {
                      setMessageToDelete(m.chatMessageId);
                      setShowDeleteConfirm(true);
                    }}
                  />
                </Stack.Item>
              </Stack>
            ))}
        </Stack>
        <Stack.Item>
          {showSendMessage && (
            <SendMessage code={code} sendButtonText={strings.messages_send} onMessageSent={getMessages} />
          )}
        </Stack.Item>
        <br />
        <Dialog
          title={strings.messages_replyTitle}
          dialogContentProps={{ subText: strings.messages_replyText }}
          hidden={!showReply}
          onDismiss={() => setShowReply(false)}
          modalProps={{ isBlocking: true }}
        >
          <DialogContent>
            <SendMessage
              code={code}
              sendButtonText={strings.messages_send}
              onMessageSent={() => {
                getMessages();
                setShowReply(false);
              }}
            />
          </DialogContent>
          <DialogFooter />
        </Dialog>
        <Dialog
          hidden={!showDeleteConfirm}
          onDismiss={() => setShowDeleteConfirm(false)}
          dialogContentProps={{ title: strings.messages_deleteTitle, subText: strings.messages_deleteText }}
          modalProps={{ isBlocking: true }}
        >
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                deleteMessage(messageToDelete!);
                setShowDeleteConfirm(false);
                getMessages();
              }}
              text={strings.generic_yes}
            />
            <DefaultButton onClick={() => setShowDeleteConfirm(false)} text={strings.generic_cancel} />
          </DialogFooter>
        </Dialog>
      </StackVert>
    </Stack.Item>
  );
};
