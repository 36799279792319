import {
  Checkbox,
  DefaultButton,
  Dialog,
  DialogFooter,
  Dropdown,
  IDropdownOption,
  IDropdownStyles,
  Link,
  PrimaryButton,
  Stack,
  TextField,
} from "@fluentui/react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, useParams, withRouter } from "react-router-dom";
import Api from "../api";
import { useObserveStore } from "../hooks/useObserveStore";
import { CodeInfo } from "../models";
// import { AccessTable } from "./AccessTable";
import { AccessTableNew } from "./AccessTableNew";
import { Content } from "./Content";
import { StackVert, ParagraphText, StackHorizontal, ItemInfoStacked, ParagraphLabel, ParagraphTitle } from "./Controls";
import { Header } from "./Header";
import { Language } from "./Strings";
import { TitleWithInfo } from "./TitleWithInfo";

const strings = Language.strings;

const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };

interface EditStoreProps extends RouteComponentProps {
  id: string;
}

export const EditStore = withRouter(({ history }: EditStoreProps) => {
  const { id } = useParams<{ id: string }>();
  const {
    store,
    name,
    setName,
    description,
    setDescription,
    requireCodesOwnerChange,
    allowResharing,
    setAllowResharing,
    setRequireCodesOwnerChange,
    isPrivateList,
    setIsPrivateList,
    codes,
    canDeleteStore,
    canAskToRequireCodeOwnerChange,
    deleteStore,
    updateCodes,
    options,
    selectedKeys: keys,
    hasListAccess,
    canEditCodes,
    canListUsers,
    join,
    storeCodes,
    addUser,
    ownerRole,
    removeUser,
    changeOwner,
  } = useObserveStore({ storeId: id, onSaved: history.goBack, onRemoved: history.goBack });

  const [dialogHidden, setDialogHidden] = useState(true);

  const showDialog = () => setDialogHidden(false);
  const hideDialog = () => setDialogHidden(true);

  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

  useEffect(() => {
    setSelectedKeys(keys);
  }, [keys]);
  const onChangeCodes = (event: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
    if (item) {
      setSelectedKeys(
        item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key)
      );
    }
  };

  const onUpdateCodes = () => {
    updateCodes(selectedKeys.map((key) => ({ id: key } as CodeInfo)));
  };

  const joinList = async () => {
    const ok = await join();
    if (!ok) {
      // TODO: set error? Or, display dialog alert?
      alert(strings.viewStore_CannotReqestJoinList);
    }
    // TODO: display info bar that request has been sent (copy Error control to InfoBar control)?
  };

  return (
    <StackVert>
      <Header />
      <Content>
        <StackVert>
          <Stack.Item>
            <TitleWithInfo
              title={strings.viewStore_EditList}
              iconFileName={""}
              infoTitle={strings.viewStore_infoHeader}
            >
              <ul>
                {/* TODO: Check this. it is not quite correct... */}
                <li>{strings.viewStore_info1}</li>
                <li>{strings.viewStore_info2}</li>
                <li>{strings.viewStore_info3}</li>
                <li>{canAskToRequireCodeOwnerChange && strings.viewStore_info4}</li>
                <li>{canDeleteStore && strings.viewStore_info5}</li>
                {/* <li>{hasListAccess && !canEditCodes && {strings.viewStore_info6}}</li> */}
                <li>{store && store.access && hasListAccess && canListUsers && strings.viewStore_info7}</li>
                <li>{store && store.access && hasListAccess && canListUsers && strings.viewStore_info8}</li>
                <li>{store && store.access && hasListAccess && canListUsers && strings.viewStore_info9}</li>
                <li>{store && store.access && hasListAccess && canListUsers && strings.viewStore_info10}</li>
                <li>{store && store.access && hasListAccess && canListUsers && strings.viewStore_info11}</li>
              </ul>
              '{" "}
            </TitleWithInfo>
            {!canDeleteStore && <ItemInfoStacked header={strings.generic_name + ":"} content={name}></ItemInfoStacked>}
            {canDeleteStore && <Stack.Item>
              <TextField
                label={strings.generic_name}
                required={false}
                value={name}
                onChange={(_e, value) => setName(value || "")}
              />
            </Stack.Item>}
            {!canDeleteStore && <ParagraphLabel>{strings.generic_description}</ParagraphLabel>}
            {!canDeleteStore && <ParagraphText>{description}</ParagraphText>}
            {/* {!canDeleteStore && isPrivateList && <ParagraphLabel>{"STR: Private list"}</ParagraphLabel>}
            {!canDeleteStore && isPrivateList && <ParagraphText>{"STR: This list is private, whch means that only those that have access to the list can even see its name and description"}</ParagraphText>} */}
            {canDeleteStore && <Stack.Item>
              <TextField
                multiline
                autoAdjustHeight
                label={strings.generic_description}
                required={false}
                value={description}
                onChange={(_e, value) => setDescription(value || "")}
              />
            </Stack.Item>}
            {/* <ParagraphLabel>{strings.viewStore_CodesTitle}</ParagraphLabel> */}
            {canEditCodes && (
              <Dropdown
                placeholder={strings.viewStore_SelectCodes}
                label={strings.viewStore_MultiSelectControlled}
                selectedKeys={selectedKeys}
                // eslint-disable-next-line react/jsx-no-bind
                onChange={onChangeCodes}
                multiSelect
                options={options}
                styles={dropdownStyles}
                onDismiss={onUpdateCodes}
                onRenderTitle={(options) => (
                  <span>{(options || []).map((o) => codes.find((c) => c.id === o.key)!.name).join(", ")}</span>
                )}
              />
            )}
            {hasListAccess && !canEditCodes && (
              <ul>
                {/* TODO: use ItemsList or new list component for listing codes */}
                {storeCodes.map((c) => (
                  <li key={c.id}>
                    <Link href={`/${c.id}`}>{c.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </Stack.Item>
          {/* <Stack.Item>
            <Checkbox
              label={"STR: Make list private (only visible to users with access) - not yet used"}
              disabled={false}
              checked={isPrivateList}
              onChange={(_ev, checked) => setIsPrivateList(!!checked)}
            />
          </Stack.Item> */}
          {canAskToRequireCodeOwnerChange && storeCodes.length === 0 && (
            <Stack.Item>
              <Checkbox
                label={strings.viewStore_RequireCodesOwnerChange}
                disabled={false /* TODO: depends on user access! */}
                checked={requireCodesOwnerChange}
                onChange={(_ev, checked) => setRequireCodesOwnerChange(!!checked)}
              />
            </Stack.Item>
          )}
          {requireCodesOwnerChange && storeCodes.length > 0 && (
            <Stack.Item>
              <ParagraphText>{strings.viewStore_RequireCodesOwnerChangeInfo}</ParagraphText>
            </Stack.Item>
          )}
          {!requireCodesOwnerChange && storeCodes.length > 0 && (
            <Stack.Item>
              <ParagraphText>{strings.viewStore_RequireCodesOwnerChangeInfoNot}</ParagraphText>
            </Stack.Item>
          )}
          {/* {store && store.access && hasListAccess && canListUsers && (
            <Stack.Item>
              <ParagraphTitle>{strings.viewStore_AccessHandling}</ParagraphTitle>
              <AccessTable
                store={store}
                onChange={(s) => {
                  // TODO: move access changes to useObserveStore?!?
                  if (s.access) {
                    s.access.map(
                      async (a) => await Api.updateStoreAccess(a.accessId, a.storeId, a.userId, a.accessType)
                    );
                  }
                  onUpdateCodes();
                }}
                addUser={addUser}
              />
            </Stack.Item>
          )} */}
          {store && store.access && hasListAccess && (
            <Stack.Item>
              <Checkbox
                label={strings.editstores_allowResharingOfList}
                disabled={false}
                checked={allowResharing}
                onChange={(_ev, checked) => setAllowResharing(!!checked)}
              />
            </Stack.Item>
          )}
          {store && store.access && hasListAccess && canListUsers && (
            <Stack.Item>
              <ParagraphTitle>{strings.viewStore_AccessHandling}</ParagraphTitle>
              <AccessTableNew
                store={store}
                ownerRole={ownerRole}
                onChange={(s) => {
                  // TODO: move access changes to useObserveStore?!?
                  if (s.access) {
                    s.access.map(
                      async (a) => await Api.updateStoreAccess(a.accessId, a.storeId, a.userId, a.accessType)
                    );
                  }
                  onUpdateCodes();
                }}
                addUser={addUser}
                removeUser={removeUser}
                changeOwner={changeOwner}
              />
            </Stack.Item>
          )}
          <Stack.Item>
            <StackHorizontal>
              {canDeleteStore && (
                <Stack.Item>
                  <PrimaryButton onClick={showDialog}>{strings.viewStore_DeleteStore}</PrimaryButton>
                </Stack.Item>
              )}
              {!hasListAccess && (
                <Stack.Item>
                  <PrimaryButton onClick={joinList}>{strings.viewStore_Join}</PrimaryButton>
                </Stack.Item>
              )}
              <Stack.Item align="end">
                <PrimaryButton onClick={history.goBack}>{strings.viewStore_Cancel}</PrimaryButton>
              </Stack.Item>
            </StackHorizontal>
          </Stack.Item>
        </StackVert>
      </Content>
      <Dialog
        title={strings.viewStore_Delete}
        dialogContentProps={{ subText: strings.viewStore_DeleteStoreQuestion }}
        hidden={dialogHidden}
        onDismiss={hideDialog}
        modalProps={{ isBlocking: true }}
      >
        <DialogFooter>
          <PrimaryButton onClick={deleteStore} text={strings.viewStore_Delete} />
          <DefaultButton onClick={hideDialog} text={strings.viewStore_Cancel} />
        </DialogFooter>
      </Dialog>
    </StackVert>
  );
});
