import * as React from "react";
import { getTheme, Stack, FontIcon, Panel, PanelType, Nav, Label, Text } from "@fluentui/react";
import { version } from "../../package.json";
import { Language, strings } from "./Strings";
import { Link, withRouter } from "react-router-dom";
import { Helpers } from "../helpers";
import { useTheme } from "../hooks/useTheme";
import { useState } from "react";
import { useObserveUser } from "../hooks";
// import Flags from 'country-flag-icons' // https://www.npmjs.com/package/country-flag-icons

export const Menu = withRouter((props: any) => {
  const { showMenu, onDismiss, history } = props;
  const isLoggedIn = Helpers.isLoggedIn();

  const { showBuy, aboutPage } = useTheme(strings.Shureguard ? "shuregard" : "qrit");

  let links = [
    {
      key: "about",
      name: strings.header_whatIsQRit,
      url: "", // "/about",
      onClick: () => history.push(aboutPage),
      iconProps: { iconName: "Info" },
    },
    {
      key: "buy",
      name: strings.header_buyQRcodes,
      url: "", // isLoggedIn ? '/buy' : '/register',
      onClick: () => history.push(isLoggedIn ? "/buy/1" : "/register?redirect=/buy/1"),
      iconProps: { iconName: "Shop" },
      disable: showBuy,
    },
    {
      key: "scan",
      name: strings.header_scan,
      url: "",
      onClick: () => history.push("/scan"),
      iconProps: { iconName: "QRCode" },
    },
    {
      key: "manualEntry",
      name: strings.header_manualQRentry,
      url: "", // isLoggedIn ? '/buy' : '/register',
      onClick: () =>
        history.push(isLoggedIn ? "/RegisterItemManually" : "/RegisterItemManually?redirect=/RegisterItemManually"),
      iconProps: { iconName: "Edit" },
    },
    {
      key: "stores",
      name: strings.header_stores,
      url: "",
      onClick: () => history.push("/stores"),
      iconProps: { iconName: "CustomList" },
    },
    {
      key: "settings",
      name: strings.header_settings,
      url: "",
      onClick: () => history.push("/settings"),
      iconProps: { iconName: "PlayerSettings" }, // TODO: Change icon
    },
    {
      key: "privacy",
      name: strings.header_termsAndConditions,
      url: "", // "/privacy",
      onClick: () => history.push("/privacy"),
      iconProps: { iconName: "EntitlementPolicy" },
    },
    {
      key: "feedback",
      name: strings.header_feedback,
      url: "",
      id: "codicent-chat",
      onClick: () => {
        (window as any).codicent.setup(
          `Feedback\n---\nJag kommer ge feedback QRit här nedan. Ställ gärna frågor till mig.\n${Helpers.getCurrentUserId()}`
        );
        (window as any).codicent.show();
        onDismiss();
      },
      iconProps: { iconName: "OfficeChat" },
    },
    isLoggedIn
      ? {
          key: "logout",
          name: strings.header_logout,
          url: "/",
          onClick: () => Helpers.logout(),
          iconProps: { iconName: "SignOut" },
        }
      : {
          key: "login",
          name: strings.header_login,
          url: "",
          onClick: () => history.push("/register"),
          iconProps: { iconName: "SignIn" },
        },
    // {
    //     key: 'choice2',
    //     name: 'Val 2',
    //     url: '#'
    // }
  ];

  // if (showLostAndFoundCodes) {
  //     links.splice(3, 0, {
  //         key: "itemFound",
  //         name: strings.header_itemFound,
  //         url: '', // isLoggedIn ? '/buy' : '/register',
  //         onClick: () => history.push('/ItemFound'),
  //         iconProps: { iconName: "EntitlementPolicy" },
  //     });
  // }

  // class Menu extends React.Component<{
  //     showMenu: boolean;
  //     onDismiss: () => void;
  // }> {
  //     render() {
  // const { showMenu, onDismiss } = this.props;
  return (
    <Panel
      isOpen={showMenu}
      onDismiss={onDismiss}
      type={PanelType.custom}
      closeButtonAriaLabel="Stäng"
      customWidth="200px"
      isLightDismiss
      // headerText="Meny"
    >
      <Nav
        // styles={{ root: { padding: 10 } }}
        ariaLabel="..."
        groups={[
          {
            // name: 'Meny',
            // expandAriaLabel: 'Expand Basic components section',
            // collapseAriaLabel: 'Collapse Basic components section',

            // TODO: set menu items according to logged in or not?
            links,
          },
        ]}
      />
      <Label>Version: {version}</Label>
      <div id="codicent-chat" />
    </Panel>
  );
});

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { imageDir } = useTheme();
  const theme = getTheme();
  const isLoggedIn = Helpers.isLoggedIn();
  const { firstName, username } = useObserveUser();
  const userName = isLoggedIn ? firstName || username : null;

  return (
    <div
      id="header"
      style={{
        backgroundColor: theme.semanticColors.accentButtonBackground, //"rgb(30, 89, 92)",
        color: "white",
        position: "absolute",
        top: "0px",
        left: "0px",
        height: "32px",
        right: "0px",
        padding: "10px",
        overflow: "hidden",
      }}
    >
      <Stack
        horizontal
        style={{
          backgroundColor: theme.semanticColors.accentButtonBackground /*"rgb(30, 89, 92)"*/,
        }}
      >
        <Stack.Item align="center" grow disableShrink>
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <span
              style={{
                color: theme.semanticColors.primaryButtonText,
                fontSize: 24,
                margin: 8,
                verticalAlign: "center",
              }}
            >
              <img src={imageDir + "/logo.png"} height="38" alt="LOGO" />{" "}
            </span>
          </Link>
        </Stack.Item>
        <Stack.Item align="start" grow disableShrink>
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <span
              style={{
                color: theme.semanticColors.primaryButtonText,
                fontSize: 24,
                margin: 8,
                verticalAlign: "center",
              }}
            >
              QRit
            </span>
          </Link>
        </Stack.Item>
        <Stack.Item align="center">
          <Link
            to="/"
            style={{
              textDecoration: "none",
            }}
          >
            <span
              style={{
                color: theme.semanticColors.primaryButtonText,
                fontSize: 24,
                margin: 8,
              }}
            >
              <img
                src={`${Language.getLanguage() === "sv" ? "gb" : "sv"}.svg`}
                height="18"
                alt={`${Language.getLanguage()}`}
                onClick={() => {
                  const language = Language.getLanguage() === "sv" ? "gb" : "sv";
                  Language.setLanguage(language);
                  window.location.reload();
                }}
              />{" "}
            </span>
          </Link>
        </Stack.Item>
        {userName && (
          <Stack.Item align="center">
            <Text
              style={{
                color: theme.semanticColors.primaryButtonText,
                padding: 8,
                fontSize: 14,
              }}
            >
              {userName.length > 12 ? `${userName.substring(0, 15).trim()}...` : userName}
            </Text>
          </Stack.Item>
        )}
        {true && (
          <Stack.Item align="center">
            {/* <IconButton iconProps={{ iconName: 'CollapseMenu' }} onClick={() => this.setState({ showMenu: true })} /> */}
            <FontIcon style={{ padding: 8 }} iconName="CollapseMenu" onClick={() => setShowMenu(true)} />
            {/* <PrimaryButton
                                onClick={() => this.setState({ userId: null }, () => localStorage.removeItem('userId'))}
                            >
                                Logout
                            </PrimaryButton> */}
          </Stack.Item>
        )}
        <Menu showMenu={showMenu} onDismiss={() => setShowMenu(false)} />
      </Stack>
      {/* {this.props.children} */}
    </div>
  );
};
